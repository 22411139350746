import * as React from "react";
import { SVGProps } from "react";

export const SVGIconBell = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <path
      d="M9.97059 20.4118H15.1471C15.1471 21.0982 14.8744 21.7565 14.389 22.2419C13.9036 22.7273 13.2453 23 12.5588 23C11.8724 23 11.2141 22.7273 10.7287 22.2419C10.2433 21.7565 9.97059 21.0982 9.97059 20.4118ZM21.6176 17.1765C21.6176 17.6913 21.4131 18.185 21.0491 18.5491C20.685 18.9131 20.1913 19.1176 19.6765 19.1176H5.44118C4.92634 19.1176 4.4326 18.9131 4.06856 18.5491C3.70452 18.185 3.5 17.6913 3.5 17.1765C3.5 16.6616 3.70452 16.1679 4.06856 15.8039C4.4326 15.4398 4.92634 15.2353 5.44118 15.2353H6.08824V10.0588C6.08843 8.5674 6.60372 7.12181 7.54695 5.96653C8.49018 4.81126 9.80347 4.0172 11.2647 3.71864V2.29412C11.2647 1.9509 11.4011 1.62173 11.6437 1.37904C11.8864 1.13634 12.2156 1 12.5588 1C12.902 1 13.2312 1.13634 13.4739 1.37904C13.7166 1.62173 13.8529 1.9509 13.8529 2.29412V3.71864C15.3142 4.0172 16.6275 4.81126 17.5707 5.96653C18.5139 7.12181 19.0292 8.5674 19.0294 10.0588V15.2353H19.6765C20.1913 15.2353 20.685 15.4398 21.0491 15.8039C21.4131 16.1679 21.6176 16.6616 21.6176 17.1765ZM20.3235 17.1765C20.3233 17.0049 20.2551 16.8405 20.1338 16.7191C20.0125 16.5978 19.848 16.5296 19.6765 16.5294H17.7353V10.0588C17.7353 8.68594 17.1899 7.36928 16.2191 6.39851C15.2484 5.42773 13.9317 4.88235 12.5588 4.88235C11.1859 4.88235 9.86928 5.42773 8.89851 6.39851C7.92773 7.36928 7.38235 8.68594 7.38235 10.0588V16.5294H5.44118C5.26957 16.5294 5.10498 16.5976 4.98364 16.7189C4.86229 16.8403 4.79412 17.0049 4.79412 17.1765C4.79412 17.3481 4.86229 17.5127 4.98364 17.634C5.10498 17.7554 5.26957 17.8235 5.44118 17.8235H19.6765C19.848 17.8233 20.0125 17.7551 20.1338 17.6338C20.2551 17.5125 20.3233 17.348 20.3235 17.1765Z"
      fill={props.color || props.fill || "#93918F"}
    />
  </svg>
);
