import * as React from "react";
import { SVGProps } from "react";

export const SVGIconHeartActive = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width} height={props.height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fill="#FF7663">
      <path d="M12.135 22.872a2.46 2.46 0 0 1-1.509-.517C8.582 20.78 4.801 17.764 2.51 15.33-.626 12.01-.85 7.266 1.983 4.258A7.315 7.315 0 0 1 7.343 2a7.433 7.433 0 0 1 4.654 1.646A7.437 7.437 0 0 1 16.665 2a7.375 7.375 0 0 1 5.346 2.258c2.846 2.989 2.609 7.747-.527 11.072a91.554 91.554 0 0 1-7.789 6.987c-.44.36-.992.556-1.56.555Z" />
      <path d="M7.32 3.898a5.472 5.472 0 0 0-3.966 1.665c-2.13 2.248-1.897 5.882.527 8.462 2.29 2.429 6.294 5.583 7.907 6.831a.57.57 0 0 0 .712 0 92.234 92.234 0 0 0 7.623-6.83c2.433-2.576 2.661-6.215.526-8.463a5.467 5.467 0 0 0-3.965-1.665 5.474 5.474 0 0 0-3.975 1.688.95.95 0 0 1-1.386 0A5.475 5.475 0 0 0 7.34 3.898h-.02Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
