import { APICompsSchema } from "../api-config";

import { flatSnsAccountObject } from "./sns-account";
import { spotlightObject } from "./spotlight";
import { flatStoreUrlObject, storeObject } from "./store";
import { ticketObject } from "./ticket";

// TODO: structuredClone関数で実装した方が良いかも。
const defaultTicketObject = (): APICompsSchema["TicketObject"] => ticketObject();
const defaultStoreObject = (): APICompsSchema["StoreObject"] => storeObject();
const defaultFlatStoreUrlObject = (): APICompsSchema["FlatStoreUrlObject"] => flatStoreUrlObject();
const defaultFlatSnsAccountObject = (): APICompsSchema["FlatSNSAccountObject"] => flatSnsAccountObject();
const defaultSpotlightObject = (): APICompsSchema["SpotlightObject"] => spotlightObject();

export {
  defaultTicketObject,
  defaultStoreObject,
  defaultFlatStoreUrlObject,
  defaultFlatSnsAccountObject,
  defaultSpotlightObject,
};
