import { css } from "@emotion/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { appColor, appTypo, CustomMuiButtonSmall } from "@unit/styles";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import AreaContainer from "@/components/common/area/AreaContainer";
import PrefectureSelector from "@/components/common/area/PrefectureSelector";
import RegionSelector from "@/components/common/area/RegionSelector";
import SelectedAreaContainer from "@/components/common/area/SelectedAreaContainer";
import SpPrefectureSelector from "@/components/common/area/SpPrefectureSelector";
import SpRegionSelector from "@/components/common/area/SpRegionSelector";
import { LocationType } from "@/data/sampleSelectType";
import { areasAtom } from "@/global-state/jotai-atom";

type Props = {
  areaIds: string[];
  setAreaIds: React.Dispatch<React.SetStateAction<string[]>>;
  nonError?: boolean;
  onClickChange?: (newAreaIds: APICompsSchema["FlatAreaObject"][]) => void;
  onFocus?: React.FocusEventHandler;
};
const SelectedAreas: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const [scroll] = useState<DialogProps["scroll"]>("paper");
  const [areas] = useAtom(areasAtom);
  const [selectedRegion, setSelectedRegion] = useState<string>("関東");
  const [selectedPrefecture, setSelectedPrefecture] = useState<string>("東京都");
  const [selectedAreas, setSelectedAreas] = useState<APICompsSchema["FlatAreaObject"][]>(
    areas?.filter((area) => props.areaIds.includes(area.id)) || [],
  );

  useEffect(() => {
    if (!areas) return;
    setSelectedAreas(areas?.filter((area) => props.areaIds.includes(area.id)));
  }, [props.areaIds]);

  const handleToggleSelectedArea = (targetArea: APICompsSchema["FlatAreaObject"]) => {
    if (props.areaIds.includes(targetArea.id)) {
      props.setAreaIds((prev) => prev.filter((prevAreaId) => prevAreaId !== targetArea.id));
    } else {
      props.setAreaIds((prev) => [...prev, targetArea.id]);
    }

    if (selectedAreas.some((selectedArea) => selectedArea.id === targetArea.id)) {
      setSelectedAreas((prev) => prev.filter((prevArea) => prevArea.id !== targetArea.id));
    } else {
      setSelectedAreas((prev) => [...prev, targetArea]);
    }
  };

  const handleSelectAllAreas = () => {
    if (!areas) return;

    const targetAreas = areas
      .filter((area) => area.region === selectedRegion && area.prefecture === selectedPrefecture)
      .filter((area) => selectedAreas.every((selectedArea) => selectedArea.id !== area.id));

    props.setAreaIds((prev) => [...prev, ...targetAreas.map((area) => area.id)]);
    setSelectedAreas([...selectedAreas, ...targetAreas]);
  };

  const handleReset = () => {
    setSelectedAreas([]);
    props.setAreaIds([]);
  };

  const changeRegion = (targetRegion: string) => {
    const location = LocationType.find((location) => location.region === targetRegion);

    if (location?.region && location.region !== selectedRegion) {
      setSelectedPrefecture(location.prefectures[0]);
      setSelectedRegion(location.region);
    }
  };

  const changePrefecture = (targetPrefecture: string) => {
    const location = LocationType.find((location) => location.region === selectedRegion);

    const prefecture = location?.prefectures.find((prefecture) => prefecture === targetPrefecture);
    if (prefecture) {
      setSelectedPrefecture(prefecture);
    }
  };

  const showSelectPrefecture = selectedRegion !== "北海道";

  if (!areas) return <></>;

  return (
    <Grid container>
      <Dialog open={open} fullWidth={true} maxWidth={"md"} scroll={scroll} onClose={() => setOpen(false)}>
        <Grid container justifyContent="center" sx={{ display: { xs: "none", sm: "block" } }}>
          <DialogTitle css={dialogTitleStyled}>エリアを選択してください(必須・複数可)</DialogTitle>
          <RegionSelector
            caption="地方を選択してください"
            selectedRegion={selectedRegion}
            onClick={(event) => {
              if (!(event.target instanceof HTMLButtonElement)) return;
              changeRegion(event.target.value);
            }}
          />
          {showSelectPrefecture ? (
            <PrefectureSelector
              caption="都道府県を選択してください"
              selectedRegion={selectedRegion}
              selectedPrefecture={selectedPrefecture}
              onClick={(event) => {
                if (!(event.target instanceof HTMLButtonElement)) return;
                changePrefecture(event.target.value);
              }}
            />
          ) : null}
        </Grid>
        <Grid container justifyContent="center" sx={{ display: { xs: "block", sm: "none" } }}>
          <DialogTitle css={dialogTitleStyled}>エリアを選択してください</DialogTitle>
          <Grid px={2} py={1} container direction={"row"} spacing={1}>
            <Grid item xs={6}>
              <SpRegionSelector
                caption="地方を選択してください"
                selectedRegion={selectedRegion}
                onChange={(event) => changeRegion(event.target.value)}
              />
            </Grid>
            {showSelectPrefecture ? (
              <Grid item xs={6}>
                <SpPrefectureSelector
                  caption="都道府県を選択してください"
                  selectedRegion={selectedRegion}
                  selectedPrefecture={selectedPrefecture}
                  onChange={(event) => changePrefecture(event.target.value)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid container item xs={12} px={2} py={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              fullWidth
              css={CustomMuiButtonSmall}
              type="submit"
              variant="contained"
              size={"small"}
              disabled={showSelectPrefecture && !selectedPrefecture}
              onClick={handleSelectAllAreas}
            >
              {`${showSelectPrefecture ? selectedPrefecture : "北海道"}のエリアを全て選択する`}
            </Button>
          </Grid>
        </Grid>
        <DialogContent dividers={scroll === "paper"} css={areaContentStyled}>
          <AreaContainer
            selectedRegion={selectedRegion}
            selectedPrefecture={selectedPrefecture}
            selectedAreas={selectedAreas}
            allAreaList={areas}
            onClick={(event) => {
              const targetArea = areas.find((area) => area.id === event.currentTarget.id);
              if (targetArea) handleToggleSelectedArea(targetArea);
            }}
          />
        </DialogContent>

        <Accordion>
          <AccordionSummary expandIcon={<MdOutlineArrowForwardIos />} css={summaryStyled}>
            選択中のエリア
          </AccordionSummary>
          <AccordionDetails>
            <DialogContent dividers={scroll === "paper"} css={dialogContentStyled}>
              <SelectedAreaContainer
                selectedAreas={selectedAreas}
                onClick={(event) => {
                  const targetArea = areas.find((area) => area.id === event.currentTarget.id);
                  if (targetArea) handleToggleSelectedArea(targetArea);
                }}
              />
            </DialogContent>
          </AccordionDetails>
        </Accordion>
        <Grid container item direction={"row-reverse"} justifyContent={"space-between"} p={2} spacing={1}>
          <Grid item xs={6} md={4}>
            <Button
              fullWidth
              css={CustomMuiButtonSmall}
              type="submit"
              variant="contained"
              size={"medium"}
              onClick={() => {
                if (props.onClickChange) {
                  props.onClickChange(selectedAreas);
                }
                setOpen(false);
              }}
            >
              {selectedAreas.length}
              件登録する
            </Button>
          </Grid>
          <Grid item xs={6} md={4}>
            <Button fullWidth color={"warning"} type="submit" variant="contained" size={"medium"} onClick={handleReset}>
              <Grid sx={{ display: { xs: "none", sm: "block" } }}>選択中のエリア全てを解除</Grid>
              <Grid sx={{ display: { xs: "block", sm: "none" } }}>選択解除</Grid>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Grid item xs={12} pb={2}>
        <Stack spacing={1}>
          <Box css={autoFocusTitleStyled}>エリア</Box>
          <TextField
            value={selectedAreas.map((area) => area.area).join("、")}
            fullWidth
            css={textField}
            InputProps={{ readOnly: true }}
            label={"エリアを選択してください"}
            variant="outlined"
            size={"small"}
            error={!props.nonError && !props.areaIds.length}
            onClick={() => setOpen(true)}
            onFocus={props.onFocus}
            helperText={`${selectedAreas.length}件選択中`}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
export default React.memo(SelectedAreas);

const autoFocusTitleStyled = css`
  font-family: ${appTypo.font.notoJp};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
`;

const dialogTitleStyled = css`
  font-family: ${appTypo.font.notoJp};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: ${appColor.Text.primary};
`;

const dialogContentStyled = css`
  max-width: 100%;
  padding: 8px 30px !important;
`;

const areaContentStyled = css`
  ${dialogContentStyled};
  max-height: 280px;
`;

const summaryStyled = css`
  font-weight: 500;
  font-size: 16px;
  background: ${appColor.App.white};
  flex-direction: row-reverse;
  &.Mui-expanded {
    min-height: 40px;
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg) !important;
    transition: all 0.3s 0s ease;
  }
  & .MuiAccordionSummary-content {
    padding-left: 8px;
    margin: 0 !important;
  }
`;

const textField = css`
  .MuiFormHelperText-contained {
    margin: 4px 4px 0;
  }
`;
