import { Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { convertToWebP, logoImageUrl, noImageUrl } from "@unit/utility";
import React from "react";

import ScreenFitImage from "../../../common/image/ScreenFitImage";
import { SlideArrow } from "../../../common/slider/SlideArrow";
import SliderProvider from "../../../common/slider/SliderProvider";

type Props = {
  ticket: APICompsSchema["TicketObject"];
};

const TicketImageCarousel: React.FC<Props> = (props) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    prevArrow: <SlideArrow direction="left" />,
    nextArrow: <SlideArrow direction="right" />,
  };

  return (
    <SliderProvider settings={settings} totalSlides={props.ticket.imageUrls.length}>
      {props.ticket.imageUrls.length ? (
        props.ticket.imageUrls.map((imageUrl, index) => (
          <Grid key={`image${index}`} textAlign="center">
            <ScreenFitImage
              alt="ticket-image"
              src={convertToWebP(imageUrl)}
              srcSet={convertToWebP(imageUrl)}
              defaultImageUrl={logoImageUrl}
              maxSize={400}
            />
          </Grid>
        ))
      ) : (
        <ScreenFitImage
          alt="ticket-image"
          src={logoImageUrl}
          srcSet={logoImageUrl}
          defaultImageUrl={noImageUrl}
          maxSize={400}
        />
      )}
    </SliderProvider>
  );
};
export default TicketImageCarousel;
