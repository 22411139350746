import * as React from "react";
import { SVGProps } from "react";

export const SVGIconTicket = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.255 10.308v3.964a.834.834 0 0 1-1.669 0v-3.964a.834.834 0 0 1 1.668 0Zm12.677 1.982a2.235 2.235 0 0 0 2.234 2.232.834.834 0 0 1 .834.834v2.412a3.817 3.817 0 0 1-3.812 3.811H3.812A3.816 3.816 0 0 1 0 17.77v-2.413a.834.834 0 0 1 .834-.834 2.233 2.233 0 1 0 0-4.465A.834.834 0 0 1 0 9.223V6.81A3.816 3.816 0 0 1 3.812 3h16.375A3.816 3.816 0 0 1 24 6.811v2.412a.834.834 0 0 1-.834.834 2.235 2.235 0 0 0-2.233 2.233Zm-1.668 0a3.91 3.91 0 0 1 3.068-3.812V6.81a2.146 2.146 0 0 0-2.144-2.143H8.255v1.676a.834.834 0 1 1-1.669 0V4.668H3.812a2.145 2.145 0 0 0-2.144 2.143v1.668a3.901 3.901 0 0 1 0 7.623v1.667a2.145 2.145 0 0 0 2.144 2.143h2.774v-1.676a.834.834 0 0 1 1.668 0v1.675h11.933a2.145 2.145 0 0 0 2.144-2.143v-1.667a3.908 3.908 0 0 1-3.067-3.811Z"
      fill={props.fill}
    />
  </svg>
);
