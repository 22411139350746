import BaseApi from "../base-api";
import { useSpotlightApi } from "admin-dash/src/custom-hooks/apis/use-spotlight-api";
import { APIPaths } from "../api-config";

export class SpotlightApi extends BaseApi {
  async getSpotlightList(
    parameters: APIPaths["/admin-dash/spotlight/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/spotlight/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/spotlight/list", {
        params: parameters,
      })
    ).data;
  }

  async getSpotlightById(
    id: APIPaths["/admin-dash/spotlight/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/spotlight/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/spotlight/${id}`)).data;
  }

  async postSpotlightNew(
    requestBody: APIPaths["/admin-dash/spotlight/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/spotlight/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/spotlight/new", requestBody)).data;
  }

  async patchSpotlightById(
    id: APIPaths["/admin-dash/spotlight/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/spotlight/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/spotlight/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/spotlight/${id}`, requestBody)).data;
  }

  async deleteSpotlightById(
    id: APIPaths["/admin-dash/spotlight/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/spotlight/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/spotlight/${id}`)).data;
  }

  async putOpenSpotlightById(
    id: APIPaths["/admin-dash/spotlight/{id}/open"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/spotlight/{id}/open"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/spotlight/${id}/open`)).data;
  }

  async putCloseSpotlightById(
    id: APIPaths["/admin-dash/spotlight/{id}/close"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/spotlight/{id}/close"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/spotlight/${id}/close`)).data;
  }

  async putFirstOpenSpotlightById(
    id: APIPaths["/admin-dash/spotlight/{id}/first-open"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/spotlight/{id}/first-open"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/spotlight/${id}/first-open`)).data;
  }

  async putFirstCloseSpotlightById(
    id: APIPaths["/admin-dash/spotlight/{id}/first-close"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/spotlight/{id}/first-close"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/spotlight/${id}/first-close`)).data;
  }

  async postSpotlightCarouselPositions(
    requestBody: APIPaths["/admin-dash/spotlight/carousel-positions"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/spotlight/carousel-positions"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post("/admin-dash/spotlight/carousel-positions", requestBody)).data;
  }
}
