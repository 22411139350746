import { css } from "@emotion/react";
import { CardMedia, Grid, Stack } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { appColor, BackgroundColor, FontColor, FontFamily, FontWeight } from "@unit/styles";
import { convertToWebP } from "@unit/utility";
import React from "react";

import { SVGIconAddressColoredGray } from "../../../common/svg-icon/IconAddressColoredGray";
import { SvgIconLinkBox } from "../../../common/svg-icon/IconLinkBox";
import { SVGRestaurantFoodColoredGray } from "../../../common/svg-icon/IconRestaurantFoodColoredGray";

type Props = {
  store: APICompsSchema["StoreObject"];
  onClickStoreUrl?: () => void;
};

const StoreOutlineInfo: React.FC<Props> = (props) => {
  return (
    <Stack gap={1}>
      {/* ストアロゴ */}
      <Grid
        py={1}
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
        flexWrap={"wrap"}
      >
        <CardMedia
          component="img"
          css={style.logoImage}
          image={convertToWebP(props.store.iconImageUrl || props.store.imageUrls[0])}
        />
        {/* TODO: 一旦コメントアウトで非表示対応。完全に必要なくなったら削除する。 unit-app-front issue #490 */}
        {/*<Grid display="flex" alignItems={"center"}>*/}
        {/*  <Rating*/}
        {/*    value={props.store.rating}*/}
        {/*    readOnly*/}
        {/*    icon={<SvgIconStar width={16} height={16} color={"#FBC929"} css={style.star} />}*/}
        {/*    emptyIcon={<SvgIconStar width={16} height={16} color={"#F1F1F1"} css={style.star} />}*/}
        {/*  />*/}
        {/*  <Grid pl={0.5} css={style.ratingText}>*/}
        {/*    {props.store.ratingCount}件*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Grid>

      {/* ストア名 / 公式HPリンク */}
      <Grid position="relative" py={0.25}>
        <Grid css={style.storeName} className="focus-scroll-store-name">
          {props.store.name}
        </Grid>
        {props.store.url && (
          <Grid
            position="absolute"
            top={0}
            right={0}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            css={style.officialHPContainer}
            onClick={props.onClickStoreUrl}
          >
            <Grid css={style.chipText}>公式HP</Grid>
            <SvgIconLinkBox width={9} height={9} fill={appColor.App.white} />
          </Grid>
        )}
      </Grid>

      {/* ジャンル */}
      <Grid display="flex" gap={1} className="focus-scroll-store-genre">
        <SVGRestaurantFoodColoredGray width={12} height={12} color={FontColor.basic} />
        <Grid css={style.ellipsisText}>{props.store.genres.map((genre) => genre.name).join(", ")}</Grid>
      </Grid>

      {/* エリア */}
      <Grid display="flex" gap={1} className="focus-scroll-store-area">
        <SVGIconAddressColoredGray width={11} height={13} />
        <Grid css={style.ellipsisText}>
          {props.store.areas.map((area) => `${area.prefecture || "北海道"} ${area.area}`).join(", ")}
        </Grid>
      </Grid>

      {/* ストア説明 */}
      <Grid py={1} css={style.description} className="focus-scroll-store-description">
        {props.store.description}
      </Grid>
    </Stack>
  );
};
export default React.memo(StoreOutlineInfo);

const kintoSansNormal = css`
  font-family: ${FontFamily.kintoSans};
  font-style: normal;
  font-width: ${FontWeight.normal};
`;

const style = {
  logoImage: css`
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
  `,
  star: css`
    margin-right: 4px;
  `,
  ratingText: css`
    font-family: ${FontFamily.kintoSans};
    font-style: normal;
    font-weight: ${FontWeight.medium};
    font-size: 12px;
    letter-spacing: 0.1em;
    color: ${FontColor.basic};
  `,
  storeName: css`
    font-family: ${FontFamily.kintoSans};
    font-style: normal;
    font-weight: ${FontWeight.bold};
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: ${FontColor.basic};
  `,
  officialHPContainer: css`
    cursor: pointer;
    width: 66px;
    height: 18px;
    padding: 3.5px 8px;
    border-radius: 12px;
    background-color: ${BackgroundColor.grayChip};
  `,
  chipText: css`
    ${kintoSansNormal};
    font-size: 11px;
    color: ${FontColor.white};
  `,
  ellipsisText: css`
    ${kintoSansNormal};
    width: calc(100% - 24px);
    font-size: 14px;
    line-height: 100%;
    color: ${FontColor.inactive};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  description: css`
    ${kintoSansNormal};
    font-size: 14px;
    line-height: 100%;
    color: ${FontColor.basic};
  `,
};
