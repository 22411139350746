import { css } from "@emotion/react";
import { Button } from "@mui/material";
import { appColor, appTypo, FontColor, FontWeight } from "@unit/styles";
import React, { ReactNode } from "react";

// TODO: 色定義をしっかり決める
type colorThemes =
  | "primary"
  | "accentGray"
  | "naturalGray"
  | "mainBlue"
  | "grassBlue"
  | "accentGreen"
  | "accentRed"
  | "unset"
  | "white";

type Props = {
  color?: colorThemes;
  size: "small" | "medium" | "large";
  borderRadius?: string;
  borderColor?: string;
  backgroundColor?: string;
  disabled?: boolean;
  disabledBackgroundColor?: string;
  startIcon?: ReactNode;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const OutlinedIconButton: React.FC<Props> = (props) => {
  return (
    <Button
      css={customButtonStyled(
        props.color || "unset",
        props.disabledBackgroundColor,
        props.borderRadius,
        props.borderColor,
        props.backgroundColor,
      )}
      type="submit"
      fullWidth
      variant="outlined"
      size={props.size}
      disabled={props.disabled}
      startIcon={props.startIcon}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
export default React.memo(OutlinedIconButton);

const fontColor = {
  primary: FontColor.basic,
  accentGray: FontColor.basic,
  naturalGray: FontColor.sub,
  mainBlue: appColor.Accent.blue,
  grassBlue: FontColor.basic,
  accentGreen: appColor.Accent.green,
  accentRed: FontColor.basic,
  unset: "unset",
  white: FontColor.white,
};

const backgroundColors = {
  primary: appColor.Button.primary.color,
  accentGray: appColor.Button.accent.gray,
  naturalGray: appColor.Accent.gray,
  mainBlue: appColor.Accent.blue,
  grassBlue: appColor.Button.accent.grassBlue,
  accentGreen: appColor.Accent.green,
  accentRed: appColor.Accent.red,
  unset: "unset",
  white: appColor.Accent.blue,
};

const customButtonStyled = (
  color: colorThemes,
  disabledBackgroundColor?: string,
  borderRadius?: string,
  borderColor?: string,
  backgroundColor?: string,
) => css`
  height: 40px;
  font-family: ${appTypo.font.notoJp};
  color: ${fontColor[color]};
  text-transform: none;
  opacity: 0.95;
  font-style: normal;
  font-weight: ${FontWeight.medium};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  padding: 10px 20px;
  border: 1px solid ${borderColor ?? backgroundColors[color]};
  border-radius: ${borderRadius};
  background: ${backgroundColor ?? `${backgroundColors[color]}00`};
  &:hover {
    border: 1px solid ${borderColor ?? backgroundColors[color]};
    background: ${backgroundColor ?? `${backgroundColors[color]}32`};
  }
  &.Mui-disabled {
    color: ${fontColor[color]};
    border: 1px solid ${disabledBackgroundColor ?? backgroundColors[color]};
    background: ${disabledBackgroundColor ?? `${backgroundColors[color]}72`};
  }
  & a:focus {
    color: ${fontColor[color]};
    background: ${backgroundColor ?? `${backgroundColors[color]}00`};
  }
`;
