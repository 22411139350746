export const QueryKeys = {
  getAuthUser: "getAuthUser",
  getGenreList: "getGenreList",
  getAreaList: "getAreaList",
  getAdminMemberList: "getAdminMemberList",
  getAdminMemberById: "getAdminMemberById",
  getOrganizationList: "getOrganizationList",
  getOrganizationById: "getOrganizationById",
  getBrandList: "getBrandList",
  getBrandById: "getBrandById",
  fetchSpotlightList: "fetchSpotlightList",
  getStoreList: "getStoreList",
  getStoreById: "getStoreById",
  getStaffList: "getStaffList",
  getInfluencerList: "getInfluencerList",
  getInfluencerById: "getInfluencerById",
  getTicketList: "getTicketList",
  getTicketById: "getTicketById",
  getInfluencerTicketList: "getInfluencerTicketList",
  getInfluencerStatusLogList: "getInfluencerStatusLogList",

  fecthRoomMessage: "fecthRoomMessage",
  fecthAllRoom: "fecthAllRoom",
  fetchRoomById: "fetchRoomById",
  fetchRoomByTicketId: "fetchRoomByTicketId",
} as const;
