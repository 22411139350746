import * as React from "react";
import { SVGProps } from "react";

export const SVGMapPin = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="noun-location-1859335 2">
      <path
        id="Vector"
        d="M9.07182 0.0804283C8.7174 0.0270348 8.35945 0.000134959 8.00103 0C6.16542 6.75831e-05 4.40525 0.729261 3.10722 2.02715C1.80927 3.32503 1.08008 5.08534 1.08008 6.92095C1.08008 11.1666 4.48173 14.1366 6.51545 15.5327C6.95531 15.8361 7.4768 15.999 8.01115 16C8.52604 16.0007 9.02909 15.8456 9.45414 15.5549C11.4405 14.1978 14.7758 11.3157 14.9172 7.19251H14.9173C14.998 5.4933 14.4445 3.82478 13.3644 2.51066C12.2843 1.19649 10.7545 0.330389 9.07182 0.0805426L9.07182 0.0804283ZM8.00103 9.51628C7.31272 9.51628 6.65252 9.24282 6.16577 8.75612C5.67902 8.26942 5.4056 7.60923 5.4056 6.92086C5.4056 6.23248 5.67907 5.57234 6.16577 5.08559C6.65247 4.59884 7.31266 4.32543 8.00103 4.32543C8.68941 4.32543 9.34954 4.59889 9.83629 5.08559C10.323 5.57229 10.5965 6.23248 10.5965 6.92086C10.5965 7.60923 10.323 8.26937 9.83629 8.75612C9.3496 9.24287 8.68941 9.51628 8.00103 9.51628Z"
        fill={props.color || props.fill || "#2B2A27"}
      />
    </g>
  </svg>
);
