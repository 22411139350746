import { Avatar, css, Grid } from "@mui/material";
import { BorderStyle } from "@unit/styles";
import React from "react";

type Props = {
  src: string;
  noImageUrl?: string;
  size?: number;
  variant?: "circular" | "rounded" | "square";
};

const BasicAvatar: React.FC<Props> = (props) => {
  return (
    <Grid css={style.outer(props.size)}>
      <Avatar src={props.src} css={style.avatar(props.size)} variant={props.variant}>
        <Avatar
          src={props.noImageUrl || ""}
          alt="画像がありません"
          css={style.avatar(props.size)}
          variant={props.variant}
        />
      </Avatar>
    </Grid>
  );
};
export default React.memo(BasicAvatar);

const style = {
  outer: (size: number = 64) => css`
    width: ${size}px;
    height: ${size}px;
    box-sizing: border-box;
    border-radius: 50%;
    ${BorderStyle.solidBasic};
  `,
  avatar: (size: number = 64) => css`
    width: ${size - 4}px;
    height: ${size - 4}px;
    margin: 1px;
    box-sizing: border-box;
    border-radius: 50%;
    ${BorderStyle.none};
  `,
};
