import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BackgroundColor } from "@unit/styles";
import React from "react";

import TicketCard from "../../domain/ticket/TicketCard";
import HomeNavFooter from "../../layout/footer/HomeNavFooter";
import TicketListHeader from "../../layout/header/TicketFilterHeader";

type Props = {
  ticket: APICompsSchema["TicketObject"];
};

const TicketListPreviewPane: React.FC<Props> = (props) => {
  return (
    <>
      <TicketListHeader />
      <Grid css={style.previewPane}>
        {Array.from(new Array(5))
          .map((_) => props.ticket)
          .map((ticket, index) => (
            <Grid px={2} py={1} key={ticket.id}>
              <TicketCard ticket={ticket} />
            </Grid>
          ))}
      </Grid>
      <Grid position="sticky" py={2} css={style.footer}>
        <HomeNavFooter />
      </Grid>
    </>
  );
};
export default React.memo(TicketListPreviewPane);

const style = {
  previewPane: css`
    background: ${BackgroundColor.none};
  `,
  footer: css`
    bottom: 0;
    width: 100%;
    height: 80px;
    background: ${BackgroundColor.lightBasic};
  `,
};
