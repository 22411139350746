import { css } from "@emotion/react";
import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { APICompsSchema, StoreTypeEnum, StoreTypeEnumToValue } from "@unit/apis";
import { BorderColor, FontColor, FontFamily } from "@unit/styles";
import React from "react";

type Props = {
  label: string;
  selectedType: APICompsSchema["StoreTypeEnum"] | null;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  onChange: (newStoreType: APICompsSchema["StoreTypeEnum"]) => void;
  onFocus?: React.FocusEventHandler;
};

const SelectStoreType: React.FC<Props> = (props) => {
  const { label, helperText, required, disabled, selectedType, onChange } = props;
  return (
    <Stack spacing={1}>
      <Box css={style.title}>
        {label}
        {required ? <span>*</span> : null}
      </Box>
      <Grid item xs={12}>
        <FormControl fullWidth size={"small"} onFocus={props.onFocus}>
          <Select
            css={required && !selectedType && style.selectError}
            displayEmpty
            disabled={disabled}
            value={selectedType || ""}
            renderValue={(currentValue) =>
              !!currentValue ? (
                StoreTypeEnumToValue(currentValue)
              ) : (
                <Grid className="notSelected">ストアタイプが未設定です</Grid>
              )
            }
            onChange={(event: SelectChangeEvent) => {
              const newStoreType = event.target.value as APICompsSchema["StoreTypeEnum"];
              if (!StoreTypeEnum.some((store) => store.type === newStoreType)) return;
              onChange(newStoreType);
            }}
          >
            {StoreTypeEnum.map((item, index) => {
              return (
                <MenuItem value={item.type} key={index}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
          {helperText ? (
            <Box css={[style.helperText, required && !selectedType && style.selectError]}>{helperText}</Box>
          ) : null}
        </FormControl>
      </Grid>
    </Stack>
  );
};
export default React.memo(SelectStoreType);

const style = {
  title: css`
    font-family: ${FontFamily.notoSansJp};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    span {
      font-size: 16px;
      color: ${FontColor.error};
    }
  `,
  selectError: css`
    color: ${FontColor.appError};
    fieldset {
      border-color: ${BorderColor.appError};
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${BorderColor.appError};
    }
    .notSelected {
      color: ${FontColor.appError};
    }
  `,
  helperText: css`
    margin: 4px 0 0;
    font-family: ${FontFamily.notoSansJp};
    color: ${FontColor.sub};
    font-size: 12px;
    letter-spacing: 0.1px;
  `,
};
