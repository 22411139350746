export const appColor = {
  App: {
    primary: "#32D3EB",
    sidenav: "#28a9bc",
    accent: "#DE3E44",
    blueMain: "#0BA1E7",
    white: "#FFFFFF",
    welcome: "linear-gradient(240.63deg, #32d3eb 13.44%, #3ab7ff 95.5%)",
  },
  Text: {
    primary: "#2B2A27", // 主にメインテキストなどに使用
    sub: "#575757",
    secondary: "#93918F", // 非アクティブ要素や注釈のテキストなどに使用
    active: "#00A9BC",
    superLightGray: "#E9E9E9",
    gray: "#C2C2C0",
    deepGray: "#92918F",
    white: "#FFFFFF",
  },
  Border: {
    primary: "#2B2A27", // Divider/Border/Strokeが主にアクティブなときに使用
    secondary: "#F4F4F4", // Divider/Border/Strokeが主に非アクティブなときに使用
    third: "#CECECE",
    card: "#E0E0E0",
    gray: "93918F",
    grayLight: "#E2E2E2",
  },
  Background: {
    primary: "#FFFFFF", // メインバックグラウンドで使用
    secondary: "#F7F7F7", // サブコンテンツのバックグラウンドで使用
    selected: "#FCFCFE", // リストアイテムなどが選択状態のときに使用
    welcome: "#F3FBFF",
    third: "#F4F4F4",
    sidenav: "#F4F3F8",
    chat: "#F8FAFB",
    step: "#4A91C7",
    grayLight: "#E2E2E2",
  },
  Progress: {
    empty: "#D9D9D9",
    instagram: "#F11E7A",
  },
  Form: {
    primary: "#FFFFFF", // 活性状態のときに使用
    secondary: "#FCFCFE", // 非活性状態のときに使用
  },
  Button: {
    primary: {
      color: "#2B2A27",
      text: "#FFFFFF",
    }, // メインボタン
    negative: {
      color: "#F4F3F8",
      text: "#B4B4BE",
    }, //主にボタンが非アクティブ状態で使用
    accent: {
      red: "#FF7663",
      gray: "#7F7F89",
      facebook: "#1877F2",
      blue: "#4984bc",
      lightBlue: "#3AB7FF",
      green: "#448c7b",
      grassBlue: "#0BA1E7",
      black: "#000000",
      white: "#ffffff",
    },
    social: {
      facebook: "#5E6E9F",
    },
  },
  Icon: {
    grassBlue: "#0BA1E7",
    red: "#FF7663",
  },
  Accent: {
    blue: "#3AB7FF",
    lightblue: "#32D3EB",
    ultraLightBlue: "#DEF8FC",
    previeBlue: "#4da07b",
    turquoiseBlue: "#51b5ab",
    orange: "#FFAA4E",
    red: "#FF7663",
    yellow: "#FBC929",
    gray: "#93918F",
    green: "#7CBC93",
    deepGreen: "#5AAA76",
    darkGreen: "#478A5E",
  },
  Chart: {
    barAgeGenderF: "#FF80AB",
    barAgeGenderM: "#82B1FF",
    barAgeGenderU: "#DDDDDD",
    ratedCircle: ["#3AB7FF", "#8FCDF2", "#BCDAEB"],
    audienceCity: ["#32D3EB", "#62D9EB", "#91DFEC", "#C1E6ED", "#F0ECEE", "#F7F7F7", "#F4F4F4", "#F1F1F1"],
  },
  Rank: {
    first: "#F2C938",
    second: "#C0C0C0",
    third: "#CD7F32",
  },
} as const;
