import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import React, { MouseEventHandler } from "react";

import AreaChip from "@/components/common/area/AreaChip";
import { LocationType } from "@/data/sampleSelectType";

type Props = {
  selectedAreas: APICompsSchema["FlatAreaObject"][];
  onClick?: MouseEventHandler;
};

const SelectedAreaContainer: React.FC<Props> = (props) => {
  const locations = LocationType.map((location) => {
    return {
      region: location.region,
      prefectures: location.prefectures.filter((prefecture) =>
        props.selectedAreas.some(
          (selectedArea) => selectedArea.prefecture === prefecture && selectedArea.region === location.region,
        ),
      ),
    };
  }).filter((location) => location.prefectures.length);

  return (
    <>
      {props.selectedAreas.length ? (
        locations.map((location) => (
          <Grid key={`area-display-region-${location.region}`} py={0.5}>
            {location.region}
            {location.prefectures.map((prefecture) => (
              <Grid key={`area-display-prefecture-${prefecture}`} container direction={"row"} px={1} py={0.5}>
                <Grid item sm={2} md={1} py={1.5}>
                  {prefecture}
                </Grid>
                <Grid item sm={10} md={11} container direction={"row"} pl={1}>
                  {props.selectedAreas
                    .filter(
                      (selectedArea) =>
                        selectedArea.region === location.region && selectedArea.prefecture === prefecture,
                    )
                    .map((selectedArea) => (
                      <Grid key={`area-display-${selectedArea.id}`} p={0.5}>
                        <AreaChip id={selectedArea.id} label={selectedArea.area} isSelected onClick={props.onClick} />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        ))
      ) : (
        <Grid item p={1} css={missingAreaStyled}>
          エリアが選択されていません
        </Grid>
      )}
    </>
  );
};
export default SelectedAreaContainer;

const missingAreaStyled = css`
  height: 48px;
`;
