import { SVGProps } from "react";

export const SVGRestaurantFoodColoredGray = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12" {...props}>
    <path
      fill="#93918F"
      d="M10.5 0h-.75A2.25 2.25 0 007.5 2.25V7.5a.75.75 0 00.75.75H9v3a.75.75 0 00.75.75h.75a.75.75 0 00.75-.75V.75A.75.75 0 0010.5 0zM4.875 0A.375.375 0 004.5.375V4.5H3V.375a.375.375 0 00-.75 0V4.5H.75V.375a.375.375 0 00-.75 0V5.25a1.5 1.5 0 001.5 1.5v4.5a.75.75 0 00.75.75H3a.75.75 0 00.75-.75v-4.5a1.5 1.5 0 001.5-1.5V.375A.375.375 0 004.875 0z"
    ></path>
  </svg>
);
