import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";

type Props = {
  closableNode?: React.ReactNode;
  children: React.ReactNode;
};

const ClosableDialogHeader: React.FC<Props> = (props) => {
  return (
    <Grid position="relative" container justifyContent="flex-start" alignItems="center">
      {/* タイトル */}
      <Grid xs item css={style.textNode}>
        {props.children}
      </Grid>

      {/* 閉じる */}
      <Grid position="absolute" css={style.rightNode}>
        {props.closableNode}
      </Grid>
    </Grid>
  );
};
export default React.memo(ClosableDialogHeader);

const style = {
  textNode: css`
    text-align: left;
    font-family: ${FontFamily.notoSansJp};
    font-size: 16px;
    font-weight: ${FontWeight.bold};
    color: ${FontColor.basic};
  `,
  rightNode: css`
    text-align: end;
    right: 16px;
  `,
};
