import { logoImageUrl } from "./app-constants";

// 20000 => 2,0万などに変換するよ。
export const formatNumJpTypo = (num: number | undefined): string => {
  if (!num) return "";
  const fmt = new Intl.NumberFormat("ja-JP", {
    notation: "compact",
  });
  return fmt.format(num);
};
export const groupBy = <T extends { [key: string]: any }>(objects: T[], key: keyof T): { [key: string]: T[] } => {
  if (!objects) {
    return {};
  }

  return objects.reduce((map, obj) => {
    map[obj[key]] = map[obj[key]] || [];
    map[obj[key]].push(obj);
    return map;
  }, {} as { [key: string]: T[] });
};

/**
 * 指定したオブジェクト配列に対して、propertyをキーとする連想配列を返す。
 * optionPropertyを指定した場合、ネスト構造を持つ連想配列を返す。
 * @param objectArray
 * @param property
 * @param optionProperty
 * @returns
 */
export const deepGroupBy = (objectArray: any[], property: string, optionProperty = "") => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    if (optionProperty && obj.hasOwnProperty(optionProperty)) {
      acc[key].push(obj[optionProperty]);
    } else {
      acc[key].push(obj);
    }
    return acc;
  }, {});
};

export const convertToWebP = (url: string | null, opts?: { size?: number }) => {
  if (!url) {
    return logoImageUrl;
  }
  const size = opts?.size || 400;

  return `${url}?h=${size}&w=${size}&o=webp`;
};

export class UUID {
  static __uuidIte = (function* () {
    const HEXOCTETS = Object.freeze([...Array(0x100)].map((e, i) => i.toString(0x10).padStart(2, "0").toUpperCase()));
    const VARSION = 0x40;
    const VARIANT = 0x80;
    const bytes = new Uint8Array(16);
    const rand = new Uint32Array(bytes.buffer);
    for (;;) {
      for (let i = 0; i < rand.length; i++) {
        rand[i] = (Math.random() * 0x100000000) >>> 0;
      }
      yield "" +
        HEXOCTETS[bytes[0]] +
        HEXOCTETS[bytes[1]] +
        HEXOCTETS[bytes[2]] +
        HEXOCTETS[bytes[3]] +
        "-" +
        HEXOCTETS[bytes[4]] +
        HEXOCTETS[bytes[5]] +
        "-" +
        HEXOCTETS[(bytes[6] & 0x0f) | VARSION] +
        HEXOCTETS[bytes[7]] +
        "-" +
        HEXOCTETS[(bytes[8] & 0x3f) | VARIANT] +
        HEXOCTETS[bytes[9]] +
        "-" +
        HEXOCTETS[bytes[10]] +
        HEXOCTETS[bytes[11]] +
        HEXOCTETS[bytes[12]] +
        HEXOCTETS[bytes[13]] +
        HEXOCTETS[bytes[14]] +
        HEXOCTETS[bytes[15]];
    }
  })();
  static randomUUID() {
    return this.__uuidIte.next().value;
  }
}

// 引数はbase64形式の文字列
export const toBlob = (base64: string): Blob | null => {
  const bin = atob(base64.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);

  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }

  // Blobを作成
  try {
    const blob = new Blob([buffer.buffer], {
      type: "image/jpeg",
    });
    return blob;
  } catch (e) {
    return null;
  }
};

// カスタムdebounce関数
export const debounce = <T extends (...args: any[]) => unknown>(
  callback: T,
  delay = 250,
): ((...args: Parameters<T>) => void) => {
  let timeout: NodeJS.Timeout; // Node.jsの場合はNodeJS.Timeout型にする
  return (...args) => {
    // 既存のタイムアウトをクリア
    clearTimeout(timeout);

    timeout = setTimeout(() => callback(...args), delay);
  };
};

// ブランクのプロパティを除外する
export const removeBlankProperties = (obj: any) => {
  const newObj: any = {};
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== "") {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

/**
 * UUID（チケットIDなど）を短縮形に変換する
 * @param uuid
 * @param opts
 */
export const convertShortId = (uuid: string, opts?: { readable?: boolean }) => {
  const shortId = uuid.split("-")[0].toUpperCase();
  if (!opts?.readable) {
    return shortId;
  }
  const POSITION = 4;
  return `${shortId.slice(0, POSITION)} - ${shortId.slice(POSITION)}`;
};
