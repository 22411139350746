import { css } from "@emotion/react";

import { appColor } from "./app-colors";
import { appTypo } from "./app-typography";

export const CustomMuiButton = css`
  font-family: ${appTypo.font.notoJp};
  color: ${appColor.App.white};
  text-transform: none;
  background: ${appColor.Button.primary.color};
  opacity: 0.95;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  padding: 10px 20px;
  &:hover {
    background: ${appColor.Button.primary.color};
    opacity: 1;
  }
  &.Mui-disabled {
    opacity: 0.4;
    color: ${appColor.App.white};
    background: ${appColor.Button.primary.color};
  }
  & a:focus {
    color: ${appColor.App.white};
  }
`;

export const CustomMuiButtonSmall = css`
  cursor: pointer;
  font-family: ${appTypo.font.notoJp};
  color: ${appColor.App.white};
  background: ${appColor.Button.primary.color};
  opacity: 0.95;
  &:hover {
    background: ${appColor.Button.primary.color};
    opacity: 1;
  }
  &.Mui-disabled {
    opacity: 0.4;
    color: ${appColor.App.white};
    background: ${appColor.Button.primary.color};
  }
  & a:focus {
    color: ${appColor.App.white};
  }
`;

export const CustomMuiButtonRed = css`
  ${CustomMuiButton};
  background: ${appColor.App.accent};
  &:hover {
    background: ${appColor.App.accent};
  }
  &.Mui-disabled {
    background: ${appColor.App.accent};
  }
`;

export const CustomMuiButtonRedSmall = css`
  ${CustomMuiButtonSmall};
  background: ${appColor.App.accent};
  &:hover {
    background: ${appColor.App.accent};
  }
  &.Mui-disabled {
    background: ${appColor.App.accent};
  }
`;

export const CustomMuiButtonCustom = (colorCode: string) => css`
  ${CustomMuiButtonSmall};
  background: ${colorCode};
  &:hover {
    background: ${colorCode};
  }
  &.Mui-disabled {
    background: ${colorCode};
  }
`;
export const CustomMuiButtonCustomSmall = (colorCode: string) => css`
  ${CustomMuiButtonSmall};
  background: ${colorCode};
  &:hover {
    background: ${colorCode};
    color: ${appColor.App.white};
    opacity: 0.8;
  }
  &.Mui-disabled {
    background: ${colorCode};
  }
`;

export const CustomMuiButtonBorderSmall = (backgroundColorCode: string, borderColorCode: string, color: string) => css`
  ${CustomMuiButtonCustomSmall(backgroundColorCode)};
  border: 1px solid ${borderColorCode};
  color: ${color};

  &:hover {
    color: ${color};
  }
`;

export const CustomAppWhiteButton = css`
  background: ${appColor.App.white};
  font-family: ${appTypo.font.notoJp};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${appColor.Text.primary};
  border: 1px solid ${appColor.Text.primary};
  box-sizing: border-box;
  border-radius: 16px;
  &.MuiButtonBase-root {
    box-shadow: none !important;
  }
  &:hover {
    background: ${appColor.App.white};
    opacity: 1;
  }
  &.Mui-disabled {
    opacity: 0.8;
    background: ${appColor.App.white};
  }
  & a:focus {
    color: ${appColor.App.white};
  }
`;

export const FormStyled = css`
  span {
    font-family: ${appTypo.font.notoJp} !important;
  }
  width: 100%;
  border-radius: 8px;
  background: ${appColor.Border.secondary};
  & .MuiOutlinedInput-root {
    &:hover {
      border-color: ${appColor.App.primary};
    }
    border-radius: 8px;
    fieldset {
      border-color: ${appColor.Border.secondary};
    }
  }
`;

export const CustomMuiSelect = css`
  font-family: ${appTypo.font.notoJp} !important;
  border-radius: 8px;
  & .MuiOutlinedInput-input {
    color: ${appColor.Text.primary};
    background: ${appColor.Border.secondary};
  }
  fieldset {
    border: none;
  }
`;

export const CustomMuiDrawerBody = css`
  max-height: calc(100vh - 172px);
  overflow-y: auto;
`;

export const CustomMuiButtonGradientBlue = css`
  ${CustomMuiButton};
  background: ${appColor.App.welcome};
  &:hover {
    background: ${appColor.App.welcome};
  }
  &.Mui-disabled {
    background: ${appColor.App.welcome};
  }
`;
export const FormStyledMultiple = css`
  span {
    font-family: ${appTypo.font.kintoSans} !important;
  }
  width: 100%;
  border-radius: 4px;
  textarea {
    background: ${appColor.Border.secondary};
  }
  & .MuiInputBase-multiline {
    background: ${appColor.Border.secondary};
    &:hover {
      border-color: ${appColor.App.primary};
    }
    border-radius: 4px;
    fieldset {
      border-color: ${appColor.Border.secondary};
    }
  }
`;

export const AppCursorPointer = css`
  cursor: pointer;
`;
