import { css } from "@emotion/react";
import { Button } from "@mui/material";
import { FontFamily } from "@unit/styles";
import React, { ReactNode } from "react";

type Props = {
  color: string;
  backgroundColor: string;
  borderColor?: string;
  height?: string;
  size: "small" | "medium" | "large";
  disabled?: boolean;
  startIcon?: ReactNode;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const RadiusOutlinedButton: React.FC<Props> = (props) => {
  return (
    <Button
      css={customButtonStyled(props.color, props.backgroundColor, props.borderColor, props.height, props.size)}
      type="submit"
      fullWidth
      variant="outlined"
      size={props.size}
      disabled={props.disabled}
      startIcon={props.startIcon}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
export default React.memo(RadiusOutlinedButton);

const customButtonStyled = (
  color: string,
  backgroundColor: string,
  borderColor?: string,
  height?: string,
  size?: "small" | "medium" | "large",
) => css`
  height: ${height ?? "auto"};
  font-family: ${FontFamily.kintoSans};
  color: ${color};
  text-transform: none;
  opacity: 0.95;
  font-style: normal;
  font-weight: 400;
  font-size: ${size === "small" ? "12px" : "medium" ? "14px" : "16px"};
  line-height: 100%;
  letter-spacing: 0.05em;
  padding: ${size === "small" ? "10px 12px" : "10px 20px"};
  border-radius: 56px;
  border: 1px solid ${borderColor || "none"};
  background: ${backgroundColor};
  &:hover {
    border: 1px solid ${borderColor};
    background: ${backgroundColor};
  }
  &.Mui-disabled {
    color: ${color};
    border: 1px solid ${borderColor || "none"};
    background: ${backgroundColor};
  }
  & a:focus {
    color: ${color};
    background: ${backgroundColor};
  }
  span.MuiButton-iconSizeSmall {
    margin: 0 0 0 -4px;
  }
`;
