import { APICompsSchema } from "@unit/apis";
import { atom } from "jotai";

export const initializingAtom = atom<boolean>(true);
export const loadingAtom = atom<boolean>(false);
export const idTokenAtom = atom<string | undefined>(undefined);
export const authUserAtom = atom<APICompsSchema["FlatAdminObject"] | null>(null);
export const genresAtom = atom<APICompsSchema["FlatGenreObject"][] | null>(null);
export const areasAtom = atom<APICompsSchema["FlatAreaObject"][] | null>(null);

export const selectInfluencerTicketIdAtom = atom<{
  chatOpen: boolean;
  influencerTicketId: string;
}>({
  chatOpen: false,
  influencerTicketId: "",
});

export const pageTitleAtom = atom<string>("");

export const CustomMUIDataTableStateAtom = atom<{
  offset: number;
  limit: number;
  sortBy: string;
  keyword?: string;
}>({ offset: 0, limit: 25, sortBy: "updatedAt-desc" });

export const CustomMUIDataTablePageAtom = atom<{
  page: number;
  rowsPerPage: number;
}>({ page: 0, rowsPerPage: 25 });
