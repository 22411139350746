import * as React from "react";
import { SVGProps } from "react";

export const SVGDish = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 6 10"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M1.063 4.24L.26 3.37.45.614h.328L.776 3.029h.151L.93.61l.267.001V3.03l.134-.001.003-2.417.27.002L1.6 3.032h.137V.612l.333.002.198 2.756-.8.862.488 4.91c-.109.633-1.268.661-1.42 0l.527-4.901zm3.123-.008c-.628-.159-1.106-.965-1.106-1.94 0-.761.407-1.966 1.33-1.966.92 0 1.329 1.203 1.329 1.966 0 .94-.444 1.725-1.038 1.92l.427 4.975c-.109.633-1.268.662-1.419 0l.477-4.955z"
      clipRule="evenodd"
    ></path>
  </svg>
);
