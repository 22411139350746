import { css } from "@emotion/react";
import { Autocomplete, Box, Checkbox, Stack, TextField } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { appTypo } from "@unit/styles";
import React from "react";

type Props = {
  genreList: APICompsSchema["FlatGenreObject"][] | null;
  selectedGenres: APICompsSchema["FlatGenreObject"][];
  onChange: (event: any, newGenres: APICompsSchema["FlatGenreObject"][]) => void;
  onFocus?: React.FocusEventHandler;
};
const SelectGenres: React.FC<Props> = (props) => {
  if (!props.genreList) return <></>;
  return (
    <Autocomplete
      fullWidth
      multiple
      disableCloseOnSelect
      value={props.selectedGenres}
      options={props.genreList}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option: APICompsSchema["FlatGenreObject"]) => option.name}
      onChange={props.onChange}
      onFocus={props.onFocus}
      renderInput={(params) => (
        <Stack spacing={1}>
          <Box css={autoFocusTitleStyled}>ストアジャンル</Box>
          <TextField
            {...params}
            fullWidth
            placeholder={!props.selectedGenres.length ? "ジャンルを選択してください" : ""}
            variant="outlined"
            size="small"
          />
        </Stack>
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} />
          {option.name}
        </li>
      )}
    />
  );
};
export default React.memo(SelectGenres);

const autoFocusTitleStyled = css`
  font-family: ${appTypo.font.notoJp};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
`;
