import { SVGProps } from "react";

export const SVGIconTikTok = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="28" viewBox="0 0 24 28" fill="none" {...props}>
    <path
      d="M17.7867 10.0247C19.5379 11.3058 21.6831 12.0597 24 12.0597V7.49662C23.5615 7.49662 23.1242 7.4499 22.6951 7.35701V10.9488C20.3785 10.9488 18.2336 10.1948 16.4819 8.91377V18.2259C16.4819 22.8843 12.7923 26.6603 8.24114 26.6603C6.54308 26.6603 4.96463 26.1348 3.65359 25.2336C5.14987 26.7997 7.23696 27.7712 9.54564 27.7712C14.0972 27.7712 17.7869 23.9952 17.7869 19.3368V10.0245L17.7867 10.0247ZM19.3964 5.42124C18.4758 4.39432 17.9102 3.08602 17.7867 1.69771V1.11078H16.5502C16.8614 2.92797 17.9231 4.48026 19.3964 5.42124ZM6.53217 21.6588C6.0322 20.9879 5.76196 20.167 5.76322 19.323C5.76322 17.1926 7.45074 15.4651 9.53292 15.4651C9.92085 15.4651 10.3066 15.5258 10.6764 15.6459V10.981C10.2442 10.9203 9.80811 10.8945 9.37204 10.9039V14.5351C9.00205 14.415 8.61619 14.354 8.22806 14.3543C6.14588 14.3543 4.45836 16.0816 4.45836 18.2125C4.45836 19.719 5.30184 21.0233 6.53217 21.6588Z"
      fill="#FF004F"
    />
    <path
      d="M16.4818 8.91377C18.2335 10.1948 20.3784 10.9486 22.6953 10.9486V7.35682C21.4257 7.07892 20.2725 6.40228 19.3964 5.42124C17.923 4.48026 16.8614 2.92778 16.5502 1.11078H13.3024V19.3363C13.2951 21.461 11.6101 23.1813 9.5327 23.1813C8.30837 23.1813 7.22056 22.584 6.53177 21.6588C5.30181 21.0233 4.45814 19.719 4.45814 18.2125C4.45814 16.0818 6.14566 14.3543 8.22784 14.3543C8.62667 14.3543 9.01133 14.418 9.37182 14.5351V10.9041C4.9006 10.9986 1.30469 14.7378 1.30469 19.3364C1.30469 21.632 2.20017 23.713 3.65355 25.2336C5.00999 26.1663 6.60746 26.663 8.24129 26.6601C12.7925 26.6601 16.482 22.8839 16.482 18.2257V8.91377H16.4818Z"
      fill="black"
    />
    <path
      d="M22.6951 7.35661V6.38547C21.5289 6.38729 20.3858 6.05308 19.3964 5.42103C20.2722 6.40239 21.4255 7.07915 22.6951 7.35679V7.35661ZM16.5502 1.11094C16.5205 0.937349 16.4977 0.762597 16.4818 0.587114V0H11.9973V18.2257C11.9901 20.35 10.3056 22.0704 8.22782 22.0704C7.63878 22.0713 7.05778 21.9304 6.53176 21.659C7.22073 22.5842 8.30835 23.1813 9.53268 23.1813C11.61 23.1813 13.2951 21.4611 13.3024 19.3364V1.11075H16.5502V1.11094ZM9.37198 10.9042V9.87036C8.99726 9.8179 8.61948 9.79166 8.24127 9.79181C3.68971 9.79144 0 13.5677 0 18.2257C0 21.1458 1.45011 23.7195 3.65372 25.2334C2.20034 23.713 1.30486 21.6318 1.30486 19.3362C1.30486 14.7378 4.90059 10.9986 9.37198 10.904V10.9042Z"
      fill="#00F2EA"
    />
  </svg>
);
