import { css } from "@emotion/react";
import { Dialog, Divider, Grid } from "@mui/material";
import { Breakpoint } from "@mui/system";
import { appColor, BackgroundColor } from "@unit/styles";
import React from "react";

type Props = {
  open: boolean;
  maxWidth?: Breakpoint;
  onClose: () => void;
  headerNode: React.ReactNode;
  children: React.ReactNode;
  footerNode?: React.ReactNode;
  paddingBody?: number;
};

const DialogFrame: React.FC<Props> = (props) => {
  return (
    <Dialog
      fullWidth
      css={style.dialogFrame}
      maxWidth={props.maxWidth || "md"}
      open={props.open}
      onClose={props.onClose}
    >
      <Grid css={style.header}>
        {/* Dialog Header */}
        <Grid py={2} pl={3}>
          {props.headerNode}
        </Grid>

        <Divider css={style.divider} />
      </Grid>

      {/* Dialog Body */}
      <Grid px={props.paddingBody ?? 3} css={style.body}>
        {props.children}
      </Grid>

      {/* Drawer Footer */}
      {props.footerNode ? (
        <Grid css={style.footer} py={3} px={2}>
          {props.footerNode}
        </Grid>
      ) : null}
    </Dialog>
  );
};
export default React.memo(DialogFrame);

const style = {
  dialogFrame: css``,
  header: css`
    z-index: 100;
    position: sticky;
    top: 0;
    left: 0;
    background: ${BackgroundColor.lightBasic};
  `,
  divider: css``,
  body: css``,
  footer: css`
    z-index: 100;
    position: sticky;
    bottom: 0;
    left: 0;
    background: ${BackgroundColor.lightBasic};
    border-top: 1px solid ${appColor.Border.card};
  `,
};
