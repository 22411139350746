import { css, Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BorderStyle, FontColor } from "@unit/styles";
import { SVGIconGoogle, SVGIconTikTok } from "@unit/svgs";
import React from "react";
import { SiYoutube } from "react-icons/si";

import { SVGIconChainBall } from "../../common/svg-icon/IconChainBall";
import { PNGIconInstagramBall } from "../../common/svg-icon/IconInstagramBall";
import { PNGIconTabelogBall } from "../../common/svg-icon/IconTabelogBall";
import { SVGIconTwitterBall } from "../../common/svg-icon/IconTwitterBall";

type Props = {
  snsAccount: APICompsSchema["SNSAccountObject"];
};

const SnsBallIcon: React.FC<Props> = (props) => {
  const IconType: APICompsSchema["SNSAccountTypeEnum"] | "other" =
    props.snsAccount.type !== "tabelog"
      ? props.snsAccount.type
      : props.snsAccount.accountUrl.includes("tabelog")
      ? "tabelog"
      : "other";

  const ICONS = {
    instagram: <PNGIconInstagramBall />,
    tiktok: (
      <Grid py={0.25} css={style.snsIcon}>
        <SVGIconTikTok height={32} width={40} />
      </Grid>
    ),
    twitter: (
      <Grid css={style.snsIcon}>
        <SVGIconTwitterBall />
      </Grid>
    ),
    youtube: (
      <Grid p={0.5} css={style.snsIcon}>
        <SiYoutube size={30} color={FontColor.youtube} />
      </Grid>
    ),
    google: (
      <Grid p={0.5} css={style.snsIcon}>
        <SVGIconGoogle height={30} width={30} />
      </Grid>
    ),
    tabelog: (
      <Grid css={[style.snsIcon, style.tabelogShift]}>
        {/*<SVGIconTabelog height={32} width={32} viewBox="6 2 20 20" />*/}
        <PNGIconTabelogBall size={32} />
      </Grid>
    ),
    other: <SVGIconChainBall />,
  };

  return ICONS[IconType];
};
export default React.memo(SnsBallIcon);

const style = {
  snsIcon: css`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    ${BorderStyle.solidSubtle};
  `,
  tabelogShift: css`
    padding-top: 4px;
    padding-right: 4px;
    padding-left: 4px;
  `,
};
