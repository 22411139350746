import { css, Grid, Stack } from "@mui/material";
import { FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";

type Props = {
  label: string;
  notice?: string;
  labelSpacing?: number;
  className?: string;
  children: React.ReactNode;
};

const DataItem: React.FC<Props> = (props) => {
  return (
    <Stack gap={1} className={props.className}>
      <Grid css={style.label}>{props.label}</Grid>
      <Grid css={style.info} mt={props.labelSpacing || 0}>
        {props.children}
      </Grid>
      <Grid css={style.notice}>{props.notice}</Grid>
    </Stack>
  );
};
export default React.memo(DataItem);

const style = {
  label: css`
    font-family: ${FontFamily.kintoSans};
    font-style: normal;
    font-weight: ${FontWeight.bold};
    font-size: 14px;
    line-height: 100%;
    color: ${FontColor.basic};
  `,
  info: css`
    font-family: ${FontFamily.kintoSans};
    font-style: normal;
    font-weight: ${FontWeight.semiLight};
    font-size: 14px;
    line-height: 140%;
    color: ${FontColor.basic};
  `,
  notice: css`
    font-family: ${FontFamily.kintoSans};
    font-style: normal;
    font-weight: ${FontWeight.medium};
    font-size: 11px;
    line-height: 140%;
    color: ${FontColor.sub};
  `,
};
