import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class AdminOrganizationApi extends BaseApi {
  async getOrganizationList(
    parameters: APIPaths["/admin-dash/organization/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/organization/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/organization/list", {
        params: parameters,
      })
    ).data;
  }
  async registerOrganizationNew(
    requestBody: APIPaths["/admin-dash/organization/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/organization/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/organization/new", requestBody)).data;
  }
  async getOrganizationById(
    id: APIPaths["/admin-dash/organization/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/organization/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/organization/${id}`)).data;
  }
  async patchOrganization(
    id: APIPaths["/admin-dash/organization/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/organization/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/organization/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/organization/${id}`, requestBody)).data;
  }
  async deleteOrganization(
    id: APIPaths["/admin-dash/organization/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/organization/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/organization/${id}`)).data;
  }
  // 認証系
  async putApproveOrganizationById(
    id: APIPaths["/admin-dash/organization/{id}/request-approval"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/organization/{id}/request-approval"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/organization/${id}/request-approval`)).data;
  }
  async putRejectOrganizationById(
    id: APIPaths["/admin-dash/organization/{id}/request-rejection"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/organization/{id}/request-rejection"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/organization/{id}/request-rejection"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/organization/${id}/request-rejection`, requestBody)).data;
  }
  // 無効有効
  async putActivateOrganizationById(
    id: APIPaths["/admin-dash/organization/{id}/activate"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/organization/{id}/activate"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/organization/${id}/activate`)).data;
  }
  async putDeactivateOrganizationById(
    id: APIPaths["/admin-dash/organization/{id}/disable"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/organization/{id}/disable"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/organization/${id}/disable`)).data;
  }
}
