import { jaJP } from "@mui/material/locale";
import { createTheme, ThemeOptions } from "@mui/material/styles";

import ColorPalette from "./app-style/color-palette";
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

// Material-UIパーツのテーマ
export const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1800,
    },
  },
  palette: {
    primary: {
      main: ColorPalette.dodgerBlue,
    },
    secondary: {
      main: "#788782",
    },
    error: {
      main: "#DE3E44",
    },
    background: {
      default: "#fff",
    },
  },
};

export const muiTheme = createTheme(themeOptions, jaJP);
