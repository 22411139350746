import * as React from "react";
import { SVGProps } from "react";

export const SvgIconStar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width} height={props.height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fill={props.color}>
      <path d="M11.77 15.928a1.824 1.824 0 0 1-.858-.218l-2.77-1.482a.415.415 0 0 0-.384 0l-2.79 1.443a1.82 1.82 0 0 1-2.629-1.94l.553-3.092a.412.412 0 0 0-.117-.352L.541 8.077A1.82 1.82 0 0 1 1.57 4.98l3.112-.43a.415.415 0 0 0 .313-.225L6.404 1.51A1.809 1.809 0 0 1 8.029.5a1.81 1.81 0 0 1 1.64 1.028l1.37 2.815a.412.412 0 0 0 .309.229l3.104.475a1.82 1.82 0 0 1 .985 3.114l-2.263 2.175a.414.414 0 0 0-.12.352l.511 3.1a1.823 1.823 0 0 1-1.795 2.14Z" />
      <path d="M7.945 12.789c.3 0 .594.074.859.215l2.77 1.481a.412.412 0 0 0 .601-.433l-.51-3.1a1.823 1.823 0 0 1 .535-1.608l2.266-2.186a.412.412 0 0 0-.225-.703l-3.104-.475a1.82 1.82 0 0 1-1.348-1.014L8.42 2.151a.412.412 0 0 0-.742 0L6.27 4.966a1.82 1.82 0 0 1-1.408.964l-3.11.43a.412.412 0 0 0-.233.703L3.761 9.27a1.82 1.82 0 0 1 .514 1.615l-.553 3.094a.415.415 0 0 0 .598.44l2.791-1.444c.26-.127.545-.19.834-.186Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
