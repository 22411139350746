import { css, Divider, Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BackgroundColor, BorderStyle } from "@unit/styles";
import { convertToWebP, logoImageUrl } from "@unit/utility";
import React from "react";

import StoreAvatarInformation from "../store/StoreAvatarInformation";

import TicketDescription from "./TicketDescription";

type Props = {
  ticket: APICompsSchema["TicketObject"];
  dashboard?: boolean;
};

const TicketCard: React.FC<Props> = (props) => {
  const { ticket } = props;
  const ticketImageUrl = ticket.imageUrls.length ? convertToWebP(ticket.imageUrls[0]) : logoImageUrl;
  return (
    <Grid position="relative" sx={{ background: BackgroundColor.lightBasic }}>
      {/* チケット画像 */}
      <Grid position="absolute">
        <img src={ticketImageUrl} srcSet={ticketImageUrl} loading="lazy" css={style.ticketImage} />
      </Grid>

      <Grid css={style.textInfoSection}>
        {/* チケット内容説明 */}
        <TicketDescription ticket={ticket} dashboard={props.dashboard} />

        <Grid py={1}>
          <Divider css={style.divider} />
        </Grid>

        {/* ストア情報 */}
        <StoreAvatarInformation store={props.ticket.store} />
      </Grid>
    </Grid>
  );
};
export default React.memo(TicketCard);

const style = {
  textInfoSection: css`
    width: 100%;
    padding-left: 112px;
  `,
  ticketImage: css`
    width: 96px;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
  `,
  divider: css`
    ${BorderStyle.subtleDivider};
  `,
};
