import * as React from "react";
import { SVGProps } from "react";

export const SVGIconSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 17.483a9 9 0 0 0 10.317-.221l5.443 5.443a1 1 0 1 0 1.415-1.415l-5.384-5.384A9 9 0 1 0 5 17.483ZM6.11 4.18a7 7 0 1 1 7.778 11.64A7 7 0 0 1 6.111 4.18Z"
      fill={props.fill}
    />
  </svg>
);
