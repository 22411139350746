import { css } from "@emotion/react";
import { Divider, Grid, Stack } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { NewLineText } from "@unit/component";
import { useAppSnackbar } from "@unit/hooks";
import { BackgroundColor, BorderColor, BorderStyle, FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import DataItem from "../../../common/accordion/DataItem";
import PrMethodChip from "../../pr-method-type/chip/PrMethodChip";

type Props = {
  ticket: APICompsSchema["TicketObject"];
};

const TicketPrInfo: React.FC<Props> = (props) => {
  const { ticket } = props;
  const { setAppSnackbar } = useAppSnackbar();

  return (
    <>
      <Grid p={2} css={style.title}>
        あなたにお願いしたいこと
      </Grid>
      <Divider css={BorderStyle.subtleDivider} className="focus-scroll-ticket-pr" />
      <Stack p={2} gap={2}>
        <DataItem label="PR方法の指定" notice="※PRや広告とわかるように投稿をお願い致します。">
          <PrMethodChip prMethodType={props.ticket.prMethodType} />
        </DataItem>
        <Divider css={style.border} />
        <DataItem label="その他の指定事項">
          <CopyToClipboard
            text={ticket.remark || ""}
            onCopy={() => setAppSnackbar("その他の指定事項をクリップボードにコピーしました。", { success: true })}
          >
            <Grid p={1} css={[style.copyableNote, style.text]}>
              <NewLineText text={ticket.remark || "特に指定なし"} />
            </Grid>
          </CopyToClipboard>
        </DataItem>
      </Stack>
    </>
  );
};
export default React.memo(TicketPrInfo);

const style = {
  title: css`
    font-family: ${FontFamily.notoSansJp};
    font-weight: ${FontWeight.bold};
    font-style: normal;
    font-size: 16px;
    color: ${FontColor.basic};
  `,
  text: css`
    font-family: ${FontFamily.notoSansJp};
    font-weight: ${FontWeight.semiLight};
    font-style: normal;
    font-size: 14px;
    color: ${FontColor.basic};
    line-height: 140%;
  `,
  copyableNote: css`
    cursor: pointer;
    border-radius: 4px;
    background: ${BackgroundColor.none};
  `,
  border: css`
    border: 1px dashed ${BorderColor.subtle};
  `,
};
