import { IconButton } from "@mui/material";
import React, { useCallback } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

type Props = {
  onClick: () => void;
};

const RoundDeleteButton: React.FC<Props> = (props) => {
  const handleClose = useCallback(() => {
    props.onClick();
  }, []);
  return (
    <IconButton size="small" onClick={handleClose}>
      <RiDeleteBin6Line size={16} />
    </IconButton>
  );
};
export default React.memo(RoundDeleteButton);
