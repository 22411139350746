import { FontColor } from "@unit/styles";
import { SVGIconFilter } from "@unit/svgs";
import React from "react";

import AppBasicIconButton from "../../../common/button/AppBasicIconButton";

const TicketFilterOpener: React.FC = () => {
  return (
    <AppBasicIconButton>
      <SVGIconFilter color={FontColor.basic} />
    </AppBasicIconButton>
  );
};
export default React.memo(TicketFilterOpener);
