import { css } from "@emotion/react";
import { appColor, appTypo } from "@unit/styles";
import { isAfter, isBefore, subYears } from "date-fns";
import * as React from "react";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/DateRangePicker";
import { useCustomMediaQuery } from "@unit/hooks";

type Props = {
  dateRange: DateRange;
  onChangeDateRange: (dateRange: DateRange) => void;
};

const DateRangeSelector: React.FC<Props> = (props) => {
  const { isMobile } = useCustomMediaQuery();
  const handleOnDateRange = (newDateRange: DateRange | null) => {
    if (newDateRange) {
      props.onChangeDateRange(newDateRange);
    }
  };

  return (
    <DateRangePicker
      placeholder={"期間を指定してください"}
      size="md"
      format={`yyyy/MM/dd`}
      value={props.dateRange}
      onChange={handleOnDateRange}
      onOk={handleOnDateRange}
      css={dateRangeStyled}
      showOneCalendar={isMobile}
      appearance="subtle"
      cleanable={false}
      placement={"bottomEnd"}
      disabledDate={(date) => isAfter(date, new Date()) || isBefore(date, subYears(new Date(), 1))}
      ranges={[]}
      locale={{
        sunday: "日",
        monday: "月",
        tuesday: "火",
        wednesday: "水",
        thursday: "木",
        friday: "金",
        saturday: "土",
        ok: "決定",
      }}
    />
  );
};
export default React.memo(DateRangeSelector);

const dateRangeStyled = css`
  font-family: ${appTypo.font.roboto};
  font-style: normal;
  font-weight: 500;
  border-bottom: 1px solid ${appColor.App.primary} !important;
  border-color: ${appColor.App.primary} !important;
  text-align: right;
  & .rs-picker-toggle-value {
    color: ${appColor.Text.secondary} !important;
  }
  & .rs-picker-toggle-textbox {
    color: ${appColor.Text.secondary} !important;
    background-color: ${appColor.Background.primary};
  }
  & .rs-picker-toggle {
    box-shadow: none !important;
    &.rs-btn {
      padding: 4px 32px 4px 4px;
    }
    & svg {
      top: 4px !important;
    }
  }
  input {
    cursor: pointer;
  }
`;
