import * as React from "react";
import { SVGProps } from "react";

export const SVGIconInfluencerTicket = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <path
      d="M8.63121 9.62069C8.63121 9.97247 8.49143 10.3098 8.24275 10.5585C7.99406 10.8072 7.6567 10.9469 7.30495 10.9469C6.9532 10.9469 6.61582 10.8072 6.36715 10.5585C6.11848 10.3098 5.97869 9.97244 5.97869 9.62069C5.97869 9.26894 6.11847 8.93157 6.36715 8.68289C6.61584 8.43422 6.9532 8.29443 7.30495 8.29443C7.6567 8.29443 7.99407 8.43421 8.24275 8.68289C8.49142 8.93158 8.63121 9.26894 8.63121 9.62069ZM13.9362 9.62069C13.9362 9.97247 13.7965 10.3098 13.5478 10.5585C13.2991 10.8072 12.9617 10.9469 12.61 10.9469C12.2582 10.9469 11.9209 10.8072 11.6722 10.5585C11.4235 10.3098 11.2837 9.97244 11.2837 9.62069C11.2837 9.26894 11.4235 8.93157 11.6722 8.68289C11.9209 8.43422 12.2582 8.29443 12.61 8.29443C12.9617 8.29443 13.2991 8.43421 13.5478 8.68289C13.7964 8.93158 13.9362 9.26894 13.9362 9.62069ZM19.2413 9.62069C19.2413 9.97247 19.1015 10.3098 18.8528 10.5585C18.6041 10.8072 18.2668 10.9469 17.915 10.9469C17.5633 10.9469 17.2259 10.8072 16.9772 10.5585C16.7285 10.3098 16.5888 9.97244 16.5888 9.62069C16.5888 9.26894 16.7285 8.93157 16.9772 8.68289C17.2259 8.43422 17.5633 8.29443 17.915 8.29443C18.2668 8.29443 18.6041 8.43421 18.8528 8.68289C19.1015 8.93158 19.2413 9.26894 19.2413 9.62069Z"
      fill={props.color || props.fill || "#93918F"}
    />
    <path
      d="M19.2414 2.32626C19.9446 2.32709 20.6188 2.60673 21.1161 3.10408C21.6134 3.60132 21.8931 4.27554 21.8939 4.97877V14.2626C21.8931 14.9658 21.6134 15.64 21.1161 16.1373C20.6188 16.6346 19.9446 16.9143 19.2414 16.9151H12.0607L7.30505 21.6708V16.9151H5.97879C5.27556 16.9143 4.60134 16.6346 4.1041 16.1373C3.60676 15.64 3.3271 14.9658 3.32628 14.2626V4.97877C3.32711 4.27554 3.60676 3.60132 4.1041 3.10408C4.60134 2.60673 5.27556 2.32708 5.97879 2.32626H19.2414ZM19.2414 1H5.97879C4.9236 1 3.91148 1.41923 3.16527 2.16536C2.41907 2.91148 1.99992 3.92358 1.99992 4.97888V14.2627C1.99992 15.3179 2.41915 16.33 3.16527 17.0762C3.9114 17.8224 4.92349 18.2416 5.97879 18.2416V21.671C5.97786 22.0249 6.11847 22.3646 6.36943 22.6142C6.62038 22.8639 6.96065 23.0027 7.3146 23C7.6641 22.9998 7.99866 22.8587 8.24288 22.6088L12.61 18.2417H19.2413C20.2965 18.2417 21.3086 17.8225 22.0548 17.0764C22.801 16.3302 23.2201 15.3181 23.2201 14.2628V4.97904C23.2201 3.92384 22.8009 2.91172 22.0548 2.16552C21.3087 1.41931 20.2966 1.00016 19.2413 1.00016L19.2414 1Z"
      fill={props.color || props.fill || "#93918F"}
    />
  </svg>
);
