import { Button, css, Grid } from "@mui/material";
import { BorderColor, FontColor, FontFamily } from "@unit/styles";
import { UUID } from "@unit/utility";
import React, { useEffect } from "react";

type Props = {
  messageId: string;
  message: string;
  onClick: () => void;
};

const UndoAppSnackbarMessage: React.FC<Props> = (props) => {
  const { messageId, message, onClick } = props;

  const closeFieldId = `cf_${UUID.randomUUID()}`;

  useEffect(() => {
    setTimeout(() => {
      const messageElem = document.querySelector(`div[id="${messageId}"]`);
      const closeFieldElem = messageElem?.querySelector(`div[id="${closeFieldId}"]`);
      const closeBtnElem = messageElem?.querySelector(`button[class*="Toastify__close"]`);
      if (closeFieldElem && closeBtnElem) {
        closeFieldElem.appendChild(closeBtnElem);
      }
    }, 1);
  }, []);

  return (
    <Grid container direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
      <Grid item xs>
        {message}
      </Grid>
      <Grid item id={closeFieldId}></Grid>
      <Grid item xs={12} />
      <Grid item pt={1}>
        <Button variant="outlined" size={"small"} onClick={onClick} css={undoButtonStyled}>
          元に戻す
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(UndoAppSnackbarMessage);

const undoButtonStyled = css`
  font-family: ${FontFamily.kintoSans};
  color: ${FontColor.white};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  border-color: ${BorderColor.white};
  padding: 2px 8px;
  &:hover {
    border-color: ${BorderColor.white};
    opacity: 0.7;
  }
`;
