import { css, Grid } from "@mui/material";
import { APICompsSchema, PRMethodTypeEnumObject } from "@unit/apis";
import { BackgroundColor, FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";

import SnsIcon from "../icon/SnsIcon";

type Props = {
  prMethodType?: APICompsSchema["PRMethodTypeEnum"];
};

const PrMethodChip: React.FC<Props> = (props) => {
  return props.prMethodType ? (
    <Grid
      display="inline-flex"
      alignItems="center"
      gap={1}
      // width={120}
      height={32}
      px={1.5}
      py={1}
      css={style.prMethodChip}
    >
      <SnsIcon active size={16} prMethodType={props.prMethodType} />
      {PRMethodTypeEnumObject[props.prMethodType] || ""}
    </Grid>
  ) : (
    <></>
  );
};
export default React.memo(PrMethodChip);

const style = {
  prMethodChip: css`
    border-radius: 16px;
    background: ${BackgroundColor.none};

    font-family: ${FontFamily.notoSansJp};
    font-weight: ${FontWeight.medium};
    font-style: normal;
    font-size: 14px;
    color: ${FontColor.basic};
  `,
};
