import { css } from "@emotion/react";
import { Tab, Tabs } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { appColor } from "@unit/styles";
import React, { useState } from "react";

import TicketDetailPreviewPane from "../pane/ticket/TicketDetailPreviewPane";
import TicketListPreviewPane from "../pane/ticket/TicketListPreviewPane";

import PreviewTemplate from "./PreviewTemplate";

type TicketPreviewType = "DETAIL" | "LIST";

type Props = {
  ticket: APICompsSchema["TicketObject"];
};

const TicketPreview: React.FC<Props> = (props) => {
  const [ticketPreviewType, setTicketPreviewType] = useState<TicketPreviewType>("DETAIL");

  return (
    <PreviewTemplate
      topNode={
        <Tabs
          orientation="horizontal"
          value={ticketPreviewType}
          textColor="primary"
          aria-label="select-preview-type"
          centered
          css={style.tabs}
          onChange={(event, newValue: TicketPreviewType) => {
            setTicketPreviewType(newValue);
          }}
        >
          {/* チケット詳細 */}
          <Tab label={"チケット詳細"} value="DETAIL" />
          {/* チケット一覧 */}
          <Tab label={"チケット一覧"} value="LIST" />
        </Tabs>
      }
    >
      <>
        {ticketPreviewType === "DETAIL" ? (
          <TicketDetailPreviewPane ticket={props.ticket} />
        ) : (
          <TicketListPreviewPane ticket={props.ticket} />
        )}
      </>
    </PreviewTemplate>
  );
};
export default React.memo(TicketPreview);

const style = {
  tabs: css`
    .MuiButtonBase-root.MuiTab-root {
      font-weight: 700;
      &.Mui-selected {
        color: ${appColor.Button.accent.grassBlue};
      }
    }
    .MuiTabs-indicator {
      background: ${appColor.Button.accent.grassBlue};
    }
  `,
};
