import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { BorderColor, FontColor, FontFamily, FontWeight } from "@unit/styles";
import React, { useCallback, useMemo } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { MdOutlineFileUpload } from "react-icons/md";

type Props = {
  onLoad: (files: File[]) => void;
};

const CsvDropzone: React.FC<Props> = (props) => {
  const onDrop = useCallback((files: File[]) => {
    props.onLoad(files);
  }, []);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { "text/csv": [".csv"] },
  });
  const filesUpdated: FileWithPath[] = acceptedFiles;

  const files = useMemo(
    () =>
      filesUpdated.map((file) => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      )),
    [filesUpdated],
  );

  return (
    <Grid css={style.container(isDragActive)}>
      <Grid {...getRootProps()} css={style.dropzone}>
        <input {...getInputProps()} type="file" accept=".csv" />

        <Grid p={3} height={218} container direction="column" justifyContent="space-between" alignItems="center">
          <Grid item xs py={1} css={style.titleText}>
            CSVファイルをアップロード
          </Grid>
          <Grid pb={1} item xs>
            <MdOutlineFileUpload color={FontColor.basic} size={64} />
          </Grid>
          <Grid item xs>
            <Grid p={1.25} css={[style.button, style.titleText]}>
              ファイルを選択
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default React.memo(CsvDropzone);

const style = {
  container: (isDragActive?: boolean) => css`
    width: 100%;
    height: 100%;
    opacity: ${isDragActive ? 0.3 : 1};
  `,
  dropzone: css`
    width: 100%;
    border: 2px solid ${BorderColor.basic};
    border-radius: 16px;

    cursor: pointer;
  `,
  titleText: css`
    font-family: ${FontFamily.notoSansJp};
    font-size: 16px;
    font-weight: ${FontWeight.bold};
  `,
  button: css`
    width: 200px;
    height: 48px;
    border: 2px solid ${BorderColor.basic};
    border-radius: 8px;

    text-align: center;
  `,
};
