import { APICompsSchema } from "../api-config";

export const flatSnsAccountObject = (): APICompsSchema["FlatSNSAccountObject"] => {
  return {
    id: "",
    type: "instagram",
    accountUrl: "",
    imageUrl: null,
    providerId: null,
    accessToken: null,
    refreshToken: null,
    accessTokenExpiresAt: null,
    refreshTokenExpiresAt: null,
    createdAt: 0,
    updatedAt: 0,
  };
};
