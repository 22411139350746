import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";

type Props = {
  leftItemNode?: React.ReactNode;
  rightItemNode?: React.ReactNode;
  children: React.ReactNode;
};

const DialogHeaderBase: React.FC<Props> = (props) => {
  return (
    <Grid position="relative" container justifyContent="center" alignItems="center">
      {/* 左 */}
      <Grid position="absolute" css={style.leftNode}>
        {props.leftItemNode}
      </Grid>

      {/* タイトル */}
      <Grid xs item css={style.centerText}>
        {props.children}
      </Grid>

      {/* 右 */}
      <Grid position="absolute" css={style.rightNode}>
        {props.rightItemNode}
      </Grid>
    </Grid>
  );
};
export default React.memo(DialogHeaderBase);

const style = {
  leftNode: css`
    text-align: start;
    left: 0;
  `,
  centerText: css`
    text-align: center;
    font-family: ${FontFamily.notoSansJp};
    font-size: 16px;
    font-weight: ${FontWeight.bold};
    color: ${FontColor.basic};
  `,
  rightNode: css`
    text-align: end;
    right: 0;
  `,
};
