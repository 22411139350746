import * as React from "react";
import { SVGProps } from "react";

export const SVGIconAddress = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width} height={props.height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.864 13a.546.546 0 0 1-.369-.145C5.318 12.705 1.5 9.141 1.5 5.364a4.364 4.364 0 1 1 8.727 0c0 3.728-3.818 7.339-3.992 7.489a.546.546 0 0 1-.371.147Zm0-10.91A3.273 3.273 0 0 0 2.59 5.365c0 2.656 2.343 5.356 3.273 6.319.924-.974 3.272-3.696 3.272-6.32a3.273 3.273 0 0 0-3.272-3.272Z"
      fill={props?.fill || "#000"}
    />
    <path
      d="M5.864 7.273a1.909 1.909 0 1 1 0-3.818 1.909 1.909 0 0 1 0 3.818Zm0-2.728a.818.818 0 1 0 0 1.637.818.818 0 0 0 0-1.637Z"
      fill={props?.fill || "#000"}
    />
  </svg>
);
