import { APICompsSchema } from "../api-config";

import { flatAddressObject } from "./address";
import { flatBrandObject } from "./brand";
import { organizationObject } from "./organization";

export const flatStoreObject = (): APICompsSchema["FlatStoreObject"] => {
  return {
    id: "",
    name: "",
    type: null,
    url: null,
    reservationUrl: null,
    iconImageUrl: null,
    storeUrls: [],
    imageUrls: [],
    email: null,
    phoneNumber: null,
    businessHours: null,
    regularHoliday: null,
    status: "editing",
    statusComment: null,
    description: null,
    accessInfo: null,
    rating: 0,
    ratingCount: 0,
    favoriteCount: 0,
    createdAt: 0,
    updatedAt: 0,
  };
};

export const storeObject = (): APICompsSchema["StoreObject"] => {
  return {
    ...flatStoreObject(),
    isFavorite: null,
    address: flatAddressObject(),
    organization: organizationObject(),
    brand: flatBrandObject(),
    areas: [],
    genres: [],
    snsAccounts: [],
  };
};

export const flatStoreUrlObject = (): APICompsSchema["FlatStoreUrlObject"] => {
  return {
    id: "",
    type: "other-reservation",
    url: "",
    createdAt: 0,
  };
};
