import * as React from "react";
import { SVGProps } from "react";

export const SVGIconHome = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.301 7.924 13.93 1.658a2.765 2.765 0 0 0-3.58 0L2.977 7.924A2.765 2.765 0 0 0 2 10.03v10.205A2.765 2.765 0 0 0 4.765 23H7.99a2.304 2.304 0 0 0 2.304-2.304v-3.92a1.152 1.152 0 0 1 1.147-1.148h1.382a1.153 1.153 0 0 1 1.157 1.147v3.921A2.304 2.304 0 0 0 16.283 23h3.226a2.765 2.765 0 0 0 2.764-2.765V10.03a2.766 2.766 0 0 0-.972-2.106Zm-.87 12.311a.921.921 0 0 1-.922.922h-3.226a.46.46 0 0 1-.46-.46v-3.922a2.995 2.995 0 0 0-2.99-2.99H11.45a2.993 2.993 0 0 0-3 2.99v3.921a.46.46 0 0 1-.46.461H4.765a.922.922 0 0 1-.922-.922V10.03a.922.922 0 0 1 .323-.7l7.372-6.267a.921.921 0 0 1 1.193 0l7.372 6.266a.922.922 0 0 1 .327.7v10.206Z"
      fill={props.fill}
    />
  </svg>
);
