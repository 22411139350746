import { Area } from "react-easy-crop";

import { useAppSnackbar } from "./use-app-snackbar";

export const useImageUploader = () => {
  const { setAppSnackbar } = useAppSnackbar();
  const validateImage = (file: File) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setAppSnackbar("You can only upload JPG/PNG file!", {
        error: true,
      });
      return false;
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      setAppSnackbar("Image must smaller than 10MB", {
        error: true,
      });
      return false;
    }
    return isJpgOrPng && isLt10M;
  };

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      // CodeSandboxでCORSエラーを回避するために必要
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const customCropImage = async (imageSrc: string, pixelCrop: Area) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return null;
    // canvasサイズを設定
    canvas.width = image.width;
    canvas.height = image.height;
    // canvas上に画像を描画
    ctx.drawImage(image, 0, 0);
    // トリミング後の画像を抽出
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);
    // canvasのサイズ指定(切り取り後の画像サイズに更新)
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    // 抽出した画像データをcanvasの左隅に貼り付け
    ctx.putImageData(data, 0, 0);
    // canvasを画像に変換
    return canvas.toDataURL("image/jpeg");
  };

  const getImageFileName = (prefix: string) => {
    const currentUnixTime = new Date().getTime();
    return `${prefix}_${currentUnixTime}.jpeg`;
  };

  return { validateImage, customCropImage, getImageFileName };
};
