import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class AreaApi extends BaseApi {
  async getAreaList(
    parameters: APIPaths["/app/area/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/app/area/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/app/area/list-all", { params: parameters })).data;
  }
}
