import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class AdminBrandApi extends BaseApi {
  async getBrandList(
    parameters: APIPaths["/admin-dash/brand/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/brand/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/brand/list", {
        params: parameters,
      })
    ).data;
  }
  async registerBrandNew(
    requestBody: APIPaths["/admin-dash/brand/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/brand/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/brand/new", requestBody)).data;
  }
  async getBrandById(
    id: APIPaths["/admin-dash/brand/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/brand/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/brand/${id}`)).data;
  }
  async patchBrandById(
    id: APIPaths["/admin-dash/brand/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/brand/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/brand/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/brand/${id}`, requestBody)).data;
  }
  async deleteBrandById(
    id: APIPaths["/admin-dash/brand/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/brand/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/brand/${id}`)).data;
  }
  // 認証系
  async putRequestBrandById(
    id: APIPaths["/admin-dash/brand/{id}/request"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/brand/{id}/request"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/brand/${id}/request`)).data;
  }
  async putApproveBrandById(
    id: APIPaths["/admin-dash/brand/{id}/request-approval"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/brand/{id}/request-approval"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/brand/${id}/request-approval`)).data;
  }
  async putRejectBrandById(
    id: APIPaths["/admin-dash/brand/{id}/request-rejection"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/brand/{id}/request-rejection"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/brand/{id}/request-rejection"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/brand/${id}/request-rejection`, requestBody)).data;
  }
  async putOpenBrandById(
    id: APIPaths["/admin-dash/brand/{id}/open"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/brand/{id}/open"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/brand/${id}/open`)).data;
  }
  async putCloseBrandById(
    id: APIPaths["/admin-dash/brand/{id}/close"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/brand/{id}/close"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/brand/${id}/close`)).data;
  }
}
