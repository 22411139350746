import { css } from "@emotion/react";
import { Badge, Grid } from "@mui/material";
import { FontColor, FontFamily } from "@unit/styles";
import React from "react";

type Props = {
  label: string;
  badgeContent?: number;
  isActive: boolean;
  children: React.ReactNode;
};

const FooterItem: React.FC<Props> = (props) => {
  return (
    <>
      <Grid textAlign={"center"}>
        <Badge badgeContent={props.badgeContent} max={99} color="warning" css={style.countBadge(props.badgeContent)}>
          {props.children}
        </Badge>
      </Grid>
      <Grid py={1} css={style.label(props.isActive)}>
        {props.label}
      </Grid>
    </>
  );
};
export default React.memo(FooterItem);

const style = {
  label: (isActive: boolean) => css`
    font-family: ${FontFamily.notoSansJp};
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 100%;
    color: ${isActive ? FontColor.basic : FontColor.inactive};
    text-align: center;
  `,
  countBadge: (count?: number) => {
    if (!count) return;
    let badgeWidth = count.toString().length;
    if (badgeWidth === 1) return;
    else if (badgeWidth === 2) badgeWidth = 20;
    else if (badgeWidth === 3) badgeWidth = 24;
    return css`
      & .MuiBadge-badge {
        width: ${badgeWidth}px;
        padding: 0;
      }
    `;
  },
};
