import { css, Grid } from "@mui/material";
import { appColor } from "@unit/styles";
import { CustomHeadLabelRenderOptions, MUISortOptions } from "mui-datatables";
import React from "react";
import { BiSortAlt2 } from "react-icons/bi";

type Props = {
  columnOptions: CustomHeadLabelRenderOptions;
  sortOrder?: MUISortOptions;
};

const CustomHeadLabel: React.FC<Props> = (props) => {
  const { name, label } = props.columnOptions;
  const isSorting = props.sortOrder?.direction && name === props.sortOrder.name;
  return (
    <Grid position="relative" display="flex" alignItems="center">
      {label}
      {!isSorting && <BiSortAlt2 size={18} color={appColor.Text.gray} css={iconPositionStyled} />}
    </Grid>
  );
};
export default React.memo(CustomHeadLabel);

const iconPositionStyled = css`
  position: relative;
  right: -4px;
  margin-right: 8px;
`;
