// ページ系
export const loginPath = "/";
export const dashboardPath = "/dashboard";
export const adminsPath = "/admins";
export const organizationsPath = "/organizations";
export const organizationsPathById = (id: string) => `/organizations/${id}`;
export const organizationsJudgementPath = "/organizations/judgement";
export const brandsPath = "/brands";
export const brandsJudgementPath = "/brands/judgement";
export const spotlightsPath = "/spotlights";
export const spotlightsPathById = (id: string) => `/spotlights/${id}`;
export const storesPath = "/stores";
export const storesJudgementPath = "/stores/judgement";
export const storesPathById = (id: string) => `/stores/${id}`;
export const staffsPath = "/staffs";
export const staffsPathById = (id: string) => `/staffs/${id}`;
export const ticketsPath = "/tickets";
export const ticketsNewPath = "/tickets/new";
export const ticketsEditPathName = "/tickets/[ticketId]/edit";
export const ticketsOfferPath = "/tickets/offer";
export const ticketsJudgementPath = "/tickets/judgement";
export const ticketsPathById = (id: string) => `/tickets/${id}`;
export const ticketsEditPathById = (id: string) => `/tickets/${id}/edit`;
export const influencersPath = "/influencers";
export const influencerTicketsDeactivatePath = "/influencers/deactivate";
export const influencerPathById = (id: string) => `/influencers/${id}`;

export const influencerTicketsPathById = (id: string) => `/influencer-tickets/${id}`;
export const influencerTicketsPath = "/influencer-tickets";

// previewPath
export const ticketsPreviewPath = (query: string) => {
  if (process.env.APP_ENV === "production") {
    return `https://app.unit-g.com/tickets/preview?id=${query}`;
  } else {
    return `https://app-stg.unit-g.com/tickets/preview?id=${query}`;
  }
};
export const shopPreviewPath = (query: string) => {
  if (process.env.APP_ENV === "production") {
    return `https://app.unit-g.com/shops/preview?id=${query}`;
  } else {
    return `https://app-stg.unit-g.com/shops/preview?id=${query}`;
  }
};

// サポート・利用規約など、その他
export const termsPath = "/";
export const privacyPolicyPath = "/";
export const serviceOrganizationPath = "/";
export const storeSupportPath = "/";
