import { css } from "@emotion/react";
import React, { MouseEvent, MouseEventHandler, useCallback } from "react";
import { BackgroundColor, FontColor, FontFamily } from "@unit/styles";
import { Button } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsSizeOverrides } from "@mui/material/Button/Button";

import { MdDeleteOutline } from "react-icons/md";

type Props = {
  label?: string;
  size?: OverridableStringUnion<"small" | "medium" | "large", ButtonPropsSizeOverrides>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const DeleteButton: React.FC<Props> = (props) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!props.onClick) {
        return;
      }
      props.onClick(event);
    },
    [props.onClick],
  );

  return (
    <Button
      fullWidth
      variant="contained"
      size={props.size}
      css={style.button}
      startIcon={<MdDeleteOutline />}
      onClick={handleClick}
    >
      {props.label || "削除する"}
    </Button>
  );
};
export default React.memo(DeleteButton);

const style = {
  button: css`
    font-family: ${FontFamily.notoSansJp};
    opacity: 0.95;
    color: ${FontColor.white};
    background: ${BackgroundColor.caution};
    &:hover {
      opacity: 1;
      color: ${FontColor.white};
      background: ${BackgroundColor.caution};
    }
    &.Mui-disabled {
      opacity: 0.4;
      background: ${BackgroundColor.caution};
    }
    & a:focus {
      color: ${FontColor.white};
    }
  `,
};
