import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class AdminMemberApi extends BaseApi {
  async getAdminProfile(): Promise<
    APIPaths["/admin-dash/admin/me"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/admin-dash/admin/me")).data;
  }
  async patchAdminProfile(
    requestBody: APIPaths["/admin-dash/admin/me"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/admin/me"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch("/admin-dash/admin/me", requestBody)).data;
  }
  async getAdminMemberList(
    parameters: APIPaths["/admin-dash/admin/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/admin/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/admin/list", {
        params: parameters,
      })
    ).data;
  }
  async registerAdminMember(
    requestBody: APIPaths["/admin-dash/admin/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/admin/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/admin/new", requestBody)).data;
  }
  async getAdminMemberById(
    id: APIPaths["/admin-dash/admin/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/admin/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/admin/${id}`)).data;
  }
  async patchAdminMemberById(
    id: APIPaths["/admin-dash/admin/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/admin/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/admin/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/admin/${id}`, requestBody)).data;
  }
  async deleteAdminMemberById(
    id: APIPaths["/admin-dash/admin/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/admin/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/admin/${id}`)).data;
  }
  async postChangePasswordByMe(
    id: APIPaths["/admin-dash/admin/{id}/change-password"]["post"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/admin/{id}/change-password"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/admin/{id}/change-password"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(`/admin-dash/admin/${id}/change-password`, requestBody)).data;
  }
}
