import { SVGProps, useMemo } from "react";

type Direction = "right" | "left";

export const SVGIconChevron = (props: SVGProps<SVGSVGElement>) => {
  const getRotateDeg = (direction: Direction) => {
    switch (direction) {
      case "right":
        return 180;
      case "left":
        return 0;
      default:
        return 0;
    }
  };

  const style = useMemo(
    () => ({ transform: `rotate(${getRotateDeg(props.direction as Direction)}deg)` }),
    [props.direction],
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      fill="none"
      viewBox="0 0 9 14"
      style={style}
      {...props}
    >
      <path
        fill="#fff"
        d="M7.627 14a.997.997 0 01-.707-.293L.918 7.705a1 1 0 010-1.415L6.92.29a1 1 0 011.415 1.414L3.04 6.998l5.295 5.294A1 1 0 017.627 14z"
      ></path>
    </svg>
  );
};
