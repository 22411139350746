import { css } from "@emotion/react";
import { Button } from "@mui/material";
import { appColor, FontFamily } from "@unit/styles";
import React, { ReactNode } from "react";

// TODO: 色定義をしっかり決める
type colorThemes = "primary" | "accentGray" | "grassBlue" | "accentGreen" | "accentRed" | "dodgerBlue" | "unset";

type Props = {
  color?: colorThemes;
  size: "small" | "medium" | "large";
  disabled?: boolean;
  startIcon?: ReactNode;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const AppRadiusButton: React.FC<Props> = (props) => {
  return (
    <Button
      css={customButtonStyled(props.color || "unset", props.size)}
      type="submit"
      fullWidth
      variant="contained"
      size={props.size}
      disabled={props.disabled}
      startIcon={props.startIcon}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
export default React.memo(AppRadiusButton);

const fontColor = {
  primary: appColor.Button.primary.text,
  accentGray: appColor.Button.primary.text,
  grassBlue: appColor.Button.primary.text,
  accentGreen: appColor.Button.primary.text,
  accentRed: appColor.Button.primary.text,
  dodgerBlue: appColor.Button.primary.text,
  unset: "unset",
};

const backgroundColor = {
  primary: appColor.Button.primary.color,
  accentGray: appColor.Button.accent.gray,
  grassBlue: appColor.Button.accent.grassBlue,
  accentGreen: appColor.Accent.green,
  accentRed: appColor.Accent.red,
  dodgerBlue: appColor.Accent.blue,
  unset: "unset",
};

const customButtonStyled = (color: colorThemes, size?: "small" | "medium" | "large") => css`
  font-family: ${FontFamily.kintoSans};
  color: ${fontColor[color]};
  text-transform: none;
  background: ${backgroundColor[color]};
  opacity: 0.95;
  font-style: normal;
  font-weight: ${size === "small" ? 400 : 700};
  font-size: ${size === "small" ? "12px" : "medium" ? "14px" : "16px"};
  line-height: 28px;
  letter-spacing: 0.05em;
  padding: ${size === "small" ? "12px 12px" : "10px 20px"};
  border-radius: 56px;
  &:hover {
    background: ${backgroundColor[color]};
    opacity: 0.8;
  }
  &.Mui-disabled {
    opacity: 0.4;
    color: ${fontColor[color]};
    background: ${backgroundColor[color]};
  }
  & a:focus {
    color: ${fontColor[color]};
  }
  span.MuiButton-iconSizeSmall {
    margin: 0 0 0 -4px;
  }
`;
