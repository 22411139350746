import React, { MouseEvent, MouseEventHandler, useCallback } from "react";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsSizeOverrides } from "@mui/material/Button/Button";
import { Button } from "@mui/material";

import { HiOutlineLockClosed } from "react-icons/hi";
import { css } from "@emotion/react";
import { FontFamily } from "@unit/styles";

type Props = {
  label?: string;
  size?: OverridableStringUnion<"small" | "medium" | "large", ButtonPropsSizeOverrides>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const StatusCloseButton: React.FC<Props> = (props) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!props.onClick) {
        return;
      }
      props.onClick(event);
    },
    [props.onClick],
  );

  return (
    <Button
      fullWidth
      variant="outlined"
      size={props.size}
      color="warning"
      startIcon={<HiOutlineLockClosed />}
      css={style.button}
      onClick={handleClick}
    >
      {props.label || "非公開にする"}
    </Button>
  );
};
export default React.memo(StatusCloseButton);

const style = {
  button: css`
    font-family: ${FontFamily.notoSansJp};
  `,
};
