import React, { MouseEventHandler, useCallback } from "react";
import { Avatar, Chip, css } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { useRouter } from "next/router";
import { storesPathById } from "@unit/utility";

type Props = {
  store: APICompsSchema["FlatStoreObject"];
};

const StoreAvatarChip: React.FC<Props> = (props) => {
  const router = useRouter();
  return (
    <Chip
      variant="outlined"
      label={props.store.name}
      css={style.chip}
      avatar={<Avatar src={props.store.imageUrls[0]} />}
      onClick={() => router.push(storesPathById(props.store.id))}
    />
  );
};
export default React.memo(StoreAvatarChip);

const style = {
  chip: css`
    height: 40px;
    border-radius: 20px;
    padding-left: 4px;
  `,
};
