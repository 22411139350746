import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";

type Props = {
  label: string;
  onClick: () => void;
};

const TextButton: React.FC<Props> = (props) => {
  return (
    <Grid css={style.textButton} onClick={props.onClick}>
      {props.label}
    </Grid>
  );
};
export default React.memo(TextButton);

const style = {
  textButton: css`
    font-family: ${FontFamily.notoSansJp};
    font-size: 16px;
    font-weight: ${FontWeight.bold};
    line-height: 24px;
    color: ${FontColor.basic};
    cursor: pointer;
    text-align: center;
    text-decoration-line: underline;

    &:hover {
      opacity: 0.5;
    }
  `,
};
