import { css } from "@emotion/react";
import { Box, MenuItem, Select, Stack } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { FontColor, FontFamily, FontWeight } from "@unit/styles";
import { prefectureJaList } from "admin-dash/src/data/prefectures";
import React from "react";

type Props = {
  title?: string;
  required?: boolean;
  value: APICompsSchema["PrefectureEnum"] | "none";
  error?: boolean;
  disabled?: boolean;
  onChange: (newPrefecture: APICompsSchema["PrefectureEnum"]) => void;
  onFocus?: () => void;
};

const SelectPrefecture: React.FC<Props> = (props) => {
  return (
    <Stack spacing={1}>
      {props.title ? (
        <Box css={style.title}>
          {props.title}
          {props.required ? <span>*</span> : null}
        </Box>
      ) : null}
      <Select
        fullWidth
        value={props.value}
        error={props.error}
        size="small"
        disabled={props.disabled}
        onChange={(e) => {
          if (e.target.value === "none") {
            return;
          }
          const prefecture = e.target.value as APICompsSchema["PrefectureEnum"];
          props.onChange(prefecture);
        }}
        onFocus={props.onFocus}
        css={props.value === "none" && style.noneText}
      >
        {props.value === "none" ? (
          <MenuItem value={"none"} css={style.noneText}>
            都道府県を選択してください
          </MenuItem>
        ) : null}
        {prefectureJaList.map((item, index) => (
          <MenuItem value={item} key={index}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
export default React.memo(SelectPrefecture);

const style = {
  title: css`
    font-family: ${FontFamily.notoSansJp};
    font-style: normal;
    font-weight: ${FontWeight.normal};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    span {
      font-size: 16px;
      font-style: normal;
      color: ${FontColor.error};
    }
  `,
  noneText: css`
    color: ${FontColor.sub};
  `,
};
