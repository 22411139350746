import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class AdminStoreApi extends BaseApi {
  async getStoreList(
    parameters: APIPaths["/admin-dash/store/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/store/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/store/list", {
        params: parameters,
      })
    ).data;
  }
  async registerStoreNew(
    requestBody: APIPaths["/admin-dash/store/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/store/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/store/new", requestBody)).data;
  }
  async getStoreById(
    id: APIPaths["/admin-dash/store/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/store/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/store/${id}`)).data;
  }
  async patchStoreById(
    id: APIPaths["/admin-dash/store/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/store/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/store/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/store/${id}`, requestBody)).data;
  }
  async deleteStoreById(
    id: APIPaths["/admin-dash/store/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/store/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/store/${id}`)).data;
  }
  // 認証系
  async putRequestStoreById(
    id: APIPaths["/admin-dash/store/{id}/request"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/store/{id}/request"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/store/${id}/request`)).data;
  }
  async putApproveStoreById(
    id: APIPaths["/admin-dash/store/{id}/request-approval"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/store/{id}/request-approval"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/store/${id}/request-approval`)).data;
  }
  async putRejectStoreById(
    id: APIPaths["/admin-dash/store/{id}/request-rejection"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/store/{id}/request-rejection"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/store/{id}/request-rejection"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/store/${id}/request-rejection`, requestBody)).data;
  }
  async putOpenStoreById(
    id: APIPaths["/admin-dash/store/{id}/open"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/store/{id}/open"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/store/${id}/open`)).data;
  }
  async putCloseStoreById(
    id: APIPaths["/admin-dash/store/{id}/close"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/store/{id}/close"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/store/${id}/close`)).data;
  }
}
