import { css } from "@emotion/react";
import { Divider, Grid } from "@mui/material";
import { BackgroundColor } from "@unit/styles";
import React from "react";

type Props = {
  children: React.ReactNode;
  previewNode: React.ReactNode;
};

const LivePreviewFormTemplate: React.FC<Props> = (props) => {
  return (
    <Grid container direction={"row"} px={{ xs: 2, lg: 5 }} css={style.pageContainer}>
      {/* 入力フォーム */}
      <Grid item xs py={2} px={1} mt={2} mb={2}>
        <Grid css={style.mainSection}>{props.children}</Grid>
      </Grid>

      <Divider orientation="vertical" flexItem css={style.displayNone} />

      {/* プレビュー */}
      <Grid item xs px={1} container justifyContent={"center"} css={style.displayNone}>
        {props.previewNode}
      </Grid>
    </Grid>
  );
};
export default React.memo(LivePreviewFormTemplate);

const style = {
  pageContainer: css`
    background: ${BackgroundColor.none};
  `,
  mainSection: css`
    margin: auto;
    max-width: 512px;
  `,
  displayNone: css`
    @media (max-width: 900px) {
      display: none;
    }
  `,
};
