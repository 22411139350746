import { APICompsSchema } from "@unit/apis";
import React from "react";

import StoreDetailPreviewPane from "../pane/store/StoreDetailPreviewPane";

import PreviewTemplate from "./PreviewTemplate";

type Props = {
  store: APICompsSchema["StoreObject"];
};

const StorePreview: React.FC<Props> = (props) => {
  return (
    <PreviewTemplate>
      <StoreDetailPreviewPane store={props.store} />
    </PreviewTemplate>
  );
};
export default React.memo(StorePreview);
