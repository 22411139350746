import * as React from "react";
import { SVGProps } from "react";

export const SVGRestaurantFood = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill={props.color}
      d="M3.34.5a1.669 1.669 0 00-1.612 1.241L.541 6.234a1.217 1.217 0 001.176 1.529h.51v2.3a1.39 1.39 0 002.78 0V2.167A1.67 1.67 0 003.34.5zm.856 9.563a.579.579 0 01-1.158 0V7.357a.406.406 0 00-.405-.406h-.916a.405.405 0 01-.392-.51L2.513 1.95a.856.856 0 011.683.219v7.895zM11.5.906v2.24a1.858 1.858 0 01-1.45 1.811v5.106a1.39 1.39 0 01-2.78 0V4.957a1.858 1.858 0 01-1.45-1.81V.906a.406.406 0 01.81 0v2.24a1.045 1.045 0 001.045 1.045.406.406 0 01.406.405v5.467a.579.579 0 001.158 0V4.612a.42.42 0 01.405-.421 1.045 1.045 0 001.044-1.044V.906a.406.406 0 11.812 0zM7.442 2.973V.906a.406.406 0 01.812 0v2.067a.406.406 0 01-.812 0zm1.623 0V.906a.406.406 0 01.812 0v2.067a.406.406 0 01-.812 0z"
    ></path>
  </svg>
);
