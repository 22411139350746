import { Button, css } from "@mui/material";
import { FontColor, FontFamily, FontWeight } from "@unit/styles";
import React, { MouseEvent, MouseEventHandler, useCallback } from "react";
import { AiOutlineLeft } from "react-icons/ai";

type Props = {
  label?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const BackButton: React.FC<Props> = (props) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!props.onClick) {
        return;
      }
      props.onClick(event);
    },
    [props.onClick],
  );

  return (
    <Button color="secondary" size="large" css={style.button} startIcon={<AiOutlineLeft />} onClick={handleClick}>
      {props.label || "戻る"}
    </Button>
  );
};
export default React.memo(BackButton);

const style = {
  button: css`
    font-family: ${FontFamily.notoSansJp};
    font-weight: ${FontWeight.medium};
    font-size: 16px;
    color: ${FontColor.basic};

    padding: 0;
  `,
};
