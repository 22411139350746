import React from "react";
import { useQueries } from "react-query";

import { useAreaApi } from "@/custom-hooks/apis/use-area-api";
import { useGenreApi } from "@/custom-hooks/apis/use-genre-api";
import { QueryKeys } from "@/global-state/react-query-keys";

type Props = {
  children: React.ReactNode;
};
const GlobalJotaiProvider: React.FC<Props> = (props) => {
  const { getAreaList } = useAreaApi();
  const { getGenreList } = useGenreApi();
  useQueries([
    {
      queryKey: QueryKeys.getGenreList,
      queryFn: () => getGenreList(),
    },
    {
      queryKey: QueryKeys.getAreaList,
      queryFn: () => getAreaList(),
    },
  ]);

  return <>{props.children}</>;
};

export default GlobalJotaiProvider;
