import { css } from "@emotion/react";

import ColorPalette from "./color-palette";

export const BorderColor = {
  basic: ColorPalette.tuatara,
  white: ColorPalette.white,
  pale: ColorPalette.iron,
  subtle: ColorPalette.mercury,
  wildSand: ColorPalette.wildSand,
  alto4: ColorPalette.alto4,
  dodgerBlue: ColorPalette.dodgerBlue,
  appError: ColorPalette.punch,
} as const;

export const BorderStyle = {
  none: css`
    border: none;
  `,
  solidBasic: css`
    border: 1px solid ${BorderColor.basic};
  `,
  solidSubtle: css`
    border: 1px solid ${BorderColor.subtle};
  `,

  /** 区切り線 */
  // paleDivider: css`
  //   border-bottom: 1px solid ${BorderColor.pale};
  // `,
  subtleDivider: css`
    border-bottom: 1px solid ${BorderColor.subtle};
  `,
  alto4Divider: css`
    border-bottom: 1px solid ${BorderColor.alto4};
  `,
} as const;
