import React from "react";
import { SVGProps } from "react";

export const SVGImgInitialTitle: React.FC = (props: SVGProps<SVGSVGElement>) => (
  <svg width={158} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M36 31H0v9h36v-9ZM22.5 26.5h9V4h-27v9h18v13.5ZM77.5 4h-27v9h27V4ZM82 31H46v9h36v-9ZM109 33H93.572v7H116V12h-7v21Z"
      fill="#2B2A27"
    />
    <path d="M93.6 12H88v9.8h5.6V12Z" fill="#32D3EB" />
    <path d="M102 12h-5.6v9.8h5.6V12Z" fill="#3AB7FF" />
    <path d="M138.04 0h-10v8.01h10V0ZM158 12.5h-30v10h.04V40h10V22.5H158v-10Z" fill="#2B2A27" />
  </svg>
);

export const SVGImgInitialTitleSmall: React.FC = (props: SVGProps<SVGSVGElement>) => (
  <svg width={100} height={26} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M22.785 19.62H0v5.697h22.785V19.62ZM14.24 16.772h5.697V2.532H2.848v5.696h11.393v8.544ZM49.05 2.532H31.962v5.696H49.05V2.532ZM51.899 19.62H29.114v5.697h22.785V19.62ZM68.987 20.886h-9.764v4.43H73.417V7.596h-4.43v13.291Z"
        fill="#2B2A27"
      />
      <path d="M59.24 7.595h-3.544v6.202h3.545V7.595Z" fill="#32D3EB" />
      <path d="M64.557 7.595h-3.544v6.202h3.544V7.595Z" fill="#3AB7FF" />
      <path d="M87.367 0h-6.329v5.07h6.33V0ZM100 7.911H81.013v6.33h.025v11.075h6.33V14.241H100V7.91Z" fill="#2B2A27" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h100v25.317H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const SVGImgInitialTitleStore: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="158" height="63" fill="none" viewBox="0 0 158 63">
    <path
      fill="#2B2A27"
      d="M36 31H0v9h36v-9zM22.5 26.5h9V4h-27v9h18v13.5zM77.5 4h-27v9h27V4zM82 31H46v9h36v-9zM109 33H93.572v7H116V12h-7v21z"
    ></path>
    <path fill="#32D3EB" d="M93.6 12H88v9.8h5.6V12zM102 12h-5.6v9.8h5.6V12z"></path>
    <path fill="#2B2A27" d="M138.04 0h-10v8.01h10V0zM158 12.5h-30v10h.04V40h10V22.5H158v-10z"></path>
    <path
      fill="#32D3EB"
      d="M107.604 62.224c2.736 0 4.353-1.648 4.353-3.584 0-1.712-.945-2.624-2.385-3.216l-1.552-.656c-1.008-.4-1.856-.704-1.856-1.552 0-.784.672-1.248 1.712-1.248.976 0 1.76.352 2.512.976l1.201-1.488c-.961-.976-2.337-1.52-3.713-1.52-2.4 0-4.096 1.488-4.096 3.424 0 1.728 1.184 2.688 2.4 3.184l1.584.672c1.056.448 1.776.72 1.776 1.6 0 .832-.656 1.36-1.888 1.36-1.04 0-2.16-.528-3.008-1.312l-1.36 1.632a6.31 6.31 0 004.32 1.728zm5.898-10.096h3.328V62h2.368v-9.872h3.36V50.16h-9.056v1.968zm15.995 10.096c3.137 0 5.281-2.352 5.281-6.192 0-3.84-2.144-6.096-5.281-6.096-3.136 0-5.296 2.24-5.296 6.096 0 3.84 2.16 6.192 5.296 6.192zm0-2.048c-1.76 0-2.88-1.616-2.88-4.144 0-2.544 1.12-4.064 2.88-4.064 1.761 0 2.88 1.52 2.88 4.064 0 2.528-1.119 4.144-2.88 4.144zm8.409-10.016V62h2.352v-4.48h1.664l2.432 4.48h2.64l-2.768-4.848c1.36-.528 2.256-1.632 2.256-3.408 0-2.72-1.968-3.584-4.448-3.584h-4.128zm2.352 5.488v-3.616h1.552c1.52 0 2.352.432 2.352 1.712 0 1.264-.832 1.904-2.352 1.904h-1.552zm9.354-5.488V62h7.44v-1.984h-5.088v-3.168h4.176v-1.984h-4.176v-2.736h4.912V50.16h-7.264z"
    ></path>
  </svg>
);

export const SVGImgInitialTitleStoreSmall: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" fill="none" viewBox="0 0 158 63">
    <path
      fill="#2B2A27"
      d="M36 31H0v9h36v-9zM22.5 26.5h9V4h-27v9h18v13.5zM77.5 4h-27v9h27V4zM82 31H46v9h36v-9zM109 33H93.572v7H116V12h-7v21z"
    ></path>
    <path fill="#32D3EB" d="M93.6 12H88v9.8h5.6V12zM102 12h-5.6v9.8h5.6V12z"></path>
    <path fill="#2B2A27" d="M138.04 0h-10v8.01h10V0zM158 12.5h-30v10h.04V40h10V22.5H158v-10z"></path>
    <path
      fill="#32D3EB"
      d="M107.604 62.224c2.736 0 4.353-1.648 4.353-3.584 0-1.712-.945-2.624-2.385-3.216l-1.552-.656c-1.008-.4-1.856-.704-1.856-1.552 0-.784.672-1.248 1.712-1.248.976 0 1.76.352 2.512.976l1.201-1.488c-.961-.976-2.337-1.52-3.713-1.52-2.4 0-4.096 1.488-4.096 3.424 0 1.728 1.184 2.688 2.4 3.184l1.584.672c1.056.448 1.776.72 1.776 1.6 0 .832-.656 1.36-1.888 1.36-1.04 0-2.16-.528-3.008-1.312l-1.36 1.632a6.31 6.31 0 004.32 1.728zm5.898-10.096h3.328V62h2.368v-9.872h3.36V50.16h-9.056v1.968zm15.995 10.096c3.137 0 5.281-2.352 5.281-6.192 0-3.84-2.144-6.096-5.281-6.096-3.136 0-5.296 2.24-5.296 6.096 0 3.84 2.16 6.192 5.296 6.192zm0-2.048c-1.76 0-2.88-1.616-2.88-4.144 0-2.544 1.12-4.064 2.88-4.064 1.761 0 2.88 1.52 2.88 4.064 0 2.528-1.119 4.144-2.88 4.144zm8.409-10.016V62h2.352v-4.48h1.664l2.432 4.48h2.64l-2.768-4.848c1.36-.528 2.256-1.632 2.256-3.408 0-2.72-1.968-3.584-4.448-3.584h-4.128zm2.352 5.488v-3.616h1.552c1.52 0 2.352.432 2.352 1.712 0 1.264-.832 1.904-2.352 1.904h-1.552zm9.354-5.488V62h7.44v-1.984h-5.088v-3.168h4.176v-1.984h-4.176v-2.736h4.912V50.16h-7.264z"
    ></path>
  </svg>
);
