import { css } from "@emotion/react";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";

type Props = {
  brandList: APICompsSchema["FlatBrandObject"][];
  selectedBrand: APICompsSchema["FlatBrandObject"];
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  onChangeBrand: (newBrandObject: APICompsSchema["FlatBrandObject"]) => void;
  onFocus?: React.FocusEventHandler;
};

const SelectBrand: React.FC<Props> = (props) => {
  const { brandList, selectedBrand, onChangeBrand, onFocus } = props;
  const handleChangeBrand = (_: React.SyntheticEvent, newBrandObject: APICompsSchema["FlatBrandObject"] | null) => {
    if (!newBrandObject) {
      return;
    }
    onChangeBrand(newBrandObject);
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      value={selectedBrand}
      options={brandList}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option: APICompsSchema["FlatBrandObject"]) => option.name}
      onChange={handleChangeBrand}
      onFocus={onFocus}
      renderInput={(params) => (
        <Stack spacing={1}>
          <Box css={style.title}>
            所属ブランド
            {props.required ? <span>*</span> : null}
          </Box>
          <TextField
            {...params}
            css={style.textField}
            error={!selectedBrand.id}
            label="ブランドを選択"
            variant="outlined"
            size="small"
            helperText={props.helperText}
          />
        </Stack>
      )}
    />
  );
};
export default React.memo(SelectBrand);

const style = {
  title: css`
    font-family: ${FontFamily.notoSansJp};
    font-style: normal;
    font-weight: ${FontWeight.normal};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    span {
      font-size: 16px;
      color: ${FontColor.error};
    }
  `,
  textField: css`
    .MuiFormHelperText-contained {
      margin: 4px 0 0;
      font-family: ${FontFamily.notoSansJp};
      color: ${FontColor.sub};
      font-size: 12px;
      letter-spacing: 0.1px;
    }
  `,
};
