import { css } from "@emotion/react";
import { Button, SxProps } from "@mui/material";
import { appColor, appTypo } from "@unit/styles";
import React, { MouseEventHandler } from "react";

type Props = {
  id?: string;
  label: string;
  isSelected?: boolean;
  sx?: SxProps;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const AreaChip: React.FC<Props> = (props) => {
  return (
    <Button
      id={props.id}
      variant="outlined"
      css={ChipStyled(props.isSelected)}
      value={props.label}
      sx={props.sx}
      onClick={props.onClick}
    >
      {props.label}
    </Button>
  );
};
export default AreaChip;

const ChipStyled = (isSelected: boolean | undefined) => css`
  cursor: pointer;
  font-family: ${appTypo.font.notoJp};
  color: ${isSelected ? appColor.App.primary : appColor.Text.secondary};
  text-transform: none;
  background: ${appColor.App.white};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  padding: 8px 12px;
  height: 36px;
  border: ${isSelected ? "2px" : "1px"} solid;
  border-radius: 18px;
  &:hover {
    border: ${isSelected ? "2px" : "1px"} solid;
  }
  flex-shrink: 0;
`;
