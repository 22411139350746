import { css } from "@emotion/react";
import { appTypo } from "./app-typography";
import { appColor } from "./app-colors";

export const globalStyled = css`
  html {
    font-family: "Roboto", "Noto Sans JP", "Avenir", "Helvetica Neue", "Helvetica", "Arial", "ヒラギノ角ゴ Pro W3",
      "Hiragino Kaku Gothic Pro", "メイリオ", sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
    background-color: #f8fafb;
    font-style: normal;
    font-weight: normal;
    min-height: 100vh;
    word-break: break-all;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: inherit;
    }
  }

  .firebase-emulator-warning {
    display: none;
  }

  & .MuiDialogContent-root {
    padding: 30px 30px !important;
    min-width: 30vw;
    max-width: 70vw;
  }

  // rsuiteの期間指定のUI
  & .rs-picker-menu {
    z-index: 2022 !important;
  }
  & .rs-picker-toggle {
    font-size: 16px !important;
  }
  & .rs-btn-primary {
    color: #ffffff !important;
    background: #32d3eb !important;
    &:hover {
      color: #ffffff !important;
      background: rgba(50, 211, 235, 0.5) !important;
    }
  }
  & .rs-btn-link {
    color: #2b2a27 !important;
    &:hover {
      color: #32d3eb !important;
    }
    &:focus {
      color: #32d3eb !important;
    }
  }
  & .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #32d3eb !important;
  }
  & .rs-calendar-table-cell-in-range:before {
    background: rgba(50, 211, 235, 0.5) !important;
  }
  & .rs-btn-primary:disabled {
    background-color: #32d3eb !important;
  }
  & .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px #32d3eb !important;
  }
  & .rs-calendar-table-cell {
    &:hover {
      .rs-calendar-table-cell-content {
        color: #32d3eb !important;
        background: rgba(50, 211, 235, 0.5) !important;
      }
    }
  }
  & .rs-calendar-table-cell-selected-end {
    & .rs-calendar-table-cell-content {
      color: #ffffff !important;
      background: #32d3eb !important;
    }
  }
  & .rs-calendar-table-cell-selected-start {
    & .rs-calendar-table-cell-content {
      color: #ffffff !important;
      background: #32d3eb !important;
    }
  }

  //  react-toastify-css
  .Toastify__toast-container {
    .Toastify__toast {
      min-height: 48px;
    }
    .Toastify__toast-body {
      font-family: ${appTypo.font.kintoSans};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: ${appColor.Text.white};
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--default {
    background: ${appColor.Text.secondary};
  }
  .Toastify__progress-bar {
    background: ${appColor.App.white};
  }
  .Toastify__close-button > svg {
    fill: ${appColor.App.white};
  }
`;
