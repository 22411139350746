import { APICompsSchema } from "../api-config";

export const flatAddressObject = (): APICompsSchema["FlatAddressObject"] => {
  return {
    id: "",
    postCode: "",
    countryCode: "",
    prefecture: "北海道",
    city: "",
    street: "",
    building: null,
    mapUrl: null,
    latLng: null,
    createdAt: 0,
    updatedAt: 0,
  };
};
