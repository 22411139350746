import { SVGProps } from "react";

export const SvgIconLinkBox = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14" {...props}>
    <path
      fill={props.fill || "#2B2A27"}
      d="M12.25 7.874a.875.875 0 00-.875.875v2.625a.876.876 0 01-.875.875H2.625a.875.875 0 01-.875-.875V3.5a.876.876 0 01.875-.875H5.25a.875.875 0 000-1.75H2.625A2.628 2.628 0 000 3.5v7.875A2.628 2.628 0 002.625 14H10.5a2.628 2.628 0 002.625-2.625V8.75a.875.875 0 00-.875-.875z"
    ></path>
    <path
      fill={props.fill || "#2B2A27"}
      d="M13.125 0h-3.5a.875.875 0 000 1.75h1.388L5.506 7.256a.875.875 0 101.238 1.238l5.506-5.507v1.388a.875.875 0 001.75 0v-3.5A.875.875 0 0013.125 0z"
    ></path>
  </svg>
);
