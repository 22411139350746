import { css } from "@emotion/react";
import { Button } from "@mui/material";
import { appColor, FontFamily, FontWeight } from "@unit/styles";
import { storeManualPath } from "@unit/utility";
import React from "react";

const ManualButton: React.FC = () => {
  return (
    <Button
      css={[style.actionButton, style.outlinedButton]}
      size={"medium"}
      variant={"outlined"}
      href={storeManualPath}
      target={"_blank"}
    >
      マニュアル
    </Button>
  );
};
export default React.memo(ManualButton);

const style = {
  actionButton: css`
    font-family: ${FontFamily.notoSansJp};
    font-size: 16px;
    font-weight: ${FontWeight.bold};
    height: 38px;
  `,
  outlinedButton: css`
    background-color: ${appColor.Background.primary};
    border: 1px solid ${appColor.Button.accent.grassBlue};
    color: ${appColor.Button.accent.grassBlue};
    &:hover {
      background-color: ${appColor.Button.negative.color};
      border: 1px solid ${appColor.Button.accent.grassBlue};
      color: ${appColor.Button.accent.grassBlue};
      opacity: 0.8;
    }
  `,
};
