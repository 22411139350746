import { SVGProps } from "react";

export const SVGIconChainBall = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40" {...props}>
    <rect width="40" height="40" fill="#93918F" rx="20"></rect>
    <path
      fill="#fff"
      d="M27.024 24.951h-4.683a4.982 4.982 0 01-4.975-4.975v-.586a.878.878 0 011.756 0v.586a3.224 3.224 0 003.22 3.22h4.682a3.22 3.22 0 000-6.44h-4.097a.878.878 0 010-1.756h4.097a4.976 4.976 0 110 9.951zm-9.073-.878a.878.878 0 00-.878-.878h-4.097a3.22 3.22 0 010-6.439h4.683a3.223 3.223 0 013.22 3.22v.585a.878.878 0 001.755 0v-.585A4.982 4.982 0 0017.658 15h-4.682a4.976 4.976 0 100 9.951h4.097a.878.878 0 00.878-.878z"
    ></path>
  </svg>
);
