import * as React from "react";
import { SVGProps } from "react";

export const SVGIconChat = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.941 22.178a.707.707 0 0 1-.706-.704v-2.169A4.95 4.95 0 0 1 0 14.415v-2.823A4.941 4.941 0 0 1 4.941 6.65h2.824a.706.706 0 0 1 .706.706v1.412A3.53 3.53 0 0 0 12 12.298h2.823c.14 0 .276.04.392.118l1.411.943a.706.706 0 0 1 .315.587v.468A4.94 4.94 0 0 1 12 19.354H9.391L5.337 22.06a.706.706 0 0 1-.396.118Zm0-14.117a3.53 3.53 0 0 0-3.53 3.53v2.823a3.53 3.53 0 0 0 3.53 3.53.706.706 0 0 1 .706.705v1.505l3.137-2.092a.706.706 0 0 1 .392-.119H12a3.53 3.53 0 0 0 3.53-3.53v-.091l-.922-.614H12a4.94 4.94 0 0 1-4.941-4.941V8.06H4.94Z"
      fill="#000"
    />
    <path
      d="M19.059 16.53a.705.705 0 0 1-.391-.118l-4.058-2.706H12a4.941 4.941 0 0 1-4.941-4.941V5.94A4.941 4.941 0 0 1 12 1h7.059A4.941 4.941 0 0 1 24 5.941v2.824a4.95 4.95 0 0 1-4.235 4.89v2.17a.706.706 0 0 1-.706.706ZM12 2.414a3.53 3.53 0 0 0-3.53 3.53v2.823a3.53 3.53 0 0 0 3.53 3.53h2.823c.14 0 .276.04.392.118l3.138 2.092v-1.505a.706.706 0 0 1 .706-.705 3.53 3.53 0 0 0 3.53-3.53V5.943a3.53 3.53 0 0 0-3.53-3.53H12Z"
      fill="#000"
    />
  </svg>
);
