import { Box, Chip, css } from "@mui/material";
import { BorderStyle, FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";

import { SVGMapPin } from "../../../common/svg-icon/IconMapPin";

const AreaFilterOpener: React.FC = () => {
  return (
    <Chip
      css={style.chip}
      label={
        <Box sx={{ display: "flex", gap: "8px", flexShrink: 1 }} css={style.label}>
          <span className="icon">
            <SVGMapPin color={FontColor.basic} width={16} height={16} />
          </span>
          <span className="ellipsis">これはプレビュー表示です</span>
        </Box>
      }
    />
  );
};
export default React.memo(AreaFilterOpener);

const style = {
  chip: css`
    transition: 0.2s ease-out;
    min-width: 100%;
    max-width: 0 !important; // Note: 値の上書きをしないとテキスト溢れの3点リーダーが機能しない

    font-family: ${FontFamily.notoSansJp};
    font-style: normal;
    font-weight: ${FontWeight.normal};
    font-size: 11px;
    color: ${FontColor.basic};
    background: #ebebeb;
    height: 40px;
    padding: 12px;
    border-radius: 20px;
    ${BorderStyle.none};
    :hover {
      opacity: 0.85;
      background: #ebebeb;
    }
    .MuiChip-label {
      padding: 0;
      width: 100%;
    }
    .MuiChip-deleteIcon {
      margin: 0;
    }
  `,
  label: css`
    span.icon {
      width: 16px;
      height: 16px;
    }
    span.ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
};
