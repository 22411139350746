import { css } from "@emotion/react";
import { FontColor, FontFamily } from "@unit/styles";
import React, { useState } from "react";
import Slider, { Settings } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {
  settings?: Settings;
  totalSlides: number;
  children: React.ReactNode;
};

const SliderProvider: React.FC<Props> = (props) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const defaultSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    arrows: false,
    beforeChange: (_currentSlide: number, nextSlide: number) => setCurrentSlide(nextSlide),
  };
  const settings = { ...defaultSettings, ...(props.settings || {}) };

  return (
    <div css={style.container}>
      <Slider {...settings} css={style.slider}>
        {props.children}
      </Slider>
      {props.totalSlides > 0 && (
        <div css={style.page}>
          <div css={style.icon}>
            {[...Array(4)].map((_, index) => (
              <div key={index} css={style.tile} />
            ))}
          </div>
          {`${currentSlide + 1} ︱ ${props.totalSlides}`}
        </div>
      )}
    </div>
  );
};

export default React.memo(SliderProvider);

const style = {
  container: css`
    width: 100%;
    position: relative;
  `,

  slider: css`
    width: 100%;
  `,

  page: css`
    position: absolute;
    bottom: 12px;
    right: 12.2px;
    border-radius: 999px;
    background: #797979;
    display: inline-flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    color: ${FontColor.white};
    font-family: ${FontFamily.kintoSans};
    font-size: 11px;
  `,

  icon: css`
    display: grid;
    grid-template-columns: repeat(2, 4px);
    grid-template-rows: repeat(2, 4px);
    gap: 2px;
    margin-right: 8px;
  `,

  tile: css`
    width: 4px;
    height: 4px;
    background-color: white;
  `,
};
