import { css, Divider, Grid } from "@mui/material";
import { APICompsSchema, defaultFlatSnsAccountObject, SNSAccountTypeEnumObject } from "@unit/apis";
import { appColor, FontColor, FontFamily, FontWeight } from "@unit/styles";
import { UUID } from "@unit/utility";
import React, { useState } from "react";

import RadiusAddButton from "../../../common/buttton/RadiusAddButton";
import PrimaryTextField from "../../../common/form-item/PrimaryTextField";

import SnsCheckbox from "./SnsCheckbox";

type Props = {
  snsAccounts: APICompsSchema["SNSAccountObject"][];
  onChangeSnsAccounts: (newSnsAccounts: APICompsSchema["FlatSNSAccountObject"][]) => void;
  onFocus?: () => void;
};

// TODO: 仕様再協議後に実装する。
const SnsUrlCheckForm: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string>("");
  const [selectedTypes, setSelectedTypes] = useState<APICompsSchema["SNSAccountTypeEnum"][]>([]);
  const snsAccountTypeEnumArray = Object.keys(SNSAccountTypeEnumObject) as APICompsSchema["SNSAccountTypeEnum"][];

  const handleAddUrl = () => {
    const newSnsAccounts = selectedTypes.map((type) => {
      return { ...defaultFlatSnsAccountObject, id: UUID.randomUUID(), type } as APICompsSchema["FlatSNSAccountObject"];
    });
    props.onChangeSnsAccounts(newSnsAccounts);
  };

  return (
    <>
      <Grid display="flex" justifyContent="space-between" alignItems="end" gap={1}>
        <Grid width={"100%"}>
          <PrimaryTextField
            fieldType="string"
            title="URL"
            placeholder="https://example.com"
            value={value}
            onChange={(newValue) => setValue(newValue)}
            // onFocus={() => scrollBy("focus-scroll-store-reservationUrl")}
          />
        </Grid>
        <Grid width={"80px"}>
          <RadiusAddButton disabled={!value} onClick={handleAddUrl} />
        </Grid>
      </Grid>

      <Grid py={2}>
        <Divider orientation="horizontal" />
      </Grid>

      <Grid container alignItems="start" spacing={2}>
        {snsAccountTypeEnumArray
          .filter((snsAccountType) => !props.snsAccounts.map((snsAccount) => snsAccount.type).includes(snsAccountType))
          .map((snsAccountType) => {
            return (
              <Grid item xs={4} key={snsAccountType}>
                <SnsCheckbox
                  snsAccountType={snsAccountType}
                  checked={selectedTypes.includes(snsAccountType)}
                  onClick={() => {
                    const newSnsAccountTypes = selectedTypes.includes(snsAccountType)
                      ? selectedTypes.filter((type) => type !== snsAccountType)
                      : [...selectedTypes, snsAccountType];
                    setSelectedTypes(newSnsAccountTypes);
                  }}
                />
              </Grid>
            );
          })}
      </Grid>

      <RadiusAddButton disabled={!selectedTypes.length} onClick={handleAddUrl} />

      <Grid py={2}>
        <Divider orientation="horizontal" />
      </Grid>
    </>
  );
};
export default React.memo(SnsUrlCheckForm);

const style = {
  snsCheckbox: css`
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid ${appColor.Border.card};
    user-select: none;
  `,
  iconLabel: css`
    text-align: center;
    font-family: ${FontFamily.notoSansJp};
    color: ${FontColor.basic};
    font-style: normal;
    font-size: 12px;
    font-weight: ${FontWeight.normal};
    line-height: 150%;
    letter-spacing: 0.1px;
  `,
};
