import * as React from "react";
import { SVGProps } from "react";

export const SVGIconFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <path
      fill="#2B2A27"
      d="M4.711 8.802A2.444 2.444 0 003.063 7.43V1.265a.598.598 0 00-1.196 0v6.162a2.464 2.464 0 000 4.78v2.218a.598.598 0 101.196 0V12.21a2.445 2.445 0 001.71-1.52 2.443 2.443 0 00-.062-1.887zm-1.056 1.466a1.271 1.271 0 11-.033-.972 1.26 1.26 0 01.04.972h-.007zM15.777 8.802A2.443 2.443 0 0014.13 7.43V1.265a.598.598 0 10-1.196 0v6.162a2.465 2.465 0 000 4.78v2.218a.598.598 0 101.196 0V12.21a2.445 2.445 0 001.711-1.52 2.442 2.442 0 00-.063-1.887zm-1.055 1.466a1.271 1.271 0 11-2.38-.897 1.271 1.271 0 012.38.897zM8.869 3.658c-.09-.033-.18-.06-.273-.084V1.265a.598.598 0 00-1.196 0v2.318a2.47 2.47 0 00-1.09 4.194 2.453 2.453 0 001.09.592v6.056a.598.598 0 101.196 0v-6.06a2.465 2.465 0 00.272-4.708zm.329 2.767a1.271 1.271 0 11-.742-1.64 1.262 1.262 0 01.7.668 1.26 1.26 0 01.042.972z"
    ></path>
  </svg>
);
