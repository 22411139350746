import { css } from "@emotion/react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Grid } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BackgroundColor, BorderColor, FontColor, FontFamily, FontWeight } from "@unit/styles";
import React, { useState } from "react";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  () => ({
    "&:before": {
      display: "none",
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "22px" }} css={{ transform: "rotate(270deg)" }} />}
    {...props}
  />
))(() => ({
  backgroundColor: BackgroundColor.lightBasic,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTop: `1px solid ${BorderColor.subtle}`,
}));

type Props = {
  label: string;
  children: React.ReactNode;
};

const BasicAccordion: React.FC<Props> = (props) => {
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Grid css={rootStyled}>
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography css={typography}>{props.label}</Typography>
        </AccordionSummary>
        <AccordionDetails css={accordionDetailStyled}>{props.children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default React.memo(BasicAccordion);

const rootStyled = css`
  border-top: 1px solid ${BorderColor.subtle};
  border-bottom: 1px solid ${BorderColor.subtle};
`;

const typography = css`
  font-family: ${FontFamily.kintoSans};
  font-style: normal;
  font-weight: ${FontWeight.bold};
  color: ${FontColor.basic};
  font-size: 16px;
  line-height: 22.4px;
  letter-spacing: 0.1em;
`;

const accordionDetailStyled = css`
  padding: 0px;
`;
