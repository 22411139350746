import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { appColor } from "@unit/styles";
import React, { MouseEventHandler } from "react";

import AreaChip from "@/components/common/area/AreaChip";
import { LocationType } from "@/data/sampleSelectType";

type Props = {
  caption: string;
  selectedRegion: string;
  onClick?: MouseEventHandler;
};

const RegionSelector: React.FC<Props> = (props) => {
  return (
    <Grid item xs={12} px={2} css={containerStyled}>
      <fieldset>
        <legend>
          <span>{props.caption}</span>
        </legend>
        <Grid container direction={"row"} item>
          {LocationType.map((location, index) => (
            <AreaChip
              sx={{ margin: "4px" }}
              label={location.region}
              key={index}
              isSelected={location.region === props.selectedRegion}
              onClick={props.onClick}
            />
          ))}
        </Grid>
      </fieldset>
    </Grid>
  );
};
export default RegionSelector;

const containerStyled = css`
  fieldset {
    padding: 4px 8px;
    border: ${appColor.Text.gray} 1px solid;
    border-radius: 4px;
    legend {
      height: 16px;
    }
    legend > span {
      font-size: 11px;
      color: ${appColor.Text.primary};
    }
  }
`;
