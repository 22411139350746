import "react";

import { css } from "@emotion/react";
import { Avatar, Chip, Grid, Tooltip } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import {
  APICompsSchema,
  BrandStatusEnumToValue,
  DiscountTypeEnumToValue,
  GenderEnumToValue,
  InfluencerLabelEnumToValue,
  InfluencerStatusEnumToValue,
  InfluencerTicketOfferLabelEnumToValue,
  InfluencerTicketStatusEnumToValue,
  OrganizationStatusEnumToValue,
  OrganizationTypeEnumToValue,
  PRMethodTypeEnumToValue,
  StaffRoleEnumToValue,
  StoreStatusEnumToValue,
  StoreTypeEnumToValue,
  TicketStatusEnumToValue,
  TicketTypeEnumToValue,
} from "@unit/apis";
import { newLineText } from "@unit/component";
import CustomHeadLabel from "@unit/component/src/mui-data-table/CustomHeadLabel";
import { appColor, appTypo, FontFamily, FontWeight, themeOptions } from "@unit/styles";
import { SVGIconTwitter } from "@unit/svgs";
import cliTruncate from "cli-truncate";
import {
  MUIDataTableOptions,
  MUIDataTableColumnOptions,
  CustomHeadLabelRenderOptions,
  MUISortOptions,
} from "mui-datatables";
import Image from "next/image";
import NextLink from "next/link";
import React from "react";
import { AiOutlineDollarCircle, AiOutlinePercentage } from "react-icons/ai";
import { FaGoogle, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { GiJapan } from "react-icons/gi";
import { TbWorld } from "react-icons/tb";

import { float, int, str } from "./common-utils";
import { selectedCountryCodeList } from "./country-code";
import { formatSlashDate, formatSlashTime } from "./time-utils";

export class MuiDatatableUtils {
  static options = {
    serverSide: false,
    rowsPerPageOptions: [5, 10, 25, 50, 100, 200],
    search: false,
    searchOpen: false,
    searchPlaceholder: "キーワード検索",
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    tableBodyHeight: "100%",
    tableBodyMaxHeight: "calc(100% - 116px)",
    filterType: "multiselect", // 'checkbox', 'dropdown', 'multiselect', 'smallTextField'
    responsive: "standard",
    selectableRows: "none", // "multiple", "single", "none"
    fixedHeader: true,
    fixedSelectColumn: true,
    textLabels: {
      body: {
        noMatch: (
          <Grid
            p={5}
            css={css`
              height: 100%;
            `}
          >
            データが見つかりませんでした
          </Grid>
        ),
        columnHeaderTooltip: (column) => `${column.label}の並び替え`,
      },
      pagination: {
        next: "次へ",
        previous: "前へ",
        rowsPerPage: "ページあたり",
        displayRows: "of",
      },
      toolbar: {
        search: "検索する",
        downloadCsv: "CSVファイルをダウンロードする",
        print: "プリントする",
        filterTable: "フィルター条件を指定する",
        viewColumns: "表示項目をカスタマイズする",
      },
      selectedRows: {
        delete: "削除する",
        deleteAria: "削除する",
        text: "件選択中",
      },
      viewColumns: {
        title: "表示項目を選択",
      },
      filter: {
        all: "全て",
        title: "絞り込む",
        reset: "リセットする",
      },
    },
  } as MUIDataTableOptions;

  static tableTheme = createTheme({
    ...themeOptions,
    components: {
      // @ts-ignore
      MUIDataTable: {
        styleOverrides: {
          root: {
            height: "100%",
          },
          responsiveScroll: {
            maxHeight: "none",
            overflowX: "auto",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {},
        },
      },
      // handles data-table data header color
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            color: appColor.Text.secondary, // text-secondary
            padding: "8px 2px 8px 16px",
            fontFamily: appTypo.font.notoJp,
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.17px",
          },
          sortAction: {
            color: appColor.Text.secondary, // text-secondary
          },
          sortActive: {
            color: appColor.Text.secondary, // text-dark
          },
          hintIconAlone: {
            marginTop: "0",
            marginLeft: "0",
            fill: appColor.Text.secondary,
          },
          toolButton: {
            marginRight: "0",
          },
        },
      },
      MUIDataTableHeadRow: {
        styleOverrides: {
          root: { whiteSpace: "nowrap" },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingLeft: "8px",
            cursor: "pointer",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {},
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          titleText: {
            fontFamily: appTypo.font.notoJp,
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#1F3730",
          },
        },
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            padding: "14px 0",
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          root: {
            backgroundColor: appColor.App.white,
          },
        },
      },
    },
  });

  static get hideOpts(): MUIDataTableColumnOptions {
    return {
      display: false,
      sort: false,
      filter: false,
      viewColumns: false,
    };
  }

  static get searchOnlyOpts(): MUIDataTableColumnOptions {
    return {
      display: false,
      sort: false,
      filter: false,
      viewColumns: false,
      searchable: true,
    };
  }

  static get noSortOpts(): MUIDataTableColumnOptions {
    return { display: true, sort: false, filter: false, viewColumns: false };
  }

  static get sortOnlyOpts(): MUIDataTableColumnOptions {
    return {
      display: true,
      filter: false,
      sort: true,
      sortDescFirst: true,
      sortThirdClickReset: true,
    };
  }

  static get emptyOpts(): MUIDataTableColumnOptions {
    return {
      display: true,
      empty: true,
      sort: false,
      filter: false,
      viewColumns: false,
    };
  }

  static intOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: number) => int(v).toLocaleString(),
    };
  }

  static get noSortIntOpts(): MUIDataTableColumnOptions {
    return { ...this.noSortOpts, customBodyRender: (v: number) => int(v).toLocaleString() };
  }

  static floatOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: number) => float(v).toLocaleString(),
    };
  }

  static strOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: string) => {
        return (
          <Grid container css={customWidth(150)} direction="column" justifyContent="center" alignItems="flex-start">
            <Grid item css={style.caption}>
              {newLineText(cliTruncate(str(v) || "-", 30))}
            </Grid>
          </Grid>
        );
      },
    };
  }

  static get noSortStrOpts(): MUIDataTableColumnOptions {
    return {
      ...this.noSortOpts,
      customBodyRender: (v: string) => {
        return (
          <Grid container css={customWidth(150)} direction="column" justifyContent="center" alignItems="flex-start">
            <Grid item css={style.caption}>
              {newLineText(cliTruncate(str(v) || "-", 30))}
            </Grid>
          </Grid>
        );
      },
    };
  }

  static toolTipOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: string) => {
        if (!v) return "-";
        return (
          <Grid css={customWidth(150)}>
            <Tooltip title={v} arrow>
              <Grid>{cliTruncate(str(v) || "-", 20)}</Grid>
            </Tooltip>
          </Grid>
        );
      },
    };
  }

  static get noSortToolTipOpts(): MUIDataTableColumnOptions {
    return {
      ...this.noSortOpts,
      customBodyRender: (v: string) => {
        if (!v) return "-";
        return (
          <Grid css={customWidth(150)}>
            <Tooltip title={v} arrow>
              <Grid>{cliTruncate(str(v) || "-", 20)}</Grid>
            </Tooltip>
          </Grid>
        );
      },
    };
  }

  static get linkOpts(): MUIDataTableColumnOptions {
    return {
      ...this.noSortOpts,
      customBodyRender: (v: string) => {
        if (!v) return "-";
        return (
          <Grid css={customWidth(150)}>
            <NextLink href={v} passHref legacyBehavior>
              <Tooltip title={v} arrow>
                <a target={"_blank"} css={style.link}>
                  {cliTruncate(str(v) || "-", 20)}
                </a>
              </Tooltip>
            </NextLink>
          </Grid>
        );
      },
    };
  }

  static datetimeOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: number) => {
        if (!v) return "-";
        return (
          <Grid css={customWidth(80)} container direction="column" justifyContent="center" alignItems="flex-start">
            <Grid item>{formatSlashDate(v)}</Grid>
            <Grid item>{formatSlashTime(v)}</Grid>
          </Grid>
        );
      },
    };
  }

  static get noSortDatetimeOpts(): MUIDataTableColumnOptions {
    return {
      ...this.noSortOpts,
      customBodyRender: (v: number) => {
        if (!v) return "-";
        return (
          <Grid css={customWidth(80)} container direction="column" justifyContent="center" alignItems="flex-start">
            <Grid item>{formatSlashDate(v)}</Grid>
            <Grid item>{formatSlashTime(v)}</Grid>
          </Grid>
        );
      },
    };
  }

  static captionOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: string) => {
        if (!v) return "-";
        return (
          <Tooltip title={v} arrow>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <Grid item css={style.caption}>
                {newLineText(v)}
              </Grid>
            </Grid>
          </Tooltip>
        );
      },
    };
  }

  static get noSortCaptionOpts(): MUIDataTableColumnOptions {
    return {
      ...this.noSortOpts,
      customBodyRender: (v: string) => {
        if (!v) return "-";
        return (
          <Tooltip title={v} arrow>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <Grid item css={style.caption}>
                {newLineText(v)}
              </Grid>
            </Grid>
          </Tooltip>
        );
      },
    };
  }

  static get aloneImageOpts(): MUIDataTableColumnOptions {
    return {
      ...this.noSortOpts,
      draggable: false,
      customBodyRender: (v: any) => {
        return (
          <Grid justifyContent={"center"} alignItems={"center"} container>
            <Avatar css={style.img} src={v || ""} variant={"square"} />
          </Grid>
        );
      },
    };
  }

  static get genderTypeOpts(): MUIDataTableColumnOptions {
    return {
      filter: true,
      sort: false,

      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && GenderEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["GenderEnum"]) => GenderEnumToValue(v),
      },
      customBodyRender: (v: APICompsSchema["GenderEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "male":
            return <Chip label={GenderEnumToValue(v)} color="info" />;
          case "female":
            return <Chip label={GenderEnumToValue(v)} color="error" />;
          case "other":
            return <Chip label={GenderEnumToValue(v)} color="default" />;
        }
      },
    };
  }

  static get organizationTypeOpts(): MUIDataTableColumnOptions {
    return {
      sort: false,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && OrganizationTypeEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["OrganizationTypeEnum"]) => OrganizationTypeEnumToValue(v),
      },
      customBodyRender: (v: APICompsSchema["OrganizationTypeEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "company":
            return <Chip label={OrganizationTypeEnumToValue(v)} color="success" variant="outlined" />;
          case "soleProprietor":
            return <Chip label={OrganizationTypeEnumToValue(v)} color="info" variant="outlined" />;
        }
      },
    };
  }

  static get organizationStatusTypeOpts(): MUIDataTableColumnOptions {
    return {
      sort: false,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && OrganizationStatusEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["OrganizationStatusEnum"]) => OrganizationStatusEnumToValue(v),
      },
      customBodyRender: (v: APICompsSchema["OrganizationStatusEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "waiting":
            return <Chip label={OrganizationStatusEnumToValue(v)} color="info" />;
          case "rejected":
            return <Chip label={OrganizationStatusEnumToValue(v)} color="error" />;
          case "active":
            return <Chip label={OrganizationStatusEnumToValue(v)} color="success" />;
          case "disabled":
            return <Chip label={OrganizationStatusEnumToValue(v)} color="warning" />;
        }
      },
    };
  }

  static get brandStatusTypeOpts(): MUIDataTableColumnOptions {
    return {
      sort: false,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && BrandStatusEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["BrandStatusEnum"]) => BrandStatusEnumToValue(v),
      },
      customBodyRender: (v: APICompsSchema["BrandStatusEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "editing":
            return <Chip label={BrandStatusEnumToValue(v)} color="default" />;
          case "waiting":
            return <Chip label={BrandStatusEnumToValue(v)} color="info" />;
          case "rejected":
            return <Chip label={BrandStatusEnumToValue(v)} color="error" />;
          case "open":
            return <Chip label={BrandStatusEnumToValue(v)} color="success" />;
          case "closed":
            return <Chip label={BrandStatusEnumToValue(v)} color="warning" />;
        }
      },
    };
  }

  static storeTypeOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && StoreTypeEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["StoreTypeEnum"]) => StoreTypeEnumToValue(v),
      },
      customBodyRender: (v: APICompsSchema["StoreTypeEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "visit":
            return <Chip label={StoreTypeEnumToValue(v)} color="info" />;
          case "order":
            return <Chip label={StoreTypeEnumToValue(v)} color="warning" />;
        }
      },
    };
  }

  static storeStatusTypeOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && StoreStatusEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["StoreStatusEnum"]) => StoreStatusEnumToValue(v),
      },
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: APICompsSchema["StoreStatusEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "editing":
            return <Chip label={StoreStatusEnumToValue(v)} color="default" />;
          case "waiting":
            return <Chip label={StoreStatusEnumToValue(v)} color="info" />;
          case "rejected":
            return <Chip label={StoreStatusEnumToValue(v)} color="error" />;
          case "open":
            return <Chip label={StoreStatusEnumToValue(v)} color="success" />;
          case "closed":
            return <Chip label={StoreStatusEnumToValue(v)} color="warning" />;
        }
      },
    };
  }

  static staffRoleTypeOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && StaffRoleEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["StaffRoleEnum"]) => StaffRoleEnumToValue(v),
      },
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: APICompsSchema["StaffRoleEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "general":
            return <Chip label={StaffRoleEnumToValue(v)} color="default" variant="outlined" />;
          case "storeManager":
            return <Chip label={StaffRoleEnumToValue(v)} color="info" variant="outlined" />;
          case "areaManager":
            return <Chip label={StaffRoleEnumToValue(v)} color="warning" variant="outlined" />;
          case "brandManager":
            return <Chip label={StaffRoleEnumToValue(v)} color="success" variant="outlined" />;
          case "supervisor":
            return <Chip label={StaffRoleEnumToValue(v)} color="primary" variant="outlined" />;
        }
      },
    };
  }

  static influencerStatusTypeOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && InfluencerStatusEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["InfluencerStatusEnum"]) => InfluencerStatusEnumToValue(v),
      },
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: APICompsSchema["InfluencerStatusEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "disabled":
            return <Chip label={InfluencerStatusEnumToValue(v)} color="default" />;
          case "waiting":
            return <Chip label={InfluencerStatusEnumToValue(v)} color="info" />;
          case "active":
            return <Chip label={InfluencerStatusEnumToValue(v)} color="success" />;
          case "canceled":
            return <Chip label={InfluencerStatusEnumToValue(v)} color="error" />;
        }
      },
    };
  }

  static get influencerLabelTypeOpts(): MUIDataTableColumnOptions {
    return {
      filter: true,
      sort: false,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && InfluencerLabelEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["InfluencerLabelEnum"]) => InfluencerLabelEnumToValue(v),
      },
      customBodyRender: (v: APICompsSchema["InfluencerLabelEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "general":
            return <></>;
          case "special":
            return <Chip label={InfluencerLabelEnumToValue(v)} color="info" />;
        }
      },
    };
  }

  static get influencerInboundCountryType(): MUIDataTableColumnOptions {
    return {
      filter: true,
      sort: false,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && InfluencerLabelEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["InfluencerLabelEnum"]) => InfluencerLabelEnumToValue(v),
      },
      customBodyRender: (inboundCountryCodes) => {
        const targetData = selectedCountryCodeList.find((d) => d.alpha2 === inboundCountryCodes?.[0]);
        if (!targetData?.alpha2) {
          return <></>;
        }
        return (
          <Grid container justifyContent="center">
            <span css={style.border} className={`flag-icon flag-icon-${targetData.alpha2.toLowerCase()}`} />
            <span css={style.padding}>{targetData?.companyjp}</span>
          </Grid>
        );
      },
    };
  }

  static shortIdOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (id: string) => {
        if (!id) return "-";
        return (
          <Grid container css={customWidth(100)} direction="column" justifyContent="center" alignItems="flex-start">
            {id.split("-")[0].toUpperCase()}
          </Grid>
        );
      },
    };
  }

  static get ticketTypeOpts(): MUIDataTableColumnOptions {
    return {
      sort: false,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => {
          return v && TicketTypeEnumToValue(v);
        },
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["TicketTypeEnum"]) => TicketTypeEnumToValue(v),
      },
      customBodyRender: (v: APICompsSchema["TicketTypeEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "visit":
            return <Chip label={TicketTypeEnumToValue(v)} color="info" />;
          case "order":
            return <Chip label={TicketTypeEnumToValue(v)} color="warning" />;
        }
      },
    };
  }

  static get ticketStatusTypeOpts(): MUIDataTableColumnOptions {
    return {
      sort: false,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && TicketStatusEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["TicketStatusEnum"]) => TicketStatusEnumToValue(v),
      },
      customBodyRender: (v: APICompsSchema["TicketStatusEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "editing":
            return <Chip label={TicketStatusEnumToValue(v)} color="secondary" />;
          case "waiting":
            return <Chip label={TicketStatusEnumToValue(v)} color="info" />;
          case "rejected":
            return <Chip label={TicketStatusEnumToValue(v)} color="error" />;
          case "open":
            return <Chip label={TicketStatusEnumToValue(v)} color="success" />;
          case "closed":
            return <Chip label={TicketStatusEnumToValue(v)} color="warning" />;
        }
      },
    };
  }

  static prMethodTypeOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      filter: true,
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: APICompsSchema["PRMethodTypeEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "all":
            return <Chip icon={<TbWorld />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "instagram":
            return <Chip icon={<FaInstagram />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "tiktok":
            return <Chip icon={<FaTiktok />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "twitter":
            return <Chip icon={<SVGIconTwitter />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "youtube":
            return <Chip icon={<FaYoutube />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "google":
            return <Chip icon={<FaGoogle />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "tabelog":
            return <Chip label={PRMethodTypeEnumToValue(v)} color="default" />;
        }
      },
    };
  }

  static customPrMethodTypeOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      ...this.sortOnlyOpts,
      filter: true,
      filterOptions: {
        fullWidth: true,
        names: ["instagram", "tiktok", "twitter", "youtube", "google", "tabelog"],
      },
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (v: APICompsSchema["PRMethodTypeEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "all":
            return <Chip icon={<TbWorld />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "instagram":
            return <Chip icon={<FaInstagram />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "tiktok":
            return <Chip icon={<FaTiktok />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "twitter":
            return <Chip icon={<SVGIconTwitter />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "youtube":
            return <Chip icon={<FaYoutube />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "google":
            return <Chip icon={<FaGoogle />} label={PRMethodTypeEnumToValue(v)} color="default" />;
          case "tabelog":
            return <Chip label={PRMethodTypeEnumToValue(v)} color="default" />;
        }
      },
    };
  }

  static get discountTypeOpts(): MUIDataTableColumnOptions {
    return {
      filter: true,
      customBodyRender: (v: APICompsSchema["DiscountTypeEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "none":
            return <Chip label={DiscountTypeEnumToValue(v)} color="info" />;
          case "jpy":
            return <Chip icon={<GiJapan />} label={DiscountTypeEnumToValue(v)} color="info" />;
          case "percent":
            return <Chip icon={<AiOutlinePercentage />} label={DiscountTypeEnumToValue(v)} color="info" />;
          case "usd":
            return <Chip icon={<AiOutlineDollarCircle />} label={DiscountTypeEnumToValue(v)} color="info" />;
        }
      },
    };
  }

  static influencerTicketOfferLabelOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (offerLabel: APICompsSchema["InfluencerTicketOfferLabelEnum"]) => {
        switch (offerLabel) {
          default:
            // TODO: トップインフルエンサーオファーが復活したら`default`を以下に戻して、ラベルテキストをオファー → 通常オファーに変更
            // return <>></>;
            return <Chip label={InfluencerTicketOfferLabelEnumToValue("none")} color="success" />;
          case "none":
            return <Chip label={InfluencerTicketOfferLabelEnumToValue(offerLabel)} color="success" />;
          case "general":
            return <Chip label={InfluencerTicketOfferLabelEnumToValue(offerLabel)} color="warning" />;
          case "top":
            return <Chip label={InfluencerTicketOfferLabelEnumToValue(offerLabel)} color="secondary" />;
          case "inbound":
            return <Chip label={InfluencerTicketOfferLabelEnumToValue(offerLabel)} color="info" />;
        }
      },
    };
  }

  static influencerTicketOfferLabelCustomOpts(sortOrder?: MUISortOptions): MUIDataTableColumnOptions {
    return {
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
        <CustomHeadLabel columnOptions={options} sortOrder={sortOrder} />
      ),
      customBodyRender: (offerLabel: APICompsSchema["InfluencerTicketOfferLabelEnum"]) => {
        switch (offerLabel) {
          default:
            return (
              <Chip label={InfluencerTicketOfferLabelEnumToValue("none")} color="success" css={chipStyled("success")} />
            );
          case "none":
            return (
              <Chip
                label={InfluencerTicketOfferLabelEnumToValue(offerLabel)}
                color="success"
                css={chipStyled("success")}
              />
            );
          case "general":
            return (
              <Chip
                label={InfluencerTicketOfferLabelEnumToValue(offerLabel)}
                color="warning"
                css={chipStyled("warning")}
              />
            );
          case "top":
            return (
              <Chip
                label={InfluencerTicketOfferLabelEnumToValue(offerLabel)}
                color="secondary"
                css={chipStyled("secondary")}
              />
            );
          case "inbound":
            return (
              <Chip label={InfluencerTicketOfferLabelEnumToValue(offerLabel)} color="info" css={chipStyled("info")} />
            );
        }
      },
    };
  }

  static get influencerTicketTypeOpts(): MUIDataTableColumnOptions {
    return {
      sort: false,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && InfluencerTicketStatusEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["InfluencerTicketStatusEnum"]) => InfluencerTicketStatusEnumToValue(v),
      },
      customBodyRender: (v: APICompsSchema["InfluencerTicketStatusEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "denied":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="error" />;
          case "canceled":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="error" />;
          case "requesting":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="warning" />;
          case "offering":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="warning" />;
          case "active":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="success" />;
          case "scheduling":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="warning" />;
          case "scheduled":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="success" />;
          case "used":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="success" />;
          case "checked":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="success" />;
          case "rated":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="info" />;
        }
      },
    };
  }

  static get influencerTicketTypeCustomOpts(): MUIDataTableColumnOptions {
    return {
      sort: false,
      filter: true,
      filterOptions: {
        fullWidth: true,
        renderValue: (v: any) => v && InfluencerTicketStatusEnumToValue(v),
      },
      customFilterListOptions: {
        render: (v: APICompsSchema["InfluencerTicketStatusEnum"]) => InfluencerTicketStatusEnumToValue(v),
      },
      customBodyRender: (v: APICompsSchema["InfluencerTicketStatusEnum"]) => {
        switch (v) {
          default:
            return <></>;
          case "denied":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="error" css={chipStyled("error")} />;
          case "canceled":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="error" css={chipStyled("error")} />;
          case "declined":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="error" css={chipStyled("error")} />;
          case "requesting":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="warning" css={chipStyled("warning")} />;
          case "offering":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="warning" css={chipStyled("warning")} />;
          case "active":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="success" css={chipStyled("success")} />;
          case "scheduling":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="warning" css={chipStyled("warning")} />;
          case "scheduled":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="success" css={chipStyled("success")} />;
          case "used":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="success" css={chipStyled("success")} />;
          case "checked":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="success" css={chipStyled("success")} />;
          case "rated":
            return <Chip label={InfluencerTicketStatusEnumToValue(v)} color="info" css={chipStyled("info")} />;
        }
      },
    };
  }

  static influencerTicketStatisticCustomOpts(tooltip: string, instagram?: boolean): MUIDataTableColumnOptions {
    return {
      sort: false,
      filter: false,
      customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => {
        const { name, label } = options;
        return (
          <div css={style.tooltipLabel}>
            {instagram && (
              <Image alt="hint" src="/sns-icons/instagram.svg" width="14" height="14" css={style.instagramIcon} />
            )}
            {label}
            <Tooltip title={tooltip} placement="bottom">
              <Image alt="hint" src="/analysis/hint-icon.svg" width="12" height="12" css={style.tooltipIcon} />
            </Tooltip>
          </div>
        );
      },
      customBodyRender: (v: string | number) => {
        return v.toLocaleString();
      },
    };
  }

  /* ソート時の比較用メソッド */
  static sortCompareString = (order: MUISortOptions["direction"], key: string) => {
    return (obj1: { data: any }, obj2: { data: any }) => {
      const val1 = obj1.data[key];
      const val2 = obj2.data[key];
      return (val1 - val2) * (order === "asc" ? 1 : -1);
    };
  };

  // 'yyyy-MM-dd'形式の文字列の順序比較
  static sortCompareDateString = (order: MUISortOptions["direction"], key: string) => {
    return (obj1: { data: any }, obj2: { data: any }) => {
      const val1 = new Date(obj1.data[key]).getTime();
      const val2 = new Date(obj2.data[key]).getTime();
      return (val1 - val2) * (order === "asc" ? 1 : -1);
    };
  };

  // 'hh:mm:ss'/'hh:mm'形式の文字列の順序比較
  static sortCompareTimeString = (order: MUISortOptions["direction"], key: string) => {
    return (obj1: { data: any }, obj2: { data: any }) => {
      const val1 = new Date(`Jan 1, 1970 ${obj1.data[key] || "00:00"}`).getTime();
      const val2 = new Date(`Jan 1, 1970 ${obj2.data[key] || "00:00"}`).getTime();
      return (val1 - val2) * (order === "asc" ? 1 : -1);
    };
  };
}

const style = {
  img: css`
    width: 40px;
    height: 40px;
    border-radius: 1000px;
  `,
  link: css`
    color: ${appColor.App.sidenav};
  `,
  caption: css`
    width: 150px;
    font-family: ${appTypo.font.notoJp};
    font-size: 12px;
    color: ${appColor.Text.primary};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `,
  padding: css`
    padding-left: 8px;
  `,
  border: css`
    border: 1px solid ${appColor.Border.secondary};
  `,
  tooltipLabel: css`
    display: flex;
    align-items: center;
    gap: 2px;
  `,
  tooltipIcon: css`
    cursor: pointer;
  `,
  instagramIcon: css`
    padding: 1.5px;
  `,
};

export const customWidth = (width: number) => css`
  width: ${width}px;
`;

const chipColorCalculate = (type: string) => {
  if (type === "secondary") {
    return {
      background: "#D6DBD9",
      border: "#AEB7B4",
      text: "#66726E",
    };
  }
  if (type === "warning") {
    return {
      background: "#F9D2B3",
      border: "#F4A667",
      text: "#C95B01",
    };
  }
  if (type === "info") {
    return {
      background: "#B3DBF1",
      border: "#67B7E3",
      text: "#0173B1",
    };
  }
  if (type === "error") {
    return {
      background: "#F5C6C6",
      border: "#EB8686",
      text: "#A02626",
    };
  }

  return {
    background: "#D7F7C2",
    border: "#A6EB84",
    text: "#006908",
  };
};

const chipStyled = (type: string) => css`
  background-color: ${chipColorCalculate(type).background};
  color: ${chipColorCalculate(type).text};
  border: 1px solid ${chipColorCalculate(type).border};
  border-radius: 4px;
  height: 20px;
  font-family: ${FontFamily.kintoSans};
  font-weight: ${FontWeight.bold};
  font-size: 12px;
  line-height: 12px;
`;
