import React, { MouseEvent, MouseEventHandler, useCallback } from "react";
import { FontFamily } from "@unit/styles";
import { Button } from "@mui/material";

import { MdDeleteOutline } from "react-icons/md";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsSizeOverrides } from "@mui/material/Button/Button";
import { css } from "@emotion/react";

type Props = {
  label?: string;
  size?: OverridableStringUnion<"small" | "medium" | "large", ButtonPropsSizeOverrides>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const CancelButton: React.FC<Props> = (props) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!props.onClick) {
        return;
      }
      props.onClick(event);
    },
    [props.onClick],
  );

  return (
    <Button fullWidth variant="outlined" color="secondary" size={props.size} css={style.button} onClick={handleClick}>
      {props.label || "キャンセル"}
    </Button>
  );
};
export default React.memo(CancelButton);

const style = {
  button: css`
    font-family: ${FontFamily.notoSansJp};
  `,
};
