import { css } from "@emotion/react";
import { Divider, Grid, Stack } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BorderColor, BorderStyle, FontColor, FontFamily, FontWeight } from "@unit/styles";
import { formatSlashDateStrings } from "@unit/utility";
import React from "react";

import DataItem from "../../../common/accordion/DataItem";

type Props = {
  ticket: APICompsSchema["TicketObject"];
};

const TicketDetailInfo: React.FC<Props> = (props) => {
  const { ticket } = props;
  const isVisit = ticket.type === "visit";

  const showDateRange = (start?: string | null, end?: string | null): string =>
    `${formatSlashDateStrings(start)} ~ ${formatSlashDateStrings(end)}`;
  const showTimeRange = (start: string, end?: string | null): string =>
    `${start.slice(0, 5)} ~ ${end?.slice(0, 5) || ""}`;

  return (
    <>
      <Grid p={2} css={style.title}>
        チケット詳細
      </Grid>
      <Divider css={BorderStyle.subtleDivider} className="focus-scroll-ticket-overview" />
      <Stack p={2} gap={2}>
        <DataItem label="ギフティング内容">{ticket.subTitle}</DataItem>
        {isVisit && (
          <>
            <Divider css={style.border} className="focus-scroll-ticket-companionMemo" />
            <DataItem label="同伴者について">{ticket.companionMemo}</DataItem>
          </>
        )}
        <Divider css={style.border} className="focus-scroll-ticket-datetime" />
        <DataItem label="応募期間">{showDateRange(ticket.entryStartDate, ticket.entryEndDate)}</DataItem>
        {isVisit && (
          <>
            <Divider css={style.border} />
            <DataItem label="来店可能期間">
              {showDateRange(ticket.availableStartDate, ticket.availableEndDate)}
            </DataItem>
            <Divider css={style.border} />
            <DataItem label="来店可能時間">
              {showTimeRange(ticket.availableStartTime, ticket.availableEndTime)}
            </DataItem>
          </>
        )}
      </Stack>
    </>
  );
};
export default React.memo(TicketDetailInfo);

const style = {
  title: css`
    font-family: ${FontFamily.notoSansJp};
    font-weight: ${FontWeight.bold};
    font-style: normal;
    font-size: 16px;
    color: ${FontColor.basic};
  `,
  border: css`
    border: 1px dashed ${BorderColor.subtle};
  `,
};
