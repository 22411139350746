import { css, Grid } from "@mui/material";
import { BackgroundColor, FontColor } from "@unit/styles";
import React from "react";

import { SVGShop } from "../../common/svg-icon/IconShop";
import { SVGTruck } from "../../common/svg-icon/IconTruck";

const TicketTypeToggleSwitch: React.FC = () => {
  return (
    <Grid css={style.toggleSwitch}>
      <SVGShop color={FontColor.basic} width="16px" height="16px" />
      <SVGTruck color={FontColor.basic} width="16px" height="16px" />
      <Grid p={1.5}>
        <SVGTruck color={FontColor.white} width="16px" height="16px" />
      </Grid>
    </Grid>
  );
};
export default TicketTypeToggleSwitch;

const style = {
  toggleSwitch: css`
    background-color: #ebebeb;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    position: relative;
    transition: 0.2s ease-out;
    width: 80px;
    height: 40px;

    cursor: pointer;
    border-radius: 20px;

    div {
      background-color: ${BackgroundColor.darkBasic};
      border-radius: 100%;
      display: inline-block;
      position: absolute;

      transition: 0.2s ease-out;
      translate: -20px;
      width: 40px;
      height: 40px;
      z-index: 2;
    }
  `,
  isOrder: css`
    div {
      translate: 20px;
    }
  `,
  collapse: css`
    width: 40px;
    div {
      translate: 0;
    }
  `,
};
