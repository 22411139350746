import { css, IconButton } from "@mui/material";
import React, { MouseEvent, MouseEventHandler, useCallback } from "react";

type Props = {
  size?: number;
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const AppBasicIconButton: React.FC<Props> = (props) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (props.onClick) {
        props.onClick(event);
      }
    },
    [props.onClick],
  );

  return (
    <IconButton disabled={props.disabled} css={style.button(props.size)} onClick={handleClick}>
      {props.children}
    </IconButton>
  );
};
export default React.memo(AppBasicIconButton);

const style = {
  button: (size: number = 40) => css`
    width: ${size}px;
    height: ${size}px;
    background: #ebebeb;
    &:hover {
      background: #ebebeb;
    }
  `,
};
