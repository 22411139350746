import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { BackgroundColor } from "@unit/styles";
import React from "react";

type Props = {
  transparent?: boolean;
  leftItemNode?: React.ReactNode;
  rightItemNode?: React.ReactNode;
};

const FloatingButtonHeaderTemplate: React.FC<Props> = (props) => {
  return (
    <Grid position="relative" css={style.root(props.transparent)}>
      <Grid px={5} container css={[style.positionFixed, style.displayFlexRow]} justifyContent="space-between">
        {/* 左サイドのボタン */}
        <Grid sx={{ width: "auto" }} css={style.displayFlexRow} gap={2}>
          {props.leftItemNode}
        </Grid>

        {/* 右サイドのボタン */}
        <Grid sx={{ width: "auto" }} css={style.displayFlexRow} gap={2}>
          {props.rightItemNode}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(FloatingButtonHeaderTemplate);

const style = {
  root: (transparent?: boolean) => css`
    z-index: 100;
    width: 100%;
    max-width: 768px;
    left: -24px;
    background-color: ${transparent ? "transparent" : BackgroundColor.lightBasic};
  `,
  positionFixed: css`
    position: fixed;
    max-width: 768px;
    width: 100%;
    height: 72px;
  `,
  displayFlexRow: css`
    display: flex;
    align-items: center;
    flex-direction: row;
  `,
};
