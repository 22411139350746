import * as React from "react";
import { SVGProps } from "react";

export const SVGPhoneNumber = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width} height={props.height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m10.886 7.635-1.03-1.03a1.288 1.288 0 0 0-1.778 0l-.16.161a.414.414 0 0 1-.528.055 8.853 8.853 0 0 1-1.26-.972 8.846 8.846 0 0 1-.97-1.26.416.416 0 0 1 .053-.527l.16-.16a1.258 1.258 0 0 0 0-1.78L4.346 1.095a2.146 2.146 0 0 0-2.964 0l-.252.252C-.253 2.726.644 5.025 3.795 8.175l.005.005.005.005C6 10.379 7.78 11.48 9.125 11.48a2.061 2.061 0 0 0 1.509-.629l.252-.252a2.096 2.096 0 0 0 0-2.964Zm-.593 2.371-.252.252c-1.296 1.295-4.092-1.113-5.643-2.666l-.005-.005-.005-.005C2.836 6.031.427 3.234 1.722 1.94l.252-.252a1.259 1.259 0 0 1 1.778 0l1.029 1.029a.42.42 0 0 1 0 .593l-.161.16a1.263 1.263 0 0 0-.16 1.583c.316.495.677.96 1.078 1.39.43.4.895.762 1.39 1.078a1.261 1.261 0 0 0 1.582-.16l.162-.161a.429.429 0 0 1 .592 0l1.029 1.028a1.258 1.258 0 0 1 0 1.78Z"
      fill="#000"
    />
  </svg>
);
