import { css } from "@emotion/react";
import { Avatar, Box, Grid, Rating, Stack } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { useAppSnackbar } from "@unit/hooks";
import { BackgroundColor, BorderColor, FontColor, FontFamily, FontWeight } from "@unit/styles";
import {
  SVGIconInstagramColored,
  SVGIconTikTok,
  SVGIconTwitter,
  SvgIconStar,
  SVGIconTabelog,
  SVGIconGoogle,
} from "@unit/svgs";
import { convertToWebP, ellipsis, formatSnsTitle } from "@unit/utility";
import React from "react";
import { SiYoutube } from "react-icons/si";

type Props = {
  influencer: APICompsSchema["InfluencerObject"];
  posts: APICompsSchema["FlatInfluencerTicketPostObject"][];
};

const InfluencerCard: React.FC<Props> = (props) => {
  const { setAppSnackbar } = useAppSnackbar();
  const imageUrlPosts = props.posts.filter((post) => post.imageUrl);
  const onClickSnsAccount = (snsType: APICompsSchema["PRMethodTypeEnum"]) => {
    const targetSns = props.influencer.snsAccounts.find((sns) => sns.type === snsType);
    if (!targetSns?.accountUrl)
      return setAppSnackbar(`${formatSnsTitle(snsType)}アカウントの登録がありません`, {
        error: true,
      });
    window.open(targetSns.accountUrl, "_blank");
  };
  return (
    <Box css={width}>
      <Grid display="flex" justifyContent="flex-start" alignItems="center" css={cardContainerStyled}>
        <Grid>
          <Avatar src={props.influencer?.avatarUrl ? convertToWebP(props.influencer.avatarUrl) : ""} css={avatarSize} />
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <Stack spacing={1} pl={2}>
            <Box css={accountNameStyled}>{ellipsis(props.influencer?.displayName || "", 15)}</Box>
            <Grid display="flex" alignItems="center">
              <Grid display="flex" alignItems="center">
                <Rating
                  css={ratingStyled}
                  value={props.influencer.ratingCount}
                  readOnly
                  icon={<SvgIconStar width={24} height={24} color={"#FBC929"} />}
                  emptyIcon={<SvgIconStar width={24} height={24} color={"#FBC929"} />}
                />
                <Box pl={2} css={ratingTextStyled}>
                  {props.influencer.ratingCount}件
                </Box>
              </Grid>
              <Grid container justifyContent={"flex-end"} alignItems={"center"} gap={1} item xs>
                {props.influencer.snsAccounts.some((sns) => sns.type === "tiktok" && sns.accountUrl) && (
                  <SVGIconTikTok width={24} height={24} onClick={() => onClickSnsAccount("tiktok")} />
                )}
                {props.influencer.snsAccounts.some((sns) => sns.type === "instagram" && sns.accountUrl) && (
                  <SVGIconInstagramColored width={24} height={24} onClick={() => onClickSnsAccount("instagram")} />
                )}
                {props.influencer.snsAccounts.some((sns) => sns.type === "twitter" && sns.accountUrl) && (
                  <SVGIconTwitter width={24} height={24} onClick={() => onClickSnsAccount("twitter")} />
                )}
                {props.influencer.snsAccounts.some((sns) => sns.type === "youtube" && sns.accountUrl) && (
                  <SiYoutube size={24} color={FontColor.youtube} onClick={() => onClickSnsAccount("youtube")} />
                )}
                {props.influencer.snsAccounts.some((sns) => sns.type === "google" && sns.accountUrl) && (
                  <SVGIconGoogle width={24} height={24} onClick={() => onClickSnsAccount("google")} />
                )}
                {props.influencer.snsAccounts.some((sns) => sns.type === "tabelog" && sns.accountUrl) && (
                  <SVGIconTabelog
                    height={32}
                    width={32}
                    viewBox="8 4 20 20"
                    onClick={() => onClickSnsAccount("tabelog")}
                  />
                )}
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      {!!imageUrlPosts.length && (
        <Grid justifyContent="flex-start" pt={2}>
          <Grid css={textPostAchievements} mb={1}>
            投稿実績
          </Grid>
          <Box css={postsHorizontalScroll}>
            {imageUrlPosts.map(
              (post) =>
                post.imageUrl && (
                  <img
                    key={post.id}
                    css={postImage}
                    src={post.imageUrl}
                    srcSet={post.imageUrl}
                    loading="eager"
                    alt=""
                  />
                ),
            )}
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default React.memo(InfluencerCard);

const width = css`
  width: 100%;
`;

const cardContainerStyled = css`
  background: ${BackgroundColor.lightBasic};
`;
const avatarSize = css`
  width: 80px;
  height: 80px;
`;
const accountNameStyled = css`
  font-family: ${FontFamily.kintoSans};
  font-style: normal;
  font-weight: ${FontWeight.medium};
  font-size: 16px;
  line-height: 19.2px;
  display: flex;
  align-items: center;
  letter-spacing: -0.8px;
  color: ${FontColor.basic};
`;

const ratingStyled = css`
  span.MuiRating-icon {
    padding-top: 8px;
  }
`;

const ratingTextStyled = css`
  font-family: ${FontFamily.kintoSans};
  font-style: normal;
  font-weight: ${FontWeight.medium};
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: ${FontColor.basic};
`;

const textPostAchievements = css`
  font-family: ${FontFamily.kintoSans};
  font-style: normal;
  font-weight: ${FontWeight.medium};
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1em;
  color: ${FontColor.sub};
`;

const postsHorizontalScroll = css`
  display: flex;
  height: 80px;
  flex-direction: row;
  grid-gap: 8px;
  padding-right: 16px;
  overflow-x: scroll;
  white-space: nowrap;

  // スクロールバー非表示
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const postImage = css`
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 8px;
  background: gray;
  border: 1px solid ${BorderColor.wildSand};
`;
