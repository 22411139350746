import React from "react";
import { BiPlus } from "react-icons/bi";

import RadiusButton from "./RadiusButton";

type Props = {
  label?: string;
  disabled?: boolean;
  onClick?: () => void;
};

const RadiusAddButton: React.FC<Props> = (props) => {
  return (
    <RadiusButton
      disabled={props.disabled}
      color="dodgerBlue"
      size="small"
      startIcon={<BiPlus size={16} />}
      onClick={props.onClick}
    >
      {props.label || "追加"}
    </RadiusButton>
  );
};
export default React.memo(RadiusAddButton);
