/**************************************************
 * カラーパレット
 * 下記を参考にして色名を決めると良い
 * @see https://chir.ag/projects/name-that-color
 **************************************************/
export default {
  black: "#000000",
  tuatara: "#2B2A27",
  scorpion: "#575757",
  // nevada: "#707173",
  // rollingStone: "#797A7C",
  jumbo: "#7F7F89", // Note: 旧カラー appColor.Button.accent.gray
  naturalGray: "#93918F", // Note: 旧カラー appColor.Text.secondary / appColor.Accent.gray
  naturalGray2: "#92918F", // Note: 旧カラー appColor.Text.deepGray
  frenchGray: "#B4B4BE", // Note: 旧カラー appColor.Button.negative.text
  silver: "#C0C0C0", // Note: 旧カラー appColor.Rank.second
  grayNickel: "#C2C2C0", // Note: 旧カラー appColor.Text.gray
  alto: "#CECECE", // Note: 旧カラー appColor.Border.third
  alto2: "#D9D9D9", // Note: 旧カラー appColor.Progress.empty
  alto3: "#DDDDDD", // Note: 旧カラー appColor.Chart.barAgeGenderU
  alto4: "#E0E0E0", // Note: 旧カラー appColor.Border.card
  foam: "#DEF8FC", // Note: 旧カラー appColor.Accent.ultraLightBlue
  mercury: "#E9E9E9", // Note: 旧カラー appColor.Text.superLightGray
  jupiter: "#E2E2E2",
  iron: "#E4E4E5",
  gallery: "#EBEBEB",
  bonJour: "#F0ECEE", //  Note: 旧カラー appColor.Chart.audienceCity[4]
  seashell: "#F1F1F1", // Note: 旧カラー appColor.Chart.audienceCity[7]
  whisper: "#F4F3F8", // Note: 旧カラー appColor.Background.sidenav
  wildSand: "#F4F4F4", // Note: 旧カラー appColor.Text.secondary / appColor.Border.secondary / appColor.Background.third
  alabaster: "#F7F7F7", // Note: 旧カラー appColor.Background.secondary
  aquaHaze: "#F8FAFB", // Note: 旧カラー appColor.Background.chat
  whiteLilac: "#FCFCFE", // Note: 旧カラー appColor.Background.selected
  white: "#FFFFFF",
  aliceBlue: "#F3FBFF", // Note: 旧カラー appColor.Background.welcome

  azureRadiance: "#1877F2", // Note: 旧カラー appColor.Button.accent.facebook
  danube: "#4A91C7", // Note: 旧カラー appColor.Background.step
  steelBlue: "#4984bc", // Note: 旧カラー appColor.Button.accent.blue
  cerulean: "#0BA1E7", // Note: 旧カラー appColor.Button.accent.grassBlue / appColor.Icon.grassBlue
  waikawaGray: "#5E6E9F", // Note: 旧カラー appColor.Button.social.facebook
  viridian: "#448c7b", // Note: 旧カラー appColor.Button.accent.green
  dodgerBlue: "#3AB7FF", // Note: UNITのブランドカラー
  pictonBlue: "#32D3EB", // Note: UNITのブランドカラー（グラデーションに使用）
  turquoiseBlue: "#62D9EB", // Note: 旧カラー appColor.Chart.audienceCity[1]
  cornflower: "#91DFEC", // Note: 旧カラー appColor.Chart.audienceCity[2]
  jordyBlue: "#8FCDF2", // Note: 旧カラー appColor.Chart.ratedCircle[1]
  malibu: "#82B1FF", // Note: 旧カラー appColor.Chart.barAgeGenderM
  ppindle: "#BCDAEB", // Note: 旧カラー appColor.Chart.ratedCircle[2]
  powderBlue: "#C1E6ED", // Note: 旧カラー appColor.Chart.audienceCity[3]
  easternBlue: "#28A9BC", // Note: 旧カラー appColor.App.sidenav
  tradewind: "#51b5ab", // Note: 旧カラー appColor.Accent.turquoiseBlue
  pacificBlue: "#00A9BC", // Note: 旧カラー appColor.Text.active
  deYork: "#7CBC93", // Note: 旧カラー appColor.Accent.green
  aquaForest: "#5AAA76", // Note: 旧カラー appColor.Accent.deepGreen
  aquaForest2: "#4DA07B", // Note: 旧カラー appColor.Accent.previeBlue
  goblin: "#478A5E", // Note: 旧カラー appColor.Accent.darkGreen
  // appleGreen: "#5EC43B",
  red: "#FF0000",
  persimmoRed: "#FF5555",
  razzmatazz: "#F11E7A", // Note: 旧カラー appColor.Progress.instagram
  punch: "#DE3E44", // Note: 旧カラー appColor.App.accent
  tickleMePink: "#FF80AB", // Note: 旧カラー appColor.Chart.barAgeGenderF
  fairPink: "#FFECEC",
  bittersweet: "#FF7663", // Note: 旧カラー appColor.Button.accent.red / appColor.Accent.red
  brandyPunch: "#CD7F32", // Note: 旧カラー appColor.Rank.third
  texasRose: "#FFAA4E", // Note: 旧カラー appColor.Accent.orange
  yellow: "#FABF26",
  lightningYellow: "#FBC929", // Note: 旧カラー appColor.Accent.yellow
  ultraLightYellow: "#FFFCE0",
  saffron: "#F2C938", // Note: 旧カラー appColor.Rank.first
} as const;

// コメントアウトしている色はインフルエンサーアプリの新デザインで使用しているがダッシュボードでは使用していない色
