import { APICompsSchema, defaultFlatSnsAccountObject } from "@unit/apis";
import { UUID } from "@unit/utility";
import React, { useState } from "react";

import PrimaryTextField from "../../../common/form-item/PrimaryTextField";

type Props = {
  snsAccounts: APICompsSchema["FlatSNSAccountObject"][];
  onChangeSnsAccounts: (newSnsAccounts: APICompsSchema["FlatSNSAccountObject"][]) => void;
  onFocus?: () => void;
};

const SnsAccountListForm: React.FC<Props> = (props) => {
  const [instagramAccount, setInstagramAccount] = useState<APICompsSchema["FlatSNSAccountObject"]>(
    props.snsAccounts.find((sa) => sa.type === "instagram") || {
      ...defaultFlatSnsAccountObject(),
      id: UUID.randomUUID(),
      type: "instagram",
    },
  );
  const [tiktokAccount, setTiktokAccount] = useState<APICompsSchema["FlatSNSAccountObject"]>(
    props.snsAccounts.find((sa) => sa.type === "tiktok") || {
      ...defaultFlatSnsAccountObject(),
      id: UUID.randomUUID(),
      type: "tiktok",
    },
  );
  const [twitterAccount, setTwitterAccount] = useState<APICompsSchema["FlatSNSAccountObject"]>(
    props.snsAccounts.find((sa) => sa.type === "twitter") || {
      ...defaultFlatSnsAccountObject(),
      id: UUID.randomUUID(),
      type: "twitter",
    },
  );
  const [youtubeAccount, setYoutubeAccount] = useState<APICompsSchema["FlatSNSAccountObject"]>(
    props.snsAccounts.find((sa) => sa.type === "youtube") || {
      ...defaultFlatSnsAccountObject(),
      id: UUID.randomUUID(),
      type: "youtube",
    },
  );
  const [googleAccount, setGoogleAccount] = useState<APICompsSchema["FlatSNSAccountObject"]>(
    props.snsAccounts.find((sa) => sa.type === "google") || {
      ...defaultFlatSnsAccountObject(),
      id: UUID.randomUUID(),
      type: "google",
    },
  );
  const [tabelogAccount, setTabelogAccount] = useState<APICompsSchema["FlatSNSAccountObject"]>(
    props.snsAccounts.find((sa) => sa.type === "tabelog" && sa.accountUrl.includes("tabelog")) || {
      ...defaultFlatSnsAccountObject(),
      type: "tabelog",
    },
  );
  // TODO: その他のSNSアカウントが入力された場合、便宜的に`type:"tabelog"`として扱う
  const [otherAccount, setOtherAccount] = useState<APICompsSchema["FlatSNSAccountObject"]>(
    props.snsAccounts.find((sa) => sa.type === "tabelog" && !sa.accountUrl.includes("tabelog")) || {
      ...defaultFlatSnsAccountObject(),
      type: "tabelog",
    },
  );

  const handleChange = (type: APICompsSchema["SNSAccountTypeEnum"] | "other", accountUrl: string) => {
    switch (type) {
      case "instagram":
        setInstagramAccount({ ...instagramAccount, accountUrl });
        break;
      case "tiktok":
        setTiktokAccount({ ...tiktokAccount, accountUrl });
        break;
      case "twitter":
        setTwitterAccount({ ...twitterAccount, accountUrl });
        break;
      case "youtube":
        setYoutubeAccount({ ...youtubeAccount, accountUrl });
        break;
      case "google":
        setGoogleAccount({ ...googleAccount, accountUrl });
        break;
      case "tabelog":
        setTabelogAccount({ ...tabelogAccount, accountUrl });
        break;
      case "other":
        setOtherAccount({ ...otherAccount, accountUrl });
        break;
    }
    props.onChangeSnsAccounts([
      type === "instagram" ? { ...instagramAccount, accountUrl } : instagramAccount,
      type === "tiktok" ? { ...tiktokAccount, accountUrl } : tiktokAccount,
      type === "twitter" ? { ...twitterAccount, accountUrl } : twitterAccount,
      type === "youtube" ? { ...youtubeAccount, accountUrl } : youtubeAccount,
      type === "google" ? { ...googleAccount, accountUrl } : googleAccount,
      type === "tabelog" ? { ...tabelogAccount, accountUrl } : tabelogAccount,
      type === "other" ? { ...otherAccount, accountUrl } : otherAccount,
    ]);
  };

  return (
    <>
      <PrimaryTextField
        fieldType="string"
        value={instagramAccount.accountUrl}
        title="Instagram"
        placeholder="https://instagram.com/xxxx"
        onChange={(value) => handleChange("instagram", value)}
        onFocus={props.onFocus}
      />
      <PrimaryTextField
        fieldType="string"
        value={tiktokAccount.accountUrl}
        title="TikTok"
        placeholder="https://www.tiktok.com/@xxxx"
        onChange={(value) => handleChange("tiktok", value)}
        onFocus={props.onFocus}
      />
      <PrimaryTextField
        fieldType="string"
        value={twitterAccount.accountUrl}
        title="X(旧Twitter)"
        placeholder="https://twitter.com/xxxx"
        onChange={(value) => handleChange("twitter", value)}
        onFocus={props.onFocus}
      />
      <PrimaryTextField
        fieldType="string"
        value={youtubeAccount.accountUrl}
        title="Youtube"
        placeholder="https://www.youtube.com/@xxxx"
        onChange={(value) => handleChange("youtube", value)}
        onFocus={props.onFocus}
      />
      <PrimaryTextField
        fieldType="string"
        value={googleAccount.accountUrl}
        title="Googleプロフィール"
        placeholder="https://www.google.com/search?q=xxxx"
        onChange={(value) => handleChange("google", value)}
        onFocus={props.onFocus}
      />
      <PrimaryTextField
        fieldType="string"
        value={tabelogAccount.accountUrl}
        title="食べログ"
        placeholder="https://tabelog.com/tokyo/A0000/xxxx"
        onChange={(value) => handleChange("tabelog", value)}
        onFocus={props.onFocus}
      />
      <PrimaryTextField
        fieldType="string"
        value={otherAccount.accountUrl}
        title="その他"
        placeholder="https://other-sns.com/xxxx"
        onChange={(value) => handleChange("other", value)}
        onFocus={props.onFocus}
      />
    </>
  );
};
export default SnsAccountListForm;
