import { SVGProps } from "react";

export const SVGIconTwitterBall = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40" {...props}>
    <rect width="40" height="40" fill="#000" rx="20"></rect>
    <path
      fill="#fff"
      d="M21.976 18.162L30.72 8h-2.072l-7.591 8.824L14.993 8H8l9.168 13.343L8 32h2.072l8.016-9.318L24.491 32h6.993l-9.508-13.838zm-2.838 3.299l-.929-1.329L10.82 9.56H14l5.965 8.532.93 1.328 7.753 11.091h-3.182l-6.327-9.05z"
    ></path>
  </svg>
);
