export const foodType = [
  "和食",
  "洋食",
  "居酒屋",
  "ラーメン",
  "カフェ",
  "テイクアウト",
  "イタリアン",
  "フレンチ",
  "串焼き",
  "鉄板",
  "焼肉",
  "ケバブ",
];

export const LocationType: { region: string; prefectures: string[] }[] = [
  {
    region: "北海道",
    prefectures: [""],
  },
  {
    region: "東北",
    prefectures: ["青森県", "秋田県", "岩手県", "山形県", "宮城県", "福島県"],
  },
  {
    region: "関東",
    prefectures: ["東京都", "神奈川県", "千葉県", "埼玉県", "群馬県", "栃木県", "茨城県"],
  },
  {
    region: "北陸・甲信越",
    prefectures: ["新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県"],
  },
  {
    region: "東海",

    prefectures: ["静岡県", "愛知県", "岐阜県", "三重県"],
  },
  {
    region: "関西",
    prefectures: ["大阪府", "京都府", "兵庫県", "滋賀県", "奈良県", "和歌山県"],
  },
  {
    region: "中国",
    prefectures: ["広島県", "岡山県", "山口県", "島根県", "鳥取県"],
  },
  {
    region: "四国",
    prefectures: ["徳島県", "香川県", "愛媛県", "高知県"],
  },
  {
    region: "九州・沖縄",
    prefectures: ["福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"],
  },
];
