import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class AdminStaffApi extends BaseApi {
  async getStaffList(
    parameters: APIPaths["/admin-dash/staff/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/staff/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/staff/list", {
        params: parameters,
      })
    ).data;
  }
  async registerStaffNew(
    requestBody: APIPaths["/admin-dash/staff/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/staff/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/staff/new", requestBody)).data;
  }
  async getStaffById(
    id: APIPaths["/admin-dash/staff/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/staff/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/staff/${id}`)).data;
  }
  async patchStaff(
    id: APIPaths["/admin-dash/staff/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/staff/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/staff/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/staff/${id}`, requestBody)).data;
  }
  async deleteStaff(
    id: APIPaths["/admin-dash/staff/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/staff/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/staff/${id}`)).data;
  }
  async postChangePasswordStaff(
    id: APIPaths["/admin-dash/staff/{id}/change-password"]["post"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/staff/{id}/change-password"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/staff/{id}/change-password"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(`/admin-dash/staff/${id}/change-password`, requestBody)).data;
  }
}
