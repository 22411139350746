import { css } from "@emotion/react";
import { Box, Grid } from "@mui/material";
import { BackgroundColor, FontColor, FontFamily, FontWeight } from "@unit/styles";
import colorPalette from "@unit/styles/src/app-style/color-palette";
import { SvgIconCheck } from "@unit/svgs";
import React from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

import RadiusButton from "../../common/buttton/RadiusButton";
import RadiusOutlinedButton from "../../common/buttton/RadiusOutlinedButton";

type Props = {
  step: number;
  maxStep: number;
  disable?: boolean;
  completeCount?: { current: number; max: number };
  rateSelected: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  onSend?: () => void;
};

const StepFormFooter: React.FC<Props> = (props) => {
  const handlePrev = () => {
    if (!props.onPrev) {
      return;
    }
    props.onPrev();
  };

  const handleNext = () => {
    if (!props.onNext) {
      return;
    }
    props.onNext();
  };

  const isFinalStep = props.maxStep <= props.step;

  return (
    <Grid height={38} position="sticky" css={style.footerButton}>
      <Grid display="flex" justifyContent="space-between" alignItems="center">
        {props.rateSelected ? (
          <>
            {!!props.completeCount ? (
              <Grid display="flex" alignItems="end" css={style.completeCount}>
                <Box component="span" className="current">
                  {props.completeCount.current}
                </Box>
                <Box component="span" className="max">
                  /{props.completeCount.max}
                </Box>
                <Box component="span" className="label">
                  完了
                </Box>
              </Grid>
            ) : (
              <RadiusOutlinedButton
                size="large"
                color={colorPalette.dodgerBlue}
                borderColor={colorPalette.dodgerBlue}
                backgroundColor={colorPalette.white}
                disabled={props.step === 1}
                startIcon={<FiArrowLeft />}
                onClick={handlePrev}
              >
                戻る
              </RadiusOutlinedButton>
            )}
          </>
        ) : (
          <Grid />
        )}

        <RadiusButton
          size="large"
          color="dodgerBlue"
          borderColor={BackgroundColor.activeBlue}
          backgroundColor={BackgroundColor.activeBlue}
          disabledBackgroundColor={BackgroundColor.grayChip}
          disabled={props.disable}
          endIcon={isFinalStep ? <SvgIconCheck width={16} height={16} /> : <FiArrowRight />}
          onClick={isFinalStep ? props.onSend : handleNext}
        >
          {isFinalStep ? "送信" : "次へ"}
        </RadiusButton>
      </Grid>
    </Grid>
  );
};
export default React.memo(StepFormFooter);

const style = {
  footerButton: css`
    bottom: 0;
    left: 0;
    background: ${BackgroundColor.lightBasic};

    button {
      width: 160px;
      height: 40px;
      padding: 8px 4px;
    }
  `,
  completeCount: css`
    span {
      font-family: ${FontFamily.kintoSans};
      font-style: normal;
      font-weight: ${FontWeight.bold};
      &.current {
        font-size: 24px;
        color: ${FontColor.unit};
        line-height: 100%;
        letter-spacing: 1.2px;
      }
      &.max {
        font-size: 16px;
        color: ${FontColor.sub};
        line-height: 120%;
      }
      &.label {
        padding-left: 8px;
        font-size: 16px;
        color: ${FontColor.sub};
        line-height: 125%;
      }
    }
  `,
};
