import React, { MouseEvent, MouseEventHandler, useCallback } from "react";
import { Button } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsSizeOverrides } from "@mui/material/Button/Button";

import { HiOutlineLockOpen } from "react-icons/hi";
import { css } from "@emotion/react";
import { FontFamily } from "@unit/styles";

type Props = {
  label?: string;
  size?: OverridableStringUnion<"small" | "medium" | "large", ButtonPropsSizeOverrides>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const StatusOpenButton: React.FC<Props> = (props) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!props.onClick) {
        return;
      }
      props.onClick(event);
    },
    [props.onClick],
  );

  return (
    <Button
      fullWidth
      variant="outlined"
      size={props.size}
      color="info"
      startIcon={<HiOutlineLockOpen />}
      onClick={handleClick}
      css={style.button}
    >
      {props.label || "公開する"}
    </Button>
  );
};
export default React.memo(StatusOpenButton);

const style = {
  button: css`
    font-family: ${FontFamily.notoSansJp};
  `,
};
