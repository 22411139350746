import { SVGProps } from "react";

export const SVGIconArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14" {...props}>
    <path
      fill="#2B2A27"
      d="M7.665 13.521a1.143 1.143 0 000-1.616L3.902 8.142h10.955a1.143 1.143 0 000-2.286H3.902l3.763-3.764A1.143 1.143 0 006.05.476L.335 6.191a1.143 1.143 0 000 1.616l5.714 5.714a1.142 1.142 0 001.616 0z"
    ></path>
  </svg>
);
