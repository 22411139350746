import { css } from "@emotion/react";
import { Divider, Grid, Stack } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BorderStyle, FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";

import PrMethodChip from "../../pr-method-type/chip/PrMethodChip";
import TicketImageCarousel from "../image/TicketImageCarousel";
import TicketTypeLabel from "../TicketTypeLabel";

type Props = {
  ticket: APICompsSchema["TicketObject"];
};

const TicketOutlineInfo: React.FC<Props> = (props) => {
  return (
    <>
      {/* チケット画像 */}
      <TicketImageCarousel ticket={props.ticket} />

      {/* PR投稿先 */}
      <Grid px={2} py={1} display="flex" alignItems="center" gap={2}>
        <Grid css={style.labelText}>PR投稿先</Grid>
        <PrMethodChip prMethodType={props.ticket.prMethodType} />
      </Grid>

      <Divider css={BorderStyle.subtleDivider} />

      <Stack p={2} gap={1}>
        <TicketTypeLabel type={props.ticket.type} />
        <Grid css={style.titleText}>{props.ticket.title}</Grid>
        <Grid css={style.description}>{props.ticket.description}</Grid>
      </Stack>
    </>
  );
};
export default React.memo(TicketOutlineInfo);

const style = {
  labelText: css`
    font-family: ${FontFamily.notoSansJp};
    font-size: 12px;
    font-style: normal;
    font-weight: ${FontWeight.bold};
    color: ${FontColor.basic};
  `,
  titleText: css`
    font-family: ${FontFamily.notoSansJp};
    font-size: 14px;
    font-style: normal;
    font-weight: ${FontWeight.bold};
    color: ${FontColor.basic};
    letter-spacing: 0.72px;
    line-height: 140%;
  `,
  description: css`
    font-family: ${FontFamily.notoSansJp};
    font-size: 14px;
    font-style: normal;
    font-weight: ${FontWeight.normal};
    color: ${FontColor.basic};
    line-height: 140%;
    letter-spacing: 0.7px;
    white-space: pre-line;
  `,
};
