import { APICompsSchema } from "../api-config";

export const flatBrandObject = (): APICompsSchema["FlatBrandObject"] => {
  return {
    id: "",
    name: "",
    status: "editing",
    statusComment: null,
    url: null,
    createdAt: 0,
    updatedAt: 0,
  };
};
