import { AdminMemberApi } from "@unit/apis";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { useCallback } from "react";

import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { dashboardPath, loginPath } from "@/global-state/admin-app-path";
import { API_URL, firebaseGetAuth } from "@/global-state/firebase-settings";
import { authUserAtom, idTokenAtom, loadingAtom } from "@/global-state/jotai-atom";

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = "518bb79dc9bb888f72f686ce77f5feb8";
const LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY = "381dd579d84447c7701272c76b109f40";

export const useAuthentication = () => {
  const router = useRouter();

  const { setAppSnackbar } = useAppSnackbar();
  const [, setLoading] = useAtom(loadingAtom);
  const [, setIdToken] = useAtom(idTokenAtom);
  const [, setAuthUser] = useAtom(authUserAtom);

  const resetAuth = useCallback(async () => {
    setAuthUser(null);
    setIdToken(undefined);
    setLoading(false);
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, "");
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY, "");
    await router.push(loginPath);
  }, []);

  const syncIdToken = useCallback(async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    const expiresAtStr = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY);
    if (accessToken && expiresAtStr) {
      const now = new Date().getTime();
      const expiresAt = parseInt(expiresAtStr);
      if (now < expiresAt) {
        setIdToken(accessToken);
        await syncAuthUser(accessToken);
        return accessToken;
      }
    }

    return new Promise<string | null>((resolve) => {
      firebaseGetAuth.onAuthStateChanged(async (user) => {
        const accessToken = (await user?.getIdToken(true)) || undefined;
        if (!accessToken) {
          await resetAuth();
          resolve(null);
          return;
        }
        await syncAuthUser(accessToken);
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES_AT_KEY, `${new Date().getTime() + 30 * 60 * 1000}`);
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);
        await setIdToken(accessToken);
        resolve(accessToken);
      });
    });
  }, []);

  const syncAuthUser = useCallback(async (token: string) => {
    setLoading(true);
    try {
      const checkApi = new AdminMemberApi(API_URL, token);
      const member = await checkApi.getAdminProfile();
      await setAuthUser(member.object);
    } catch (e: any) {
      console.error(e?.response?.data?.message || e?.response?.data?.devMessage);
      await resetAuth();
    } finally {
      setLoading(false);
    }
  }, []);

  const authReFetchMember = useCallback(async () => {
    setLoading(true);
    firebaseGetAuth.onAuthStateChanged(async (user) => {
      const accessToken = (await user?.getIdToken(true)) || undefined;
      if (!accessToken) return resetAuth();
      await setIdToken(accessToken);
      const checkApi = new AdminMemberApi(API_URL, accessToken);
      const member = await checkApi.getAdminProfile();
      if (!member) return resetAuth();
      await setAuthUser(member.object);
      setLoading(false);
    });
  }, []);

  // Email&Passwordでのログイン
  const authLoginWithEmail = useCallback(async (email: string, password: string) => {
    const accessToken = await signInWithEmailAndPassword(firebaseGetAuth, email, password)
      .then(async (credential) => (await credential.user?.getIdToken(true)) || undefined)
      .catch((error) => {
        let errorMessage = "";
        switch (error.code) {
          case "auth/user-not-found":
            errorMessage = "管理者で登録がありませんでした";
            break;
          case "auth/wrong-password":
            errorMessage = "パスワードが一致しませんでした。";
            break;
          default:
            errorMessage = error?.response?.data?.detail || error?.message || error;
        }
        setAppSnackbar(errorMessage, { error: true });
        return undefined;
      });
    if (!accessToken) {
      return await resetAuth();
    }
    try {
      await setIdToken(accessToken);

      const checkApi = new AdminMemberApi(API_URL, accessToken);
      const member = await checkApi.getAdminProfile();
      await setAuthUser(member.object);
      await router.push(dashboardPath);
      setAppSnackbar(`${member.object.email}でログインしました`, { success: true });
    } catch (e) {
      setAppSnackbar("管理者ではありません。", { error: true });
      return resetAuth();
    }
  }, []);

  // ログアウト
  const authLogout = useCallback(async () => {
    setLoading(true);
    signOut(firebaseGetAuth)
      .then(async () => {
        setAppSnackbar("ログアウトしました。", { info: true });
        await resetAuth();
      })
      .catch((error) => {
        setAppSnackbar(error?.message || "error", { error: true });
      })
      .finally(() => setLoading(false));
  }, []);

  return { syncIdToken, authReFetchMember, authLoginWithEmail, authLogout, resetAuth };
};
