import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { FontColor, FontWeight } from "@unit/styles";
import { SVGIconTicket } from "@unit/svgs";
import React from "react";

const TicketNotExists: React.FC = () => {
  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={12} px={3}>
        <SVGIconTicket color={FontColor.inactive} width={26} height={24} />
        <Grid css={style.text}>まだチケットがありません</Grid>
      </Grid>
    </Grid>
  );
};
export default React.memo(TicketNotExists);

const style = {
  text: css`
    font-size: 16px;
    font-weight: ${FontWeight};
    color: ${FontColor.inactive};
    text-align: center;
    margin-top: 20px;
  `,
};
