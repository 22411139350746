import { css, Grid, IconButton } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BorderStyle } from "@unit/styles";
import React from "react";

import { SVGIconChain } from "../../../influencer-app/common/svg-icon/IconChain";
import { SVGIconHotPepperBall } from "../../../influencer-app/common/svg-icon/IconHotPepperBall";
import { PNGIconTabelogBall } from "../../../influencer-app/common/svg-icon/IconTabelogBall";

type Props = {
  storeUrl: APICompsSchema["FlatStoreUrlObject"];
};

const ReservationUrlButton: React.FC<Props> = (props) => {
  const handleOpenLink = () => {
    const url = props.storeUrl.url;
    if (url) {
      window.open(url, "_blank");
    }
  };

  const ICONS = {
    "google-map": <></>,
    "apple-map": <></>,
    "hotpepper-reservation": <SVGIconHotPepperBall height={24} width={24} />,
    "tabelog-reservation": <PNGIconTabelogBall height={28} width={28} />,
    "other-reservation": <SVGIconChain height={24} width={24} />,
  };

  return (
    <IconButton
      css={[BorderStyle.solidSubtle, props.storeUrl.type === "tabelog-reservation" && style.tabelogShift]}
      onClick={handleOpenLink}
    >
      <Grid height={24} width={24}>
        {ICONS[props.storeUrl.type]}
      </Grid>
    </IconButton>
  );
};
export default React.memo(ReservationUrlButton);

const style = {
  tabelogShift: css`
    padding-left: 7px;
    padding-right: 9px;
    img {
      margin: 0px;
    }
  `,
};
