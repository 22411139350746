import { css } from "@emotion/react";
import { Divider, Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BackgroundColor, BorderStyle } from "@unit/styles";
import React from "react";

import StoreAvatarOutline from "../../domain/store/StoreAvatarOutline";
import TicketDetailInfo from "../../domain/ticket/detail/TicketDetailInfo";
import TicketOtherInfo from "../../domain/ticket/detail/TicketOtherInfo";
import TicketOutlineInfo from "../../domain/ticket/detail/TicketOutlineInfo";
import TicketPrInfo from "../../domain/ticket/detail/TicketPrInfo";
import HomeNavFooter from "../../layout/footer/HomeNavFooter";
import TicketDetailHeader from "../../layout/header/TicketDetailHeader";

type Props = {
  ticket: APICompsSchema["TicketObject"];
};

const TicketDetailPreviewPane: React.FC<Props> = (props) => {
  return (
    <>
      <TicketDetailHeader ticket={props.ticket} />
      {/* チケット画像 / チケット概要 */}
      <TicketOutlineInfo ticket={props.ticket} />
      <Divider css={BorderStyle.subtleDivider} />

      {/* ストア情報 */}
      <StoreAvatarOutline store={props.ticket.store} />
      <Divider css={BorderStyle.subtleDivider} />

      {/* チケット詳細 */}
      <TicketDetailInfo ticket={props.ticket} />
      <Divider css={BorderStyle.subtleDivider} />

      {/* あなたにお願いしたいこと */}
      <TicketPrInfo ticket={props.ticket} />
      <Divider css={BorderStyle.subtleDivider} />

      {/* その他 */}
      <TicketOtherInfo ticket={props.ticket} />

      <Grid position="sticky" py={2} css={style.footer}>
        <HomeNavFooter />
      </Grid>
    </>
  );
};
export default React.memo(TicketDetailPreviewPane);

const style = {
  customButtonCard: css`
    width: 100%;
    border-radius: 16px;
    &:active {
      box-shadow: none;
    }
  `,
  footer: css`
    bottom: 0;
    width: 100%;
    height: 80px;
    background: ${BackgroundColor.lightBasic};
  `,
};
