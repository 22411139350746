import { useMediaQuery, useTheme } from "@mui/material";

export type MuiMediaQuery = "xs" | "sm" | "md" | "lg" | "xl";

export const useCustomMediaQuery = () => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const isMdMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = !useMediaQuery(theme.breakpoints.up("lg"));
  const isMonitor = !useMediaQuery(theme.breakpoints.up("xl"));

  // TicketTemplateDialogContent.tsx でタブ幅を微調整用に使用
  const isTabTextOverflowLess = useMediaQuery(theme.breakpoints.down(508));
  const isTabTextOverflowMore = useMediaQuery(theme.breakpoints.between(865, 1032));
  const isTabTextOverflow = isTabTextOverflowLess || isTabTextOverflowMore;
  return { isMobile, isMdMobile, isTablet, isMonitor, isTabTextOverflow };
};

export const useBetweenMediaQuery = (min: MuiMediaQuery, max: MuiMediaQuery) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between(min, max));
};
