import ColorPalette from "./color-palette";

export const FontColor = {
  black: ColorPalette.black,
  basic: ColorPalette.tuatara,
  sub: ColorPalette.naturalGray,
  inactive: ColorPalette.naturalGray,
  subtle: ColorPalette.mercury,
  white: ColorPalette.white,
  unit: ColorPalette.dodgerBlue,

  link: ColorPalette.dodgerBlue,
  error: ColorPalette.bittersweet,
  appError: ColorPalette.punch,

  // SNSアイコン
  youtube: ColorPalette.red,
} as const;

export const FontFamily = {
  kintoSans: "kinto-sans",
  notoSansJp: "Noto Sans JP, sans-serif",
  roboto: "Roboto, sans-serif",
  poppins: "Poppins",
} as const;

// Note: ダッシュボード側では直書きでフォントサイズの指定する事
//       ダッシュボード新デザイン実装の際に再考します。
// export const FontSize = {
//   title: "16px",
//   headline: "15px",
//   caption: "14px",
//   subhead: "13px",
//   label: "12px",
//   description: "11px",
//   note: "10px",
// } as const;

/**************************************************
 * 一般的な太さ名との対応
 * @See https://developer.mozilla.org/ja/docs/Web/CSS/font-weight#%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AA%E5%A4%AA%E3%81%95%E5%90%8D%E3%81%A8%E3%81%AE%E5%AF%BE%E5%BF%9C
 **************************************************/
export const FontWeight = {
  semiLight: 350,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
} as const;
