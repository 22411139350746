import { css, Grid, Stack } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { NewLineText } from "@unit/component";
import { BackgroundColor, FontColor, FontFamily, FontWeight } from "@unit/styles";
import { convertToWebP, logoImageUrl, noImageUrl } from "@unit/utility";
import React from "react";

import BasicAvatar from "../../common/image/BasicAvatar";
import { SVGIconAddressColoredGray } from "../../common/svg-icon/IconAddressColoredGray";
import { SVGRestaurantFoodColoredGray } from "../../common/svg-icon/IconRestaurantFoodColoredGray";
import { SVGRightArrow } from "../../common/svg-icon/IconRightArrow";
import { SVGShop } from "../../common/svg-icon/IconShop";
import { SVGTruck } from "../../common/svg-icon/IconTruck";

type Props = {
  store: APICompsSchema["StoreObject"];
};

const StoreAvatarOutline: React.FC<Props> = (props) => {
  const { store } = props;

  const isVisitType = store.type === "visit";
  const storeImageUrl = store.imageUrls.length ? convertToWebP(store.iconImageUrl || store.imageUrls[0]) : logoImageUrl;

  return (
    <Grid p={2} position="relative" css={style.container}>
      <Grid position="absolute">
        <BasicAvatar size={56} src={storeImageUrl} noImageUrl={noImageUrl} />
      </Grid>

      <Stack position="relative" justifyContent="space-between" pl={8.5} pr={3} height={56}>
        {/* ストア名 */}
        <Grid css={style.storeTitle}>
          <NewLineText text={store.name} />
        </Grid>

        {/* ストアタイプ | ジャンル */}
        <Grid css={style.storeInfo}>
          {isVisitType ? (
            <span>
              <SVGShop width={14} height={12} />
              <span className="text">来店</span>
            </span>
          ) : (
            <span>
              <SVGTruck width={14} height={12} />
              <span className="text">通販</span>
            </span>
          )}
          <span className="genre-display">
            <SVGRestaurantFoodColoredGray width={12} height={12} />
            <span className="text">{store.genres.map((genre) => genre.name).join(", ")}</span>
          </span>
        </Grid>

        {/* エリア情報 */}
        <Grid css={style.storeInfo}>
          <span>
            <SVGIconAddressColoredGray width={14} height={12} />
            <span>{store.areas.map((area) => `${area.prefecture || "北海道"} ${area.area}`).join(", ")}</span>
          </span>
        </Grid>
      </Stack>
      <Grid position="absolute" top={32} right={16}>
        <SVGRightArrow width={22} height={22} color={FontColor.sub} />
      </Grid>
    </Grid>
  );
};
export default React.memo(StoreAvatarOutline);

const style = {
  container: css`
    cursor: pointer;
    &:hover {
      background: ${BackgroundColor.accentGray};
    }
  `,
  storeInfo: css`
    line-height: 14px;
    font-family: ${FontFamily.notoSansJp};
    font-weight: ${FontWeight.normal};
    font-style: normal;
    font-size: 14px;

    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      position: relative;
      line-height: 14px;
      svg {
        position: absolute;
        left: 0;
        top: 0px;
      }
      .text {
        margin-left: 20px;
      }
    }
    span.genre-display {
      margin-left: 10px;
      span.text {
        margin-left: 18px;
      }
    }
  `,
  storeTitle: css`
    font-family: ${FontFamily.notoSansJp};
    font-weight: ${FontWeight.bold};
    font-style: normal;
    font-size: 16px;
    color: ${FontColor.basic};
    line-height: 100%;

    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  `,
};
