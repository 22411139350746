import { Checkbox, css, Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { appColor, FontColor, FontFamily, FontWeight } from "@unit/styles";
import { SVGIconGoogle, SVGIconInstagramColored, SVGIconTabelog, SVGIconTikTok, SVGIconTwitter } from "@unit/svgs";
import React from "react";
import { SiYoutube } from "react-icons/si";

type Props = {
  snsAccountType: APICompsSchema["SNSAccountTypeEnum"];
  checked: boolean;
  onClick: (newValue: boolean) => void;
};

const IconComponents = {
  instagram: <SVGIconInstagramColored height={24} width={24} />,
  tiktok: <SVGIconTikTok height={24} width={24} />,
  twitter: <SVGIconTwitter height={24} width={24} />,
  youtube: <SiYoutube size={28} color={FontColor.youtube} />,
  google: <SVGIconGoogle height={24} width={24} />,
  tabelog: <SVGIconTabelog height={32} width={32} viewBox="8 6 20 20" />,
};

const LabelComponents = {
  instagram: (
    <Grid py={1} height={40}>
      Instagram
    </Grid>
  ),
  tiktok: (
    <Grid py={1} height={40}>
      Tiktok
    </Grid>
  ),
  twitter: (
    <Grid py={1} height={40}>
      X(旧Twitter)
    </Grid>
  ),
  youtube: (
    <Grid py={0.5} height={36}>
      Youtube
    </Grid>
  ),
  google: (
    <Grid height={40}>
      Google
      <br />
      プロフィール
    </Grid>
  ),
  tabelog: <Grid height={32}>食べログ</Grid>,
};

const SnsCheckbox: React.FC<Props> = (props) => {
  return (
    <Grid
      css={style.snsCheckbox}
      onClick={() => {
        props.onClick(!props.checked);
      }}
    >
      <Checkbox checked={props.checked} />
      <Grid p={1} css={style.iconLabel}>
        <Grid>{IconComponents[props.snsAccountType]}</Grid>
        {LabelComponents[props.snsAccountType]}
      </Grid>
    </Grid>
  );
};
export default React.memo(SnsCheckbox);

const style = {
  snsCheckbox: css`
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid ${appColor.Border.card};
    user-select: none;
  `,
  iconLabel: css`
    text-align: center;
    font-family: ${FontFamily.notoSansJp};
    color: ${FontColor.basic};
    font-style: normal;
    font-size: 12px;
    font-weight: ${FontWeight.normal};
    line-height: 150%;
    letter-spacing: 0.1px;
  `,
};
