import { APICompsSchema } from "../api-config";
import { organizationObject } from "./organization";
import { storeObject } from "./store";

export const flatTicketObject = (): APICompsSchema["FlatTicketObject"] => {
  return {
    id: "",
    status: "editing",
    statusComment: null,
    imageUrls: [],
    title: "",
    subTitle: "",
    description: null,
    type: "visit",
    maxCapacity: 999,
    companionMemo: null,
    prMethodType: "instagram",
    discountType: "none",
    discountAmount: 0,
    timezone: "jst",
    entryStartDate: "",
    entryEndDate: null,
    availableStartDate: "",
    availableEndDate: null,
    availableStartTime: "10:00",
    availableEndTime: "17:00",
    remark: null,
    createdAt: 0,
    updatedAt: 0,
  };
};

export const ticketObject = (): APICompsSchema["TicketObject"] => {
  return {
    ...flatTicketObject(),
    organization: organizationObject(),
    store: storeObject(),
    influencer_ticket_statuses: [],
  };
};
