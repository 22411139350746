import { css } from "@emotion/react";
import { Button } from "@mui/material";
import { appColor, FontColor, FontFamily, FontWeight } from "@unit/styles";
import { homePreviewPath } from "@unit/utility";
import React from "react";

const TicketAppPreviewButton: React.FC = () => {
  return (
    <Button
      css={[style.actionButton, style.containButton]}
      size={"medium"}
      variant={"contained"}
      href={homePreviewPath}
      target={"_blank"}
    >
      他のお店のチケットを確認
    </Button>
  );
};
export default React.memo(TicketAppPreviewButton);

const style = {
  actionButton: css`
    font-family: ${FontFamily.notoSansJp};
    font-size: 16px;
    font-weight: ${FontWeight.bold};
    height: 38px;
  `,
  containButton: css`
    background-color: ${appColor.Button.accent.grassBlue};
    border: 1px solid ${appColor.Button.accent.grassBlue};
    font-weight: ${FontWeight.medium};
    color: ${FontColor.white};
    &:hover {
      background-color: ${appColor.Button.accent.grassBlue};
      border: 1px solid ${appColor.Button.accent.grassBlue};
      color: ${FontColor.white};
      opacity: 0.8;
    }
  `,
};
