import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BackgroundColor } from "@unit/styles";
import React from "react";

import StoreDetailContainer from "../../domain/store/detail/StoreDetailContainer";
import StoreImageCarousel from "../../domain/store/image/StoreImageCarousel";
import HomeNavFooter from "../../layout/footer/HomeNavFooter";
import StoreDetailHeader from "../../layout/header/StoreDetailHeader";

type Props = {
  store: APICompsSchema["StoreObject"];
};

const StoreDetailPreviewPane: React.FC<Props> = (props) => {
  return (
    <>
      <StoreDetailHeader store={props.store} />
      {props.store.imageUrls && (
        <Grid textAlign={"center"}>
          <StoreImageCarousel store={props.store} />
        </Grid>
      )}
      <StoreDetailContainer store={props.store} />
      <Grid position="sticky" py={2} css={style.footer}>
        <HomeNavFooter />
      </Grid>
    </>
  );
};
export default React.memo(StoreDetailPreviewPane);

const style = {
  footer: css`
    bottom: 0;
    width: 100%;
    height: 80px;
    background: ${BackgroundColor.lightBasic};
  `,
};
