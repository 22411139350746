import * as React from "react";
import { SVGProps } from "react";

export const SvgIconBigStar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width} height={props.height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fill={props.color}>
      <path d="M35.81 46.283a5.471 5.471 0 0 1-2.575-.654l-8.309-4.445a1.245 1.245 0 0 0-1.15 0l-8.373 4.329a5.46 5.46 0 0 1-7.886-5.817l1.658-9.28a1.235 1.235 0 0 0-.349-1.056l-6.704-6.63a5.458 5.458 0 0 1 3.094-9.29l9.332-1.288a1.246 1.246 0 0 0 .94-.676l4.223-8.446A5.427 5.427 0 0 1 24.588 0a5.428 5.428 0 0 1 4.92 3.083l4.107 8.446a1.237 1.237 0 0 0 .929.686l9.312 1.425a5.458 5.458 0 0 1 2.956 9.343l-6.789 6.525a1.243 1.243 0 0 0-.359 1.056l1.531 9.3a5.469 5.469 0 0 1-5.384 6.42Z" />
      <path d="M24.335 36.867c.898-.001 1.783.22 2.576.644l8.309 4.444a1.235 1.235 0 0 0 1.805-1.298l-1.53-9.301a5.47 5.47 0 0 1 1.604-4.825l6.799-6.556a1.235 1.235 0 0 0-.676-2.112l-9.312-1.425a5.462 5.462 0 0 1-4.043-3.04L25.76 4.952a1.236 1.236 0 0 0-2.227 0l-4.223 8.446a5.46 5.46 0 0 1-4.223 2.892l-9.333 1.288a1.235 1.235 0 0 0-.697 2.112l6.725 6.62a5.459 5.459 0 0 1 1.542 4.845l-1.658 9.28a1.246 1.246 0 0 0 1.795 1.32l8.372-4.329a5.464 5.464 0 0 1 2.502-.56Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
