import { css } from "@emotion/react";
import React from "react";

type Props = {
  alt?: string;
  src?: string;
  srcSet?: string;
  defaultImageUrl?: string;
  maxSize?: number;
  aspect?: [number, number];
  onClick?: React.MouseEventHandler<HTMLImageElement>;
};

const ScreenFitImage: React.FC<Props> = (props) => {
  return (
    <img
      src={props.src || props.defaultImageUrl}
      srcSet={props.srcSet || props.defaultImageUrl}
      loading="lazy"
      alt={props.alt}
      css={[
        style.screenFit(props.aspect && props.aspect[0] / props.aspect[1]),
        !!props.maxSize && style.maxSize(props.maxSize, props.aspect && props.aspect[0] / props.aspect[1]),
      ]}
      onClick={props.onClick}
    />
  );
};

export default React.memo(ScreenFitImage);

const style = {
  screenFit: (aspectRatio: number = 1) => css`
    width: 100vw;
    height: ${100 / aspectRatio}vw;
    object-fit: cover;
  `,
  maxSize: (size: number, aspectRatio: number = 1) => css`
    display: unset !important;
    max-width: ${size}px;
    max-height: ${size / aspectRatio}px;
  `,
};
