import { SVGProps } from "react";

export const SVGTruck = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" fill="none" viewBox="0 0 14 9" {...props}>
    <path
      fill={props.color || props.fill || "#93918F"}
      d="M13.202 3.734l-.952-.317-1.4-1.867a1.167 1.167 0 00-.934-.467h-.583A1.167 1.167 0 008.166 2.25v3.5c.002.205.057.407.162.583h-.907c.105-.176.161-.378.162-.583V1.667A1.167 1.167 0 006.416.5h-5.25A1.167 1.167 0 000 1.667V5.75a1.167 1.167 0 001.166 1.167h.03a1.459 1.459 0 002.858 0h5.892a1.459 1.459 0 002.858 0h.612A.583.583 0 0014 6.333V4.841a1.167 1.167 0 00-.798-1.107zM2.625 7.5a.875.875 0 110-1.75.875.875 0 010 1.75zm7.291-4.083a.583.583 0 010-1.167h.73l.875 1.167H9.916zM11.375 7.5a.875.875 0 110-1.75.875.875 0 010 1.75z"
    ></path>
  </svg>
);
