import { css } from "@emotion/react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { appColor } from "@unit/styles";
import React from "react";

import { LocationType } from "@/data/sampleSelectType";

type Props = {
  caption: string;
  selectedRegion: string;
  selectedPrefecture: string;
  onChange?: (event: SelectChangeEvent<string>) => void;
};

const SpPrefectureSelector: React.FC<Props> = (props) => {
  const isSelectBlank = props.selectedPrefecture === "";
  return (
    <FormControl fullWidth css={formControlStyled} disabled={!props.selectedRegion}>
      <InputLabel css={textStyled}>{!isSelectBlank ? props.caption : props.selectedRegion}</InputLabel>
      <Select
        value={props.selectedPrefecture}
        onChange={props.onChange}
        autoWidth
        disabled={isSelectBlank}
        label={props.caption}
        css={textStyled}
      >
        {LocationType.map((location, locationIndex) => {
          if (location.region === props.selectedRegion) {
            return location.prefectures.map((prefecture, index) => {
              return (
                <MenuItem value={prefecture} key={`prefecture-${locationIndex}-${index}`}>
                  {prefecture}
                </MenuItem>
              );
            });
          }
        })}
      </Select>
    </FormControl>
  );
};
export default SpPrefectureSelector;

const formControlStyled = css`
  background: ${appColor.App.white};
`;

const textStyled = css`
  font-size: 14px;
`;
