import ColorPalette from "./color-palette";

const gradient = {
  unitBlue: [
    `linear-gradient(0deg, ${ColorPalette.dodgerBlue} 0%, ${ColorPalette.dodgerBlue} 100%)`,
    `linear-gradient(243deg, ${ColorPalette.pictonBlue} 0%, ${ColorPalette.dodgerBlue} 100%);`,
  ],
};

export const BackgroundColor = {
  none: ColorPalette.alabaster, // Appデフォルトの背景色
  lightBasic: ColorPalette.white,
  darkBasic: ColorPalette.tuatara,
  accentRed: ColorPalette.bittersweet,
  accentGray: ColorPalette.gallery,
  grayChip: ColorPalette.naturalGray,
  hoveredListItem: ColorPalette.wildSand,
  activeBlue: ColorPalette.dodgerBlue,

  caution: ColorPalette.punch,
  unitBlue: gradient.unitBlue.join(", "),
} as const;
