import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class AdminTicketApi extends BaseApi {
  async getTicketList(
    parameters: APIPaths["/admin-dash/ticket/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/ticket/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/ticket/list", {
        params: parameters,
      })
    ).data;
  }
  async registerTicketNew(
    requestBody: APIPaths["/admin-dash/ticket/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/ticket/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/ticket/new", requestBody)).data;
  }
  async getTicketById(
    id: APIPaths["/admin-dash/ticket/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/ticket/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/ticket/${id}`)).data;
  }
  async patchTicketById(
    id: APIPaths["/admin-dash/ticket/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/ticket/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/ticket/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/ticket/${id}`, requestBody)).data;
  }
  async deleteTicketById(
    id: APIPaths["/admin-dash/ticket/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/ticket/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/ticket/${id}`)).data;
  }
  //  認証系
  async putRequestTicketById(
    id: APIPaths["/admin-dash/ticket/{id}/request"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/ticket/{id}/request"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/ticket/${id}/request`)).data;
  }
  async putApproveTicketById(
    id: APIPaths["/admin-dash/ticket/{id}/request-approval"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/ticket/{id}/request-approval"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/ticket/${id}/request-approval`)).data;
  }
  async putRejectTicketById(
    id: APIPaths["/admin-dash/ticket/{id}/request-rejection"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/ticket/{id}/request-rejection"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/ticket/{id}/request-rejection"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/ticket/${id}/request-rejection`, requestBody)).data;
  }
  async putOpenTicketById(
    id: APIPaths["/admin-dash/ticket/{id}/open"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/ticket/{id}/open"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/ticket/${id}/open`)).data;
  }
  async putCloseTicketById(
    id: APIPaths["/admin-dash/ticket/{id}/close"]["put"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/ticket/{id}/close"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/ticket/${id}/close`)).data;
  }
  async putOfferTicketById(
    id: APIPaths["/admin-dash/ticket/{id}/offer"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/ticket/{id}/offer"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/ticket/{id}/offer"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/ticket/${id}/offer`, requestBody)).data;
  }
}
