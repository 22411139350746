import { Divider, Grid } from "@mui/material";
import { APICompsSchema, defaultFlatStoreUrlObject, StoreUrlTypeEnumObject } from "@unit/apis";
import { UUID } from "@unit/utility";
import React, { useState } from "react";

import RadiusAddButton from "../../../common/buttton/RadiusAddButton";
import RoundDeleteButton from "../../../common/buttton/RoundDeleteButton";
import PrimaryTextField from "../../../common/form-item/PrimaryTextField";

type Props = {
  storeUrls: APICompsSchema["FlatStoreUrlObject"][];
  onChangeStoreUrls: (newStoreUrls: APICompsSchema["FlatStoreUrlObject"][]) => void;
  onFocus?: () => void;
};

const OtherReservationForm: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string>("");
  const reservationTypes = ["hotpepper-reservation", "tabelog-reservation", "other-reservation"];
  const reservationUrls = props.storeUrls.filter((storeUrl) => reservationTypes.includes(storeUrl.type));

  const getUrlType = (url: string): APICompsSchema["StoreUrlTypeEnum"] => {
    const hotPepperPattern = /^(https?:\/\/)?(www\.)?hotpepper\.jp\//;
    const tabelogPattern = /^(https?:\/\/)?(www\.)?tabelog\.com\//;
    if (hotPepperPattern.test(url)) {
      return "hotpepper-reservation";
    } else if (tabelogPattern.test(url)) {
      return "tabelog-reservation";
    } else {
      return "other-reservation";
    }
  };

  const handleAddUrl = () => {
    const newStoreUrl = {
      ...defaultFlatStoreUrlObject(),
      id: UUID.randomUUID(),
      type: getUrlType(value),
      url: value,
    };
    setValue("");

    props.onChangeStoreUrls([...props.storeUrls, newStoreUrl]);
  };

  const handleChangeUrl = (inputUrl: string, targetStoreUrl: APICompsSchema["FlatStoreUrlObject"]) => {
    const newStoreUrls = props.storeUrls.reduce(
      (prev: APICompsSchema["FlatStoreUrlObject"][], curr: APICompsSchema["FlatStoreUrlObject"]) => {
        const newStoreUrl =
          targetStoreUrl.id === curr.id ? { ...curr, url: inputUrl, type: getUrlType(inputUrl) } : curr;
        return [...prev, newStoreUrl];
      },
      [],
    );
    props.onChangeStoreUrls(newStoreUrls);
  };

  const handleDeleteUrl = (targetStoreUrl: APICompsSchema["FlatStoreUrlObject"]) => {
    const newStoreUrls = props.storeUrls.filter((storeUrl) => storeUrl.id !== targetStoreUrl.id);
    props.onChangeStoreUrls(newStoreUrls);
  };

  return (
    <>
      <Grid position="relative">
        <PrimaryTextField
          fieldType="string"
          title="URL"
          placeholder="https://example.com"
          value={value}
          onChange={setValue}
          onFocus={props.onFocus}
        />
      </Grid>

      <RadiusAddButton disabled={!value} onClick={handleAddUrl} />

      <Grid py={2}>
        <Divider orientation="horizontal" />
      </Grid>

      {reservationUrls.map((storeUrl) => {
        return (
          <Grid position="relative" key={storeUrl.id}>
            <PrimaryTextField
              fieldType="string"
              title={StoreUrlTypeEnumObject[storeUrl.type]}
              placeholder="https://example.com"
              value={storeUrl.url}
              onChange={(newValue) => handleChangeUrl(newValue, storeUrl)}
              onFocus={props.onFocus}
            />
            <Grid position="absolute" top={-4} right={0}>
              <RoundDeleteButton onClick={() => handleDeleteUrl(storeUrl)} />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};
export default OtherReservationForm;
