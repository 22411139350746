import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import React from "react";
const InitialScreenContainer: React.FC = () => {
  return (
    <Grid container justifyContent="center" alignContent="center" css={initialStyled}>
      <Grid item padding={1}>
        <img src="/initail-screen-container.svg" alt="" />
      </Grid>
    </Grid>
  );
};

export default InitialScreenContainer;

const initialStyled = css`
  z-index: -10;
  background-image: url("/blob-scene-haikei.svg");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
`;
