import { css } from "@emotion/react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { appColor } from "@unit/styles";
import React from "react";

import { LocationType } from "@/data/sampleSelectType";

type Props = {
  caption: string;
  selectedRegion: string;
  onChange?: (event: SelectChangeEvent<string>) => void;
};

const SpRegionSelector: React.FC<Props> = (props) => {
  return (
    <FormControl fullWidth css={formControlStyled}>
      <InputLabel css={textStyled}>{props.caption}</InputLabel>
      <Select value={props.selectedRegion} onChange={props.onChange} autoWidth label={props.caption} css={textStyled}>
        {LocationType.map((location, index) => {
          return (
            <MenuItem value={location.region} key={index}>
              {location.region}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default SpRegionSelector;

const formControlStyled = css`
  background: ${appColor.App.white};
`;

const textStyled = css`
  font-size: 14px;
`;
