import { IconButton } from "@mui/material";
import React, { useCallback } from "react";
import { MdOutlineClose } from "react-icons/md";

type Props = {
  onClick: () => void;
};

const RoundCloseButton: React.FC<Props> = (props) => {
  const handleClose = useCallback(() => {
    props.onClick();
  }, []);
  return (
    <IconButton onClick={handleClose}>
      <MdOutlineClose size={24} />
    </IconButton>
  );
};
export default React.memo(RoundCloseButton);
