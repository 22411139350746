import { css, Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BackgroundColor } from "@unit/styles";
import React from "react";

import BasicAccordion from "../../../common/accordion/BasicAccordion";
import TicketNotExists from "../../ticket/TicketNotExists";

import StoreDetailInfo from "./StoreDetailInfo";
import StoreOutlineInfo from "./StoreOutlineInfo";

type Props = {
  store: APICompsSchema["StoreObject"];
};

const StoreDetailContainer: React.FC<Props> = (props) => {
  return (
    <Grid css={style.root(!!props.store.imageUrls.length)}>
      {/* ストア基本情報 */}
      <Grid px={2} py={1}>
        <StoreOutlineInfo
          store={props.store}
          onClickStoreUrl={() => {
            if (!props.store.url) {
              return;
            }
            window.open(props.store.url, "_blank");
          }}
        />
      </Grid>

      {/* 店舗情報 */}
      <BasicAccordion label="店舗情報">
        <StoreDetailInfo store={props.store} />
      </BasicAccordion>

      {/* 最新のチケット */}
      <BasicAccordion label="最新のチケット">
        {/* TODO: 一旦、一律でチケットが存在しないケースのみ */}
        {/*{props.tickets?.length ? (*/}
        {/*  props.tickets.map((ticket) => (*/}
        {/*    <React.Fragment key={ticket.id}>*/}
        {/*      <TicketCard ticket={ticket} />*/}
        {/*    </React.Fragment>*/}
        {/*  ))*/}
        {/*) : (*/}
        {/*  <TicketNotExists />*/}
        {/*)}*/}
        <Grid height={400}>
          <TicketNotExists />
        </Grid>
      </BasicAccordion>
    </Grid>
  );
};
export default React.memo(StoreDetailContainer);

const style = {
  root: (isTransparent: boolean) => css`
    z-index: 100;
    width: 100%;
    max-width: 768px;
    background-color: ${isTransparent ? "transparent" : BackgroundColor.lightBasic};
  `,
};
