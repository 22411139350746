import React, { SVGProps } from "react";

export const SVGShop = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 10 10" {...props}>
    <path
      fill={props.color || props.fill || "#93918F"}
      d="M8.75 0h-7.5A1.25 1.25 0 000 1.25V5a1.245 1.245 0 00.625 1.077v3.298A.625.625 0 001.25 10h5a.625.625 0 00.625-.625V6.25c.36 0 .702-.158.938-.431.235.273.577.43.937.431v3.437a.313.313 0 00.625 0v-3.61A1.245 1.245 0 0010 5V1.25A1.25 1.25 0 008.75 0zM5.625 5a.625.625 0 01-1.25 0h1.25zM3.75 5A.625.625 0 012.5 5h1.25zM.625 5h1.25a.625.625 0 01-1.25 0zM6.25 6.25v3.125h-5V6.25c.36 0 .702-.158.938-.431a1.235 1.235 0 001.874 0 1.235 1.235 0 001.875 0c.09.102.195.19.313.258v.173zm.625-.625A.625.625 0 016.25 5H7.5a.625.625 0 01-.625.625zm1.875 0A.625.625 0 018.125 5h1.25a.625.625 0 01-.625.625z"
    ></path>
  </svg>
);
