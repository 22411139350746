import { css } from "@emotion/react";
import { Button } from "@mui/material";
import { FontColor, appColor, appTypo } from "@unit/styles";
import React, { ReactNode } from "react";

// TODO: 色定義をしっかり決める
type colorThemes =
  | "primary"
  | "accentGray"
  | "grassBlue"
  | "accentGreen"
  | "accentRed"
  | "dodgerBlue"
  | "mainBlue"
  | "unset";

type Props = {
  color?: colorThemes;
  size: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
  borderColor?: string;
  borderWidth?: number;
  backgroundColor?: string;
  disabled?: boolean;
  disabledBackgroundColor?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const RadiusButton: React.FC<Props> = (props) => {
  return (
    <Button
      css={customButtonStyled(
        props.color || "unset",
        props.size,
        props.disabledBackgroundColor,
        props.borderColor,
        props.borderWidth,
        props.backgroundColor,
      )}
      type="submit"
      fullWidth
      variant={props.variant ?? "contained"}
      size={props.size}
      disabled={props.disabled}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
export default React.memo(RadiusButton);

const fontColor = {
  primary: appColor.Button.primary.text,
  accentGray: appColor.Button.primary.text,
  grassBlue: appColor.Button.primary.text,
  mainBlue: appColor.Accent.blue,
  accentGreen: appColor.Button.primary.text,
  accentRed: appColor.Button.primary.text,
  dodgerBlue: appColor.Button.primary.text,
  unset: "unset",
  white: FontColor.white,
};

const backgroundColors = {
  primary: appColor.Button.primary.color,
  accentGray: appColor.Button.accent.gray,
  mainBlue: appColor.Accent.blue,
  grassBlue: appColor.Button.accent.grassBlue,
  accentGreen: appColor.Accent.green,
  accentRed: appColor.Accent.red,
  dodgerBlue: appColor.Accent.blue,
  unset: "unset",
  white: appColor.App.white,
};

const customButtonStyled = (
  color: colorThemes,
  size?: "small" | "medium" | "large",
  disabledBackgroundColor?: string,
  borderColor?: string,
  borderWidth?: number,
  backgroundColor?: string,
) => css`
  font-family: ${appTypo.font.notoJp};
  color: ${fontColor[color]};
  text-transform: none;
  background: ${backgroundColor ?? backgroundColors[color]};
  opacity: 0.95;
  font-style: normal;
  font-weight: 400;
  font-size: ${size === "small" ? "12px" : "medium" ? "14px" : "16px"};
  line-height: 100%;
  letter-spacing: 0.05em;
  padding: ${size === "small" ? "10px" : "10px 20px"};
  border-radius: 56px;
  border: ${borderWidth ?? 1}px solid ${borderColor ?? backgroundColors[color]};
  box-shadow: none !important;
  &:hover {
    border: ${borderWidth ?? 1}px solid ${borderColor ?? backgroundColors[color]};
    background: ${backgroundColor ?? `${backgroundColors[color]}32`};
    opacity: 0.8;
  }
  &.Mui-disabled {
    opacity: 0.4;
    color: ${fontColor[color]};
    background: ${disabledBackgroundColor ?? `${backgroundColors[color]}32`};
    border: 1px solid ${disabledBackgroundColor ?? `${backgroundColors[color]}32`};
  }
  & a:focus {
    color: ${fontColor[color]};
  }
  span.MuiButton-iconSizeSmall {
    margin: 0 0 0 -4px;
  }
`;
