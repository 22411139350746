export * from "./StoreImgInitial";
export * from "./DividerOrAuth";
export * from "./ImgInitialTitle";
export * from "./ImgTitleGray";
export * from "./Icons/IconChat";
export * from "./Icons/IconAddress";
export * from "./Icons/IconFilter";
export * from "./Icons/IconHeart";
export * from "./Icons/IconHome";
export * from "./Icons/IconSearch";
export * from "./Icons/IconBigRatingStar";
export * from "./Icons/IconHeartActive";
export * from "./Icons/IconPhoneNumber";
export * from "./Icons/IconRatingStarActive";
export * from "./Icons/IconRating";
export * from "./Icons/IconRestaurantFood";
export * from "./Icons/IconSchedule";
export * from "./Icons/IconTicket";
export * from "./Icons/IconDish";
export * from "./Icons/IconTabelog";
export * from "./Icons/IconInstagramPro";
export * from "./Icons/IconInstagramColored";
export * from "./Icons/IconTwitter";
export * from "./Icons/IconTikTok";
export * from "./Icons/IconGoogle";
export * from "./Icons/IconCheck";
export * from "./Icons/IconClose";
