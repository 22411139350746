import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class AdminInfluencerTicketApi extends BaseApi {
  async getInfluencerTicketList(
    parameters: APIPaths["/admin-dash/influencer-ticket/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/influencer-ticket/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/influencer-ticket/list", {
        params: parameters,
      })
    ).data;
  }
  async getInfluencerTicketById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/influencer-ticket/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/influencer-ticket/${id}`)).data;
  }
  async putInfluencerTicketOfferRejectionById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/offer-rejection"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/offer-rejection"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/offer-rejection`)).data;
  }
  async putInfluencerTicketOfferApprovalById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/offer-approval"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/offer-approval"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/offer-approval`)).data;
  }
  async putInfluencerTicketRequestRejectionById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/request-rejection"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/request-rejection"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/request-rejection`)).data;
  }
  async putInfluencerTicketRequestApprovalById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/request-approval"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/request-approval"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/request-approval`)).data;
  }
  // 利用系
  async putInfluencerTicketUseById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/use"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/use"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/use`)).data;
  }
  async putInfluencerTicketUseCancelById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/use-cancel"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/use-cancel"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/use-cancel`)).data;
  }
  async putInfluencerTicketScheduleById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/schedule"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/influencer-ticket/{id}/schedule"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/schedule"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/schedule`, requestBody)).data;
  }
  async putInfluencerTicketPRPostById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/posts"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/influencer-ticket/{id}/posts"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/posts"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/posts`, requestBody)).data;
  }
  async putInfluencerTicketCancelById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/cancel"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/cancel"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/cancel`)).data;
  }
  async putInfluencerTicketDeliveryById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/delivery"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/delivery"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/delivery`)).data;
  }
  async putInfluencerTicketCheckById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/check"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/check"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/check`)).data;
  }
  async putInfluencerTicketInfluencerRatingById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/influencer-rating"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/influencer-ticket/{id}/influencer-rating"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/influencer-rating"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/influencer-rating`, requestBody)).data;
  }
  async putInfluencerTicketInfluencerRatingCommentById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/rating"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/influencer-ticket/{id}/rating"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/rating"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/rating`, requestBody)).data;
  }
  async getInfluencerTicketChatReportList(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/chat-report/list-all"]["get"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/chat-report/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`/admin-dash/influencer-ticket/${id}/chat-report/list-all`)).data;
  }
  async deleteInfluencerTicketCancelById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/cancel"]["delete"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/cancel"]["delete"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.delete(`/admin-dash/influencer-ticket/${id}/cancel`)).data;
  }
  async deleteInfluencerTicketById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.delete(`/admin-dash/influencer-ticket/${id}`)).data;
  }
  async deleteInfluencerTicketReserveById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/reserve"]["delete"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/reserve"]["delete"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.delete(`/admin-dash/influencer-ticket/${id}/reserve`)).data;
  }
  async putInfluencerTicketReserveById(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/reserve"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/influencer-ticket/{id}/reserve"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/reserve"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer-ticket/${id}/reserve`, requestBody)).data;
  }

  async getInfluencerTicketStatusLogList(
    id: APIPaths["/admin-dash/influencer-ticket/{id}/status-log/list-all"]["get"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer-ticket/{id}/status-log/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`/admin-dash/influencer-ticket/${id}/status-log/list-all`)).data;
  }
}
