import { APICompsSchema } from "./api-config";

export const TicketTypeEnum: { type: APICompsSchema["TicketTypeEnum"]; value: string }[] = [
  { type: "visit", value: "来店用" },
  { type: "order", value: "通販用" },
];

export const StoreTypeEnum: { type: APICompsSchema["StoreTypeEnum"]; value: string }[] = [
  { type: "visit", value: "来店用" },
  { type: "order", value: "通販用" },
];

export const PRMethodTypeEnum: { type: APICompsSchema["PRMethodTypeEnum"]; value: string }[] = [
  { type: "all", value: "指定なし" },
  { type: "instagram", value: "Instagram" },
  { type: "tiktok", value: "TikTok" },
  { type: "youtube", value: "YouTube" },
  { type: "twitter", value: "X(旧Twitter)" },
  { type: "google", value: "Google" },
  { type: "tabelog", value: "食べログ" },
];

export const SNSAccountTypeEnum: { type: APICompsSchema["SNSAccountTypeEnum"]; value: string }[] = [
  { type: "instagram", value: "Instagram" },
  { type: "tiktok", value: "TikTok" },
  { type: "twitter", value: "X(旧Twitter)" },
  { type: "youtube", value: "YouTube" },
  { type: "google", value: "Google" },
  { type: "tabelog", value: "食べログ" },
];

export const DiscountTypeEnum: { type: APICompsSchema["DiscountTypeEnum"]; value: string }[] = [
  { type: "percent", value: "%引き" },
  { type: "jpy", value: "円引き" },
  { type: "usd", value: "ドル引き" },
  { type: "none", value: "未設定" },
];

export const GenderEnum: { type: APICompsSchema["GenderEnum"]; value: string }[] = [
  { type: "male", value: "男性" },
  { type: "female", value: "女性" },
  { type: "other", value: "無回答" },
];

export const StaffRoleEnum: { type: APICompsSchema["StaffRoleEnum"]; value: string }[] = [
  { type: "general", value: "一般スタッフ" },
  { type: "storeManager", value: "店長（ストアマネージャー）" },
  // { type: "areaManager", value: "エリアマネージャー" },
  // { type: "brandManager", value: "ブランドマネージャー" },
  { type: "supervisor", value: "全体責任者" },
];

export const OrganizationTypeEnum: { type: APICompsSchema["OrganizationTypeEnum"]; value: string }[] = [
  { type: "company", value: "会社" },
  { type: "soleProprietor", value: "個人事業" },
];

export const ChatReportTypeEnum: { type: APICompsSchema["ChatReportTypeEnum"]; value: string }[] = [
  { type: "suspicious", value: "不審な内容またはスパム" },
  { type: "sensitive", value: "センシティブな内容を含んでいる" },
  { type: "inappropriate", value: "不適切または攻撃的な内容を含んでいる" },
  { type: "other", value: "その他" },
];

export const StaffStatusEnum: { type: APICompsSchema["StaffStatusEnum"]; value: string }[] = [
  { type: "waiting", value: "利用規約同意前" },
  { type: "active", value: "有効" },
  { type: "disabled", value: "無効" },
];

export const InfluencerStatusEnum: { type: APICompsSchema["InfluencerStatusEnum"]; value: string }[] = [
  { type: "waiting", value: "利用規約同意前" },
  { type: "active", value: "有効" },
  { type: "disabled", value: "利用停止中" },
  { type: "canceled", value: "退会済" },
];

export const InfluencerLabelEnum: { type: APICompsSchema["InfluencerLabelEnum"]; value: string }[] = [
  { type: "general", value: "" },
  { type: "special", value: "有料案件受付" },
];

export const OrganizationStatusEnum: { type: APICompsSchema["OrganizationStatusEnum"]; value: string }[] = [
  { type: "waiting", value: "申請中" },
  { type: "rejected", value: "修正依頼" },
  { type: "active", value: "有効" },
  { type: "disabled", value: "無効" },
];

export const BrandStatusEnum: { type: APICompsSchema["BrandStatusEnum"]; value: string }[] = [
  { type: "editing", value: "編集中" },
  { type: "waiting", value: "審査中" },
  { type: "rejected", value: "修正依頼" },
  { type: "open", value: "公開中" },
  { type: "closed", value: "非公開" },
];

export const StoreStatusEnum: { type: APICompsSchema["StoreStatusEnum"]; value: string }[] = [
  { type: "editing", value: "編集中" },
  { type: "waiting", value: "審査中" },
  { type: "rejected", value: "修正依頼" },
  { type: "open", value: "公開中" },
  { type: "closed", value: "非公開" },
];

export const SpotlightStatusEnum: { type: APICompsSchema["SpotlightStatusEnum"]; value: string }[] = [
  { type: "open", value: "公開中" },
  { type: "closed", value: "非公開" },
];

export const TicketStatusEnum: { type: APICompsSchema["TicketStatusEnum"]; value: string }[] = [
  { type: "editing", value: "編集中" },
  { type: "waiting", value: "審査中" },
  { type: "rejected", value: "修正依頼" },
  { type: "open", value: "公開中" },
  { type: "closed", value: "非公開" },
];

export const InfluencerTicketStatusEnum: { type: APICompsSchema["InfluencerTicketStatusEnum"]; value: string }[] = [
  { type: "none", value: "未設定" },
  { type: "denied", value: "不採用済" },
  { type: "declined", value: "拒否" },
  { type: "canceled", value: "キャンセル済" },
  { type: "requesting", value: "申請中" },
  { type: "offering", value: "オファー中" },
  { type: "active", value: "採用済" },
  { type: "scheduling", value: "日程調整中" },
  { type: "scheduled", value: "来店日確定" },
  { type: "used", value: "来店済み(PR提出待ち)" },
  { type: "checked", value: "店舗評価待ち" },
  { type: "rated", value: "評価済み(完了)" },
  { type: "done", value: "" },
];

export const InfluencerTicketOfferLabelEnum: {
  type: APICompsSchema["InfluencerTicketOfferLabelEnum"];
  value: string;
}[] = [
  { type: "none", value: "応募" },
  { type: "general", value: "オファー" },
  { type: "top", value: "トップインフルエンサー" },
  { type: "inbound", value: "インバウンドオファー" },
];

export const InfluencerTicketOrderStatusEnum: { type: APICompsSchema["InfluencerTicketStatusEnum"]; value: string }[] =
  [
    { type: "none", value: "未設定" },
    { type: "denied", value: "不採用済" },
    { type: "canceled", value: "キャンセル済" },
    { type: "requesting", value: "応募中" },
    { type: "offering", value: "オファー中" },
    { type: "active", value: "採用済" },
    { type: "used", value: "発送済" },
    { type: "checked", value: "PRリンク確認済" },
    { type: "rated", value: "評価済" },
  ];

export const InfluencerTicketPriorityEnum: { type: APICompsSchema["InfluencerTicketPriorityEnum"]; value: string }[] = [
  { type: "low", value: "低" },
  { type: "middle", value: "中" },
  { type: "high", value: "高" },
];

export const InfluencerTicketScheduleStatusEnum: {
  type: APICompsSchema["InfluencerTicketScheduleStatusEnum"];
  value: string;
}[] = [
  { type: "waiting", value: "確認中" },
  { type: "reserved", value: "予約済" },
  { type: "canceled", value: "キャンセル済" },
];

export const PRMethodTypeEnumObject = {
  all: "指定なし",
  instagram: "Instagram",
  tiktok: "TikTok",
  twitter: "X(旧Twitter)",
  youtube: "YouTube",
  google: "Google",
  tabelog: "食べログ",
};

export const SNSAccountTypeEnumObject = {
  instagram: "Instagram",
  tiktok: "TikTok",
  twitter: "X(旧Twitter)",
  youtube: "YouTube",
  google: "Google",
  tabelog: "食べログ",
};

export const StoreUrlTypeEnumObject = {
  "google-map": "Googleマップ",
  "apple-map": "Appleマップ",
  "hotpepper-reservation": "ホットペッパー予約",
  "tabelog-reservation": "食べログ予約",
  "other-reservation": "その他予約",
};
