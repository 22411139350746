import { css, Divider, Grid, Stack } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BorderColor, FontColor } from "@unit/styles";
import React from "react";
import { BiLinkExternal } from "react-icons/bi";

import ReservationUrlButton from "../../../../domain/store/button/ReservationUrlButton";
import { NewLineText } from "../../../../UtilityComponent/NewLineText";
import DataItem from "../../../common/accordion/DataItem";
import AppRadiusButton from "../../../common/button/AppRadiusButton";
import AppRadiusOutlinedButton from "../../../common/button/AppRadiusOutlinedButton";
import StoreSnsLinkCard from "../../sns-account/StoreSnsLinkCard";
import GoogleMapContainer from "../address/GoogleMapContainer";

type Props = {
  store: APICompsSchema["StoreObject"];
};

const StoreDetailInfo: React.FC<Props> = (props) => {
  const { store } = props;
  const RESERVATION_TYPES = ["hotpepper-reservation", "tabelog-reservation", "other-reservation"];
  return (
    <Stack p={2} gap={2}>
      <DataItem label="店名">{store.name}</DataItem>
      <Divider css={style.border} />

      {/* 電話 */}
      <Grid className="focus-scroll-store-phoneNumber" />
      {store.phoneNumber && (
        <>
          <DataItem label="電話" notice="※お問合せの際は「ユニット」を見たと言うとスムーズです。">
            {store.phoneNumber || "-"}
          </DataItem>
          <Divider css={style.border} />
        </>
      )}

      {/* 営業時間 */}
      <Grid className="focus-scroll-store-businessHours" />
      {store.businessHours && (
        <>
          <DataItem label="営業時間" notice="※（）内の時間はラストオーダーの時間です。">
            <NewLineText text={store.businessHours || "-"} />
          </DataItem>
          <Divider css={style.border} />
        </>
      )}

      {/* 定休日 */}
      <Grid className="focus-scroll-store-regularHoliday" />
      {store.regularHoliday && (
        <>
          <DataItem label="定休日">{store.regularHoliday || "-"}</DataItem>
          <Divider css={style.border} />
        </>
      )}

      {/* Web予約 */}
      <Grid className="focus-scroll-store-reservationUrl" />
      {store.reservationUrl && (
        <DataItem label="Web予約" labelSpacing={1}>
          <AppRadiusButton
            size="medium"
            color="dodgerBlue"
            onClick={() => window.open(store.reservationUrl || "", "_blank")}
          >
            予約する
          </AppRadiusButton>
        </DataItem>
      )}

      {/* その他の予約 */}
      <Grid className="focus-scroll-store-storeUrls" />
      {store.storeUrls.some((storeUrl) => RESERVATION_TYPES.includes(storeUrl.type)) && (
        <>
          <DataItem label="その他の予約" labelSpacing={1}>
            <Grid display="flex" alignItems="center" gap={1}>
              {store.storeUrls
                .filter((storeUrl) => RESERVATION_TYPES.includes(storeUrl.type))
                .map((storeUrl) => (
                  <React.Fragment key={`${storeUrl.id}_${storeUrl.type}`}>
                    <ReservationUrlButton storeUrl={storeUrl} />
                  </React.Fragment>
                ))}
            </Grid>
          </DataItem>
          <Divider css={style.border} />
        </>
      )}

      {/* SNS */}
      <Grid className="focus-scroll-store-snsAccounts" />
      {store.snsAccounts.some((snsAccount) => !!snsAccount.accountUrl) && (
        <DataItem label="SNS" labelSpacing={1}>
          <Stack gap={1}>
            {store.snsAccounts
              .filter((snsAccount) => !!snsAccount.accountUrl)
              .map((snsAccount) => (
                <React.Fragment key={snsAccount.id}>
                  <StoreSnsLinkCard snsAccount={snsAccount} />
                </React.Fragment>
              ))}
          </Stack>
        </DataItem>
      )}
      <DataItem label="住所" labelSpacing={1} className="focus-scroll-store-address">
        {store.address.prefecture}
        {store.address.city}
        {store.address.street}
        {store.address.building}
        <GoogleMapContainer address={store.address} />
      </DataItem>

      {!!store.url && (
        <AppRadiusOutlinedButton size="medium" color="primary" onClick={() => window.open(store.url || "", "_blank")}>
          詳細はこちら
          <BiLinkExternal color={FontColor.basic} />
        </AppRadiusOutlinedButton>
      )}
    </Stack>
  );
};
export default React.memo(StoreDetailInfo);

const style = {
  border: css`
    border: 1px dashed ${BorderColor.subtle};
  `,
};
