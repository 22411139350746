import { css } from "@emotion/react";

import { appColor } from "./app-colors";

export const CustomFacebookButton = css`
  text-transform: none;
  background: ${appColor.Button.accent.facebook};
  opacity: 0.9;
  border-radius: 8px;
  &:hover {
    background: ${appColor.Button.accent.facebook};
    opacity: 1;
  }
`;
