import { SVGProps } from "react";

export const SVGIconInstagramColoredGray = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
    <g fill={props.color || props.fill || "#E2E2E2"} clipPath="url(#clip0_340_6407)">
      <path d="M8 1.441c2.136 0 2.39.009 3.233.047.78.036 1.203.166 1.485.276.348.128.662.332.92.598.266.258.47.572.599.92.11.282.24.705.275 1.485.038.844.047 1.097.047 3.233s-.008 2.39-.047 3.233c-.036.78-.166 1.203-.275 1.485a2.651 2.651 0 01-1.519 1.518c-.282.11-.705.24-1.485.276-.844.038-1.097.047-3.233.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.48 2.48 0 01-.92-.598 2.479 2.479 0 01-.598-.92c-.11-.282-.24-.705-.276-1.485-.038-.844-.047-1.097-.047-3.233s.009-2.39.047-3.233c.036-.78.166-1.203.276-1.485.128-.348.332-.662.598-.92.258-.266.572-.47.92-.598.282-.11.705-.24 1.485-.276.844-.038 1.097-.047 3.233-.047zM8 0C5.827 0 5.555.01 4.702.048 3.85.087 3.269.222 2.76.42c-.534.2-1.018.516-1.417.923-.407.4-.722.883-.923 1.417-.198.509-.333 1.09-.372 1.942C.01 5.555 0 5.827 0 8s.01 2.445.048 3.298c.039.852.174 1.433.372 1.942.2.534.516 1.018.923 1.417.4.407.883.722 1.417.923.508.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.445-.01 3.298-.048c.852-.039 1.433-.174 1.942-.372a4.09 4.09 0 002.34-2.34c.198-.509.333-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.298c-.04-.852-.174-1.433-.372-1.942a3.921 3.921 0 00-.923-1.417A3.921 3.921 0 0013.24.42c-.509-.198-1.09-.333-1.942-.372C10.445.01 10.173 0 8 0z"></path>
      <path d="M8 3.892a4.108 4.108 0 100 8.216 4.108 4.108 0 000-8.216zm0 6.775a2.667 2.667 0 110-5.334 2.667 2.667 0 010 5.334zM12.27 4.69a.96.96 0 100-1.92.96.96 0 000 1.92z"></path>
    </g>
    <defs>
      <clipPath id="clip0_340_6407">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
