import { SVGProps } from "react";

export const SVGIconAddressColoredGray = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" fill="none" viewBox="0 0 11 12" {...props}>
    <path
      fill="#93918F"
      d="M5.994.06A5.191 5.191 0 000 5.19c0 3.185 2.551 5.412 4.077 6.46.33.227.72.35 1.121.35.386 0 .764-.116 1.083-.334 1.49-1.018 3.99-3.18 4.097-6.272A5.146 5.146 0 005.994.06zM5.19 7.137a1.947 1.947 0 110-3.893 1.947 1.947 0 010 3.893z"
    ></path>
  </svg>
);
