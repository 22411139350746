import * as React from "react";
import { SVGProps } from "react";

export const SVGRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="noun-caret-right-1916330 1">
      <path
        id="Vector"
        d="M6.00002 12.6667C5.82324 12.6666 5.65371 12.5964 5.52871 12.4714C5.40371 12.3464 5.3335 12.1768 5.3335 12C5.3335 11.8232 5.4037 11.6537 5.52866 11.5287L9.05732 7.99999L5.52866 4.47132C5.36282 4.30242 5.29896 4.05826 5.36095 3.82976C5.42288 3.60132 5.60136 3.42283 5.8298 3.36091C6.0583 3.29893 6.30246 3.36279 6.47136 3.52862L10.4714 7.52862C10.5964 7.65362 10.6667 7.8232 10.6667 7.99998C10.6667 8.17681 10.5964 8.34638 10.4714 8.47134L6.47136 12.4713C6.34647 12.5966 6.17683 12.6669 6 12.6666L6.00002 12.6667Z"
        fill={props.color || props.fill || "black"}
      />
    </g>
  </svg>
);
