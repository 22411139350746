import { css } from "@emotion/react";
import React from "react";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";

import { Grid } from "@mui/material";
import { BackgroundColor } from "@unit/styles";
import { BsBatteryFull, BsWifi } from "react-icons/bs";
import { formatApiTime } from "@unit/utility";

type Props = {
  id?: string;
  zoom?: number;
  children: React.ReactNode;
};

// Note: This size is iPhone SE for Chrome emulator device
const SpFrame: React.FC<Props> = (props) => {
  return (
    <Grid id={props.id} css={style.frame}>
      <DeviceFrameset device="iPhone X" width={375} height={780} zoom={props.zoom}>
        <Grid css={style.phoneHeader}>
          <Grid position="relative" top={4} left={24}>
            {formatApiTime(new Date())}
          </Grid>
          <Grid position="absolute" top={6} right={24}>
            <BsBatteryFull size={20} />
          </Grid>
          <Grid position="absolute" top={6} right={52}>
            <BsWifi size={20} />
          </Grid>
        </Grid>
        <Grid>{props.children}</Grid>
      </DeviceFrameset>
    </Grid>
  );
};
export default React.memo(SpFrame);

const style = {
  frame: css`
    & .screen {
      overflow-y: scroll !important;
      -ms-overflow-style: none !important;
      scrollbar-width: none !important;
      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  `,
  phoneHeader: css`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 1000;
    background: ${BackgroundColor.none};
  `,
};
