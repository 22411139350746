import { css } from "@emotion/react";
import { Autocomplete, Avatar, Box, Checkbox, Chip, Grid, Popper, Stack, TextField } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { appColor, appTypo, FontColor, FontFamily, FontWeight } from "@unit/styles";
import cliTruncate from "cli-truncate";
import React, { useEffect, useState } from "react";
import { allStoreObject } from "@unit/utility";

type Props = {
  multiple?: boolean;
  title?: string;
  required?: boolean;
  placeholder?: string;
  selectableAll?: boolean;
  storeList: APICompsSchema["StoreObject"][];
  selectedStores: APICompsSchema["StoreObject"][];
  onSelectStores: (storeList: APICompsSchema["StoreObject"][]) => void;
  onInitStoreSorting?: (storeList: APICompsSchema["StoreObject"][]) => APICompsSchema["StoreObject"][];
};

const StoreSelector: React.FC<Props> = (props) => {
  const [autoCompleteStoreList, setAutoCompleteStoreList] = useState<APICompsSchema["StoreObject"][]>([]);
  const allStoreObj = allStoreObject();

  useEffect(() => {
    const storeList = props.onInitStoreSorting ? props.onInitStoreSorting(props.storeList) : [...props.storeList];
    if (props.selectableAll && !storeList.some((store) => store.id === "all")) {
      storeList.unshift(allStoreObj);
    }
    if (props.selectableAll && !props.selectedStores.length) {
      props.onSelectStores([allStoreObj]);
    }
    setAutoCompleteStoreList(storeList);
  }, [props.storeList]);

  const handleChangeSelectedStores = (event: any, newStores: APICompsSchema["StoreObject"][]) => {
    if (!props.multiple) return changeSelectedStoreSingle(newStores);
    if (props.selectableAll) return changeSelectedStoreMultiple(newStores);
    props.onSelectStores(newStores);
  };

  const changeSelectedStoreSingle = (newStores: APICompsSchema["StoreObject"][]) => {
    const newTicket = props.selectedStores.length ? newStores[1] : newStores[0];
    props.onSelectStores(newStores.length ? [newTicket] : []);
  };

  const changeSelectedStoreMultiple = (newStores: APICompsSchema["StoreObject"][]) => {
    const hasAllStoreObject = props.selectedStores.some((store) => store.id === "all");
    const isSelectAllStoreObject = newStores.some((store) => store.id === "all");

    // 選択中のチケットがない場合
    if (!newStores.length) {
      return props.onSelectStores([allStoreObj]);
    }
    // 選択中のチケットに「すべて」がない状態で「すべて」が選択された時
    if (!hasAllStoreObject && isSelectAllStoreObject) {
      return props.onSelectStores([allStoreObj]);
    }
    props.onSelectStores(newStores.filter((store) => store.id !== "all"));
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      limitTags={2}
      disableCloseOnSelect={props.multiple}
      isOptionEqualToValue={(option, value) => option.id === (value?.id || "")}
      options={autoCompleteStoreList}
      css={style.autoComplete}
      getOptionLabel={(option: APICompsSchema["StoreObject"]) => `${option?.name}-${option?.id}`}
      value={props.selectedStores}
      onChange={handleChangeSelectedStores}
      renderTags={(selectedTags, getTagProps) =>
        selectedTags.map((option: APICompsSchema["StoreObject"], index) => {
          return option?.id ? (
            <Grid key={option.id}>
              <Chip
                css={style.autoCompleteChip}
                avatar={<Avatar src={option?.imageUrls?.[0] || ""} sx={{ width: 56, height: 56 }} />}
                variant="outlined"
                color={"default"}
                label={cliTruncate(option.name, props.selectedStores.length > 1 ? 18 : 22)}
                {...getTagProps({ index })}
              />
            </Grid>
          ) : (
            <></>
          );
        })
      }
      renderInput={(params) => {
        return (
          <Stack spacing={1}>
            <Box css={style.title}>
              {props.title}
              {props.required ? <span>*</span> : null}
            </Box>
            <TextField
              {...params}
              fullWidth
              size={"small"}
              variant="outlined"
              placeholder={!props.selectedStores.length ? props.placeholder : undefined}
              css={style.customTextField}
            />
          </Stack>
        );
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} css={style.muiMenuItem}>
            <Checkbox checked={selected} />
            <Avatar src={option?.imageUrls?.[0] || ""} />
            <Box pl={1}>{cliTruncate(option.name, 30)}</Box>
          </li>
        );
      }}
      PopperComponent={(popperProps) => <Popper {...popperProps} css={style.editingPopper} />}
    />
  );
};
export default React.memo(StoreSelector);

const style = {
  autoComplete: css`
    & .MuiAutocomplete-hasClearIcon {
      padding-right: 24px !important;
    }
    input {
      min-width: 0 !important;
    }
  `,
  autoCompleteChip: css`
    padding-left: 8px;
  `,
  muiMenuItem: css`
    font-family: ${FontFamily.notoSansJp};
    color: ${FontColor.basic};
    font-size: 14px;
    line-height: 20px;
    font-weight: ${FontWeight.normal};
    font-style: normal;
    padding-left: 8px !important;
  `,
  title: css`
    font-family: ${FontFamily.notoSansJp};
    font-size: 12px;
    line-height: 16px;
    font-weight: ${FontWeight.normal};
    font-style: normal;
    letter-spacing: 0.1px;
    span {
      font-size: 16px;
      font-weight: ${FontWeight.bold};
      color: ${FontColor.error};
    }
  `,
  customTextField: css`
    & .MuiAutocomplete-inputRoot {
      padding-right: 48px !important;
    }
  `,
  editingPopper: css`
    & .MuiAutocomplete-paper {
      min-height: 394px;
      max-height: 500px;

      ul {
        max-height: 100%;
        height: 100%;
      }
    }
  `,
};
