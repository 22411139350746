import { css, Grid, Stack } from "@mui/material";
import { APICompsSchema, SNSAccountTypeEnumObject } from "@unit/apis";
import { BackgroundColor, BorderStyle, FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";
import { BiLinkExternal } from "react-icons/bi";

import SnsBallIcon from "./SnsBallIcon";

type Props = {
  snsAccount: APICompsSchema["SNSAccountObject"];
};

const StoreSnsLinkCard: React.FC<Props> = (props) => {
  const type: APICompsSchema["SNSAccountTypeEnum"] | "other" =
    props.snsAccount.type !== "tabelog"
      ? props.snsAccount.type
      : props.snsAccount.accountUrl.includes("tabelog")
      ? "tabelog"
      : "other";
  const label = type === "other" ? "その他" : SNSAccountTypeEnumObject[props.snsAccount.type];

  // TODO: 要リファクタ
  const getSNSIdFromUrl = (snsType: APICompsSchema["SNSAccountTypeEnum"] | "other", url: string) => {
    if (snsType === "other") {
      return url;
    }
    if (snsType === "google") {
      const queryString = url.split("?")[1];
      const params = new URLSearchParams(queryString);
      const qValue = params.get("q");
      return !!qValue ? `@${qValue}` : "";
    }

    const matchResult = url.match(/^https?:\/\/[^/]+\/([^?#]+)/);
    if (!matchResult || !matchResult[1]) {
      return;
    }
    const pathSegments = matchResult[1].split("/").filter((segment) => segment.length > 0);

    if (snsType === "tabelog") {
      const idValue = pathSegments[pathSegments.length - 2];
      return `@${idValue}`;
    }

    if (snsType !== "tiktok" && snsType !== "youtube") {
      const idValue = pathSegments[pathSegments.length - 1];
      return `@${idValue}`;
    }

    return pathSegments[pathSegments.length - 1] || "";
  };

  const accountIdText = getSNSIdFromUrl(type, props.snsAccount.accountUrl);

  return (
    <Grid
      display="flex"
      p={1}
      gap={2}
      css={style.snsLinkCard}
      onClick={() => window.open(props.snsAccount.accountUrl, "_blank")}
    >
      <SnsBallIcon snsAccount={props.snsAccount} />
      <Stack pt={0.5} gap={0.5} css={style.labelContainer}>
        <Grid css={style.labelText}>{label}</Grid>
        <Grid css={style.accountIdText}>{accountIdText}</Grid>
      </Stack>
      <Grid pr={2} py={1.25}>
        <BiLinkExternal color={FontColor.basic} size={16} />
      </Grid>
    </Grid>
  );
};
export default React.memo(StoreSnsLinkCard);

const style = {
  snsLinkCard: css`
    cursor: pointer;
    width: 100%;
    height: 56px;
    border-radius: 36px;
    ${BorderStyle.solidSubtle};
    &:hover {
      background: ${BackgroundColor.hoveredListItem};
    }
  `,
  snsIcon: css`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    ${BorderStyle.solidSubtle};
  `,
  labelContainer: css`
    width: calc(100% - 40px - 16px);
  `,
  labelText: css`
    font-family: ${FontFamily.kintoSans};
    font-style: normal;
    font-weight: ${FontWeight.medium};
    font-size: 16px;
    line-height: 16px;
    color: ${FontColor.basic};
  `,
  accountIdText: css`
    font-family: ${FontFamily.kintoSans};
    font-style: normal;
    font-weight: ${FontWeight.medium};
    font-size: 11px;
    line-height: 11px;
    color: ${FontColor.sub};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
};
