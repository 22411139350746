import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useQuery } from "react-query";

import InitialScreenContainer from "@/components/common/InitialScreenContainer";
import { BackdropProgress } from "@/components/provider/BackdropProgress";
import { useAuthentication } from "@/custom-hooks/use-authentication";
import { authUserAtom, idTokenAtom, initializingAtom } from "@/global-state/jotai-atom";
import { QueryKeys } from "@/global-state/react-query-keys";

type Props = {
  children: React.ReactNode;
};
const AuthProvider: React.FC<Props> = (props) => {
  const { syncIdToken, resetAuth } = useAuthentication();
  const [initializing, setInitializing] = useAtom(initializingAtom);
  const [authUser] = useAtom(authUserAtom);
  const [idToken] = useAtom(idTokenAtom);

  useQuery(QueryKeys.getAuthUser, () => syncIdToken(), {
    onSuccess: async () => await setTimeout(() => setInitializing(false), 1000),
  });

  const handleWindowFocusChange = async () => {
    if (!document.hidden) {
      await syncIdToken();
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleWindowFocusChange);
    return () => {
      document.removeEventListener("visibilitychange", handleWindowFocusChange);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const accessToken = await syncIdToken();
      if (!accessToken) {
        return await resetAuth();
      }
    })();
  }, [idToken]);

  useEffect(() => {
    if (!authUser?.id) {
      return;
    }
    const resetInterval = setInterval(() => syncIdToken(), 10 * 60 * 1000); // 10分間
    return () => clearInterval(resetInterval);
  }, [authUser?.id]);

  if (initializing) {
    return (
      <BackdropProgress open>
        <InitialScreenContainer />
      </BackdropProgress>
    );
  }

  return <>{props.children}</>;
};

export default AuthProvider;
