import * as React from "react";
import { SVGProps } from "react";

export const SVGImgTitleGray = (props: SVGProps<SVGSVGElement>) => (
  <svg width={95} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fill="#93918F">
      <path d="M21.7 18.6H.1V24h21.6v-5.4ZM13.6 15.9H19V2.4H2.8v5.4h10.8v8.1ZM46.6 2.4H30.4v5.4h16.2V2.4ZM49.3 18.6H27.7V24h21.6v-5.4ZM65.5 19.8h-9.257V24H69.7V7.2h-4.2v12.6ZM56.26 7.2H52.9v5.88h3.36V7.2Z" />
      <path d="M61.3 7.2h-3.36v5.88h3.36V7.2ZM82.924 0h-6v4.806h6V0ZM94.9 7.5h-18v6h.024V24h6V13.5H94.9v-6Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.1)" d="M0 0h94.8v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
