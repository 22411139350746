import { APICompsSchema } from "@unit/apis";
import SelectAreas from "admin-dash/src/components/common/SelectAreas";
import React, { useEffect, useState } from "react";

type Props = {
  areas: APICompsSchema["FlatAreaObject"][];
  onChange: (newAreas: APICompsSchema["FlatAreaObject"][]) => void;
  onFocus?: React.FocusEventHandler;
};

// TODO: 新UI作成時に@unit/component配下に作成
const WrappedSelectAreas: React.FC<Props> = (props) => {
  const [areaIds, setAreaIds] = useState<string[]>(props.areas.map((area) => area.id));

  useEffect(() => setAreaIds(props.areas.map((area) => area.id)), [props.areas]);

  return (
    <SelectAreas
      nonError
      areaIds={areaIds}
      setAreaIds={setAreaIds}
      onClickChange={props.onChange}
      onFocus={props.onFocus}
    />
  );
};
export default React.memo(WrappedSelectAreas);
