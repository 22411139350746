import React from "react";

type Props = {
  text?: string | null;
  ignoreUrl?: boolean;
};

export const NewLineText: React.FC<Props> = (props) => {
  if (!props.text) {
    return <></>;
  }

  const textList = props.text.split(`\n`);
  return (
    <>
      {textList.map((text, index) => (
        <InlineText key={index} text={text} ignoreUrl={props.ignoreUrl} newLine={textList.length !== index} />
      ))}
    </>
  );
};

type InlineTextProps = {
  text: string;
  ignoreUrl?: boolean;
  newLine?: boolean;
};
const InlineText: React.FC<InlineTextProps> = (props) => {
  const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
  const words = props.text.split(" ");

  const normalizeStringArray = (arr: string[]): string[] =>
    arr
      .reduce((res: string[], str: string) => {
        if (str) res.push(str, "");
        else if (res[res.length - 1] !== "") res.push("");
        return res;
      }, [])
      .slice(0, -1);

  return (
    <>
      {normalizeStringArray(words).map((word, index) =>
        props.ignoreUrl ? (
          <React.Fragment key={index}>{urlPattern.test(word) ? <a href={word}>{word}</a> : word || " "}</React.Fragment>
        ) : (
          <React.Fragment key={index}>{word || " "}</React.Fragment>
        ),
      )}
      {props.newLine ? <br /> : null}
    </>
  );
};
