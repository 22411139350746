import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { BackgroundColor } from "@unit/styles";
import { debounce } from "@unit/utility";
import React, { ReactNode, useEffect, useState } from "react";

import SpFrame from "./SpFrame";

type DeviceFrameProperty = {
  zoom: number;
  tabPosition: number;
  framePosition: number;
};

type Props = {
  topNode?: ReactNode;
  children: ReactNode;
};

const PreviewTemplate: React.FC<Props> = (props) => {
  const [deviceFrameProperty, setDeviceFrameProperty] = useState<DeviceFrameProperty>({
    zoom: 0.8,
    tabPosition: 80,
    framePosition: 80,
  });

  const updateWindowDimensions = () => {
    const newHeight = window.innerHeight;
    if (newHeight < 824) {
      setDeviceFrameProperty({ zoom: 0.7, tabPosition: 64, framePosition: 10 });
    } else {
      setDeviceFrameProperty({ zoom: 0.8, tabPosition: 16, framePosition: 80 });
    }
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", debounce(updateWindowDimensions, 250));
    return () => window.removeEventListener("resize", debounce(updateWindowDimensions, 250));
  }, []);

  return (
    <Grid position={"fixed"} top={deviceFrameProperty.framePosition}>
      <Grid position={"relative"}>
        <Grid position={"absolute"} top={deviceFrameProperty.tabPosition} left={96}>
          {/* 切り替えタブ表示エリア */}
          {props.topNode}
        </Grid>
        <SpFrame id="focus-scroll-preview-root" zoom={deviceFrameProperty.zoom}>
          <Grid css={style.spFrame}>
            {/* ライブプレビュー表示エリア */}
            {props.children}
          </Grid>
        </SpFrame>
      </Grid>
    </Grid>
  );
};
export default React.memo(PreviewTemplate);

const style = {
  spFrame: css`
    background: ${BackgroundColor.lightBasic};
  `,
};
