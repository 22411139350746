import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export const QueryError: React.FC = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Box style={{ height: 200 }} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" color="textSecondary" px={4}>
          {"データ取得にエラーが発生しました。再リロードをお願いします。"}
        </Typography>
      </Grid>
    </Grid>
  );
};
