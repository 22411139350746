import { APICompsSchema } from "../api-config";
import { flatAddressObject } from "./address";

export const flatOrganizationObject = (): APICompsSchema["FlatOrganizationObject"] => {
  return {
    id: "",
    type: "company",
    name: "",
    nameKana: null,
    representativeName: "",
    representativeNameKana: null,
    url: null,
    phoneNumber: null,
    status: "waiting",
    statusComment: null,
    createdAt: 0,
    updatedAt: 0,
  };
};

export const organizationObject = (): APICompsSchema["OrganizationObject"] => {
  return {
    ...flatOrganizationObject(),
    address: flatAddressObject(),
  };
};
