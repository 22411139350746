import { css, IconButton } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BackgroundColor } from "@unit/styles";
import React from "react";

import { SVGIconArrow } from "../../common/svg-icon/IconArrow";
import { SVGIconShare } from "../../common/svg-icon/IconShare";

import FloatingButtonHeaderTemplate from "./FloatingButtonHeaderTemplate";

type Props = {
  ticket: APICompsSchema["TicketObject"];
};

const TicketDetailHeader: React.FC<Props> = (props) => {
  return (
    <FloatingButtonHeaderTemplate
      leftItemNode={
        <IconButton css={style.iconContainer}>
          <SVGIconArrow />
        </IconButton>
      }
      rightItemNode={
        <>
          {/* シェアボタン */}
          <IconButton css={style.iconContainer}>
            <SVGIconShare />
          </IconButton>
        </>
      }
    />
  );
};
export default React.memo(TicketDetailHeader);

const style = {
  root: (isTransparent: boolean) => css`
    z-index: 100;
    width: 100%;
    max-width: 768px;
    background-color: ${isTransparent ? "transparent" : BackgroundColor.lightBasic};
  `,
  positionFixed: css`
    position: fixed;
    max-width: 768px;
    width: 100%;
    height: 72px;
  `,
  displayFlexRow: css`
    display: flex;
    align-items: center;
    flex-direction: row;
  `,
  iconContainer: css`
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: ${BackgroundColor.lightBasic};
    border-radius: 40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.24);
    &:hover {
      background: ${BackgroundColor.accentGray};
    }
  `,
};
