import { css } from "@emotion/react";
import { Grid, SxProps } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import React, { MouseEventHandler } from "react";

import AreaChip from "@/components/common/area/AreaChip";

type Props = {
  sx?: SxProps;
  selectedRegion: string; //APICompsSchema["RegionEnum"];
  selectedPrefecture: string; //APICompsSchema["PrefectureEnum"];
  selectedAreas: APICompsSchema["FlatAreaObject"][];
  allAreaList: APICompsSchema["FlatAreaObject"][];
  onClick?: MouseEventHandler;
};

const AreaContainer: React.FC<Props> = (props) => {
  const selectableAreas = props.allAreaList.filter(
    (areas) => areas.region === props.selectedRegion && areas.prefecture === props.selectedPrefecture,
  );

  return (
    <Grid container sx={props.sx}>
      <Grid container p={1} css={containerCaptionStyled}>
        エリアを選択してください
      </Grid>
      <Grid item container direction={"row"} pb={0.5}>
        {selectableAreas.length ? (
          selectableAreas.map((area) => (
            <AreaChip
              id={area.id}
              sx={{ margin: "4px" }}
              label={area.area}
              key={area.id}
              isSelected={props.selectedAreas.some((selectedArea) => selectedArea.id === area.id)}
              onClick={props.onClick}
            />
          ))
        ) : (
          <Grid item p={1} css={missingAreaStyled}>
            詳細地域を設定してください
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default AreaContainer;

const containerCaptionStyled = css`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
`;

const missingAreaStyled = css`
  height: 48px;
`;
