import { css, Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { FontColor, FontFamily, FontWeight } from "@unit/styles";
import { formatJpDate } from "@unit/utility";
import React from "react";

import SnsIcon from "../pr-method-type/icon/SnsIcon";

type Props = {
  ticket: APICompsSchema["TicketObject"];
  dashboard?: boolean;
};

const TicketDescription: React.FC<Props> = (props) => {
  const { ticket } = props;
  return (
    <Grid position="relative">
      <Grid css={style.importantSubInfo(props.dashboard)}>
        {props.ticket.entryEndDate ? `${formatJpDate(props.ticket.entryEndDate)}まで応募可能` : "応募期限なし"}
      </Grid>
      <Grid position="absolute" top={0} right={0}>
        <SnsIcon active size={props.dashboard ? 24 : 16} prMethodType={ticket.prMethodType} />
      </Grid>

      <Grid css={style.title(props.dashboard)} pt={0.5} height={38}>
        {ticket.title}
      </Grid>
    </Grid>
  );
};
export default React.memo(TicketDescription);

const style = {
  importantSubInfo: (dashboard?: boolean) => css`
    color: ${FontColor.basic};
    font-family: ${FontFamily.notoSansJp};
    font-size: ${dashboard ? "14px" : "12px"};
    font-weight: ${dashboard ? FontWeight.normal : FontWeight.bold};
    width: 100%;
    padding-right: 24px;
  `,
  title: (dashboard?: boolean) => css`
    color: ${FontColor.basic};
    font-family: ${FontFamily.notoSansJp};
    font-size: 16px;
    font-weight: ${dashboard ? FontWeight.medium : FontWeight.bold};
    line-height: 115%;
    width: 100%;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  `,
};
