import { SVGProps } from "react";

export const SVGIconShare = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" fill="none" viewBox="0 0 14 20" {...props}>
    <path
      fill="#2B2A27"
      fillRule="evenodd"
      d="M5.833 3.325l-1.93 1.93a.833.833 0 01-1.179 0l-.13-.13a.834.834 0 010-1.178L6.446.095a.325.325 0 01.46 0l3.852 3.852a.833.833 0 010 1.178l-.13.13a.833.833 0 01-1.18 0L7.5 3.306v8.082a.833.833 0 11-1.667 0V3.325zM10 20H3.333A3.333 3.333 0 010 16.667v-6.111a2.5 2.5 0 012.5-2.5h1.667v1.666H2.5a.833.833 0 00-.833.834v6.11c0 .921.746 1.667 1.666 1.667H10c.92 0 1.667-.746 1.667-1.666v-6.111a.833.833 0 00-.834-.834H9.167V8.056h1.666a2.5 2.5 0 012.5 2.5v6.11A3.333 3.333 0 0110 20z"
      clipRule="evenodd"
    ></path>
  </svg>
);
