import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class GenreApi extends BaseApi {
  async getGenreList(): Promise<
    APIPaths["/app/genre/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/app/genre/list-all")).data;
  }
}
