import { APICompsSchema } from "@unit/apis";
import { storeObject } from "@unit/apis/src/apis/schema-default-object/store";
import { logoImageUrl } from "@unit/utility";

export const allStoreObject = (): APICompsSchema["StoreObject"] => {
  return {
    ...storeObject(),
    id: "all",
    name: "すべてのストア",
    imageUrls: [logoImageUrl],
  };
};

export const checkLeaveStorePage = (
  editingStore: APICompsSchema["StoreObject"],
  initStore: APICompsSchema["StoreObject"],
): boolean => {
  // TODO: 後で実装する
  return true;
  // return (
  //   editingTicket.title === initTicket.title &&
  //   editingTicket.subTitle === initTicket.subTitle &&
  //   editingTicket.type === initTicket.type &&
  //   editingTicket.prMethodType === initTicket.prMethodType &&
  //   editingTicket.companionMemo === initTicket.companionMemo &&
  //   editingTicket.description === initTicket.description &&
  //   editingTicket.remark === initTicket.remark
  // );
};
