import { css, IconButton } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { BackgroundColor } from "@unit/styles";
import React, { useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import { SVGIconArrow } from "../../common/svg-icon/IconArrow";
import { SVGIconShare } from "../../common/svg-icon/IconShare";

import FloatingButtonHeaderTemplate from "./FloatingButtonHeaderTemplate";

type Props = {
  store: APICompsSchema["StoreObject"];
};

const StoreDetailHeader: React.FC<Props> = (props) => {
  const [activeFavorite, setActiveFavorite] = useState<boolean>(true);
  const handleToggleFavoriteStore = () => {
    setActiveFavorite(!activeFavorite);
  };

  return (
    <FloatingButtonHeaderTemplate
      transparent={!!props.store.imageUrls.length}
      leftItemNode={
        <IconButton css={style.iconContainer}>
          <SVGIconArrow />
        </IconButton>
      }
      rightItemNode={
        <>
          {/* シェアボタン */}
          <IconButton css={style.iconContainer}>
            <SVGIconShare />
          </IconButton>
          {/* いいねボタン */}
          <IconButton css={style.iconContainer} onClick={handleToggleFavoriteStore}>
            {activeFavorite ? <AiFillHeart color="#FF7663" /> : <AiOutlineHeart />}
          </IconButton>
        </>
      }
    />
  );
};
export default React.memo(StoreDetailHeader);

const style = {
  iconContainer: css`
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: ${BackgroundColor.lightBasic};
    border-radius: 40px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
    &:hover {
      background: ${BackgroundColor.accentGray};
    }
  `,
};
