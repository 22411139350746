import { css, Grid } from "@mui/material";
import { APICompsSchema } from "@unit/apis";
import { FontColor, FontFamily, FontWeight } from "@unit/styles";
import React from "react";

import { SVGShop } from "../../common/svg-icon/IconShop";
import { SVGTruck } from "../../common/svg-icon/IconTruck";

type Props = {
  type: APICompsSchema["TicketTypeEnum"];
};

const TicketTypeLabel: React.FC<Props> = (props) => {
  return (
    <Grid
      display="inline-flex"
      alignItems="center"
      gap={0.5}
      px={1}
      py={0.5}
      width={64}
      height={24}
      css={style.ticketTypeLabel(props.type === "visit")}
    >
      {props.type === "visit" ? (
        <SVGShop width={14} height={12} color={FontColor.white} />
      ) : (
        <SVGTruck width={14} height={12} color={FontColor.white} />
      )}
      <span className="text">{props.type === "visit" ? "来店" : "通販"}</span>
    </Grid>
  );
};
export default React.memo(TicketTypeLabel);

const style = {
  ticketTypeLabel: (isVisit: boolean) => css`
    border-radius: 4px;
    // BackgroundColor.accentYellow    > ColorPalette.creamCan  = #F6CA55
    // BackgroundColor.accentBlueGreen > ColorPalette.tradeWind = #51B5AB
    background: ${isVisit ? "#F6CA55" : "#51B5AB"};
    span.text {
      font-family: ${FontFamily.notoSansJp};
      font-weight: ${FontWeight.medium};
      font-style: normal;
      font-size: 14px;
      color: ${FontColor.white};
    }
  `,
};
