import { APICompsSchema } from "../api-config";

export const flatSpotlightObject = (): APICompsSchema["FlatSpotlightObject"] => {
  return {
    id: "",
    name: "",
    status: "closed",
    popupImageUrl: null,
    carouselImageUrl: null,
    carouselPosition: 0,
    description: null,
    isFirstOpen: false,
    createdAt: 0,
    updatedAt: 0,
  };
};

export const spotlightObject = (): APICompsSchema["SpotlightObject"] => {
  return {
    ...flatSpotlightObject(),
    store_count: 0,
  };
};
