import React from "react";
import { Grid } from "@mui/material";

export const newLineText = (text: string | null) => {
  if (!text) return "";
  const texts = text.split(/(\n)/).map((item, index) => {
    return <React.Fragment key={index}>{item.match(/\n/) ? <br /> : item}</React.Fragment>;
  });
  return <Grid>{texts}</Grid>;
};
