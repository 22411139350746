import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { APICompsSchema } from "@unit/apis";
import { BorderColor } from "@unit/styles";
import React from "react";

type Props = {
  address: APICompsSchema["FlatAddressObject"];
};

const GoogleMapContainer: React.FC<Props> = (props) => {
  const mapContainerStyle = {
    width: "100%",
    height: "160px",
    borderRadius: "16px",
    border: `1px solid ${BorderColor.subtle}`,
  };

  const { address } = props;

  const latLng = address?.latLng?.split(",", 2) || ["0", "0"];
  const initLatLng = { lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1]) };

  return (
    <LoadScript googleMapsApiKey={process.env.GOOGLE_MAP_API_KEY || ""}>
      {address.latLng && (
        <GoogleMap mapContainerStyle={mapContainerStyle} center={initLatLng} zoom={15}>
          <Marker position={initLatLng} />
        </GoogleMap>
      )}
    </LoadScript>
  );
};
export default React.memo(GoogleMapContainer);
