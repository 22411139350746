import axios, { AxiosRequestConfig } from "axios";

export default class BaseApi {
  API_URL: string | undefined;
  accessToken: string | undefined;

  constructor(API_URL: string, accessToken?: string) {
    if (API_URL) this.API_URL = API_URL;
    if (accessToken) this.accessToken = accessToken;
  }

  protected get axiosApiConfig() {
    return {
      baseURL: this.API_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.accessToken ? `Bearer ${this.accessToken}` : "",
      },
      responseType: "json",
    } as AxiosRequestConfig;
  }

  protected get get() {
    return axios.create(this.axiosApiConfig).get;
  }

  protected get post() {
    return axios.create(this.axiosApiConfig).post;
  }

  protected get put() {
    return axios.create(this.axiosApiConfig).put;
  }

  protected get patch() {
    return axios.create(this.axiosApiConfig).patch;
  }

  protected get delete() {
    return axios.create(this.axiosApiConfig).delete;
  }
}
