import { APIPaths } from "../api-config";
import BaseApi from "../base-api";

export class AdminInfluencerApi extends BaseApi {
  async getInfluencerList(
    parameters: APIPaths["/admin-dash/influencer/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/influencer/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/influencer/list", {
        params: parameters,
      })
    ).data;
  }
  async registerInfluencerNew(
    requestBody: APIPaths["/admin-dash/influencer/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/influencer/new"]["post"]["responses"]["201"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/influencer/new", requestBody)).data;
  }
  async getInfluencerById(
    id: APIPaths["/admin-dash/influencer/{id}"]["get"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/influencer/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/influencer/${id}`)).data;
  }
  async patchInfluencerById(
    id: APIPaths["/admin-dash/influencer/{id}"]["patch"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/influencer/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/influencer/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/influencer/${id}`, requestBody)).data;
  }
  async deleteInfluencerById(
    id: APIPaths["/admin-dash/influencer/{id}"]["delete"]["parameters"]["path"]["id"],
  ): Promise<APIPaths["/admin-dash/influencer/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/influencer/${id}`)).data;
  }
  async postChangePasswordInfluencer(
    id: APIPaths["/admin-dash/influencer/{id}/change-password"]["post"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/influencer/{id}/change-password"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/influencer/{id}/change-password"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(`/admin-dash/influencer/${id}/change-password`, requestBody)).data;
  }
  async putCancelInfluencerById(
    id: APIPaths["/admin-dash/influencer/{id}/cancel"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/influencer/{id}/cancel"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/influencer/{id}/cancel"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/influencer/${id}/cancel`, requestBody)).data;
  }
  async putDisableInfluencerById(
    id: APIPaths["/admin-dash/influencer/{id}/disable"]["put"]["parameters"]["path"]["id"],
    requestBody: APIPaths["/admin-dash/influencer/{id}/disable"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/influencer/{id}/disable"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer/${id}/disable`, requestBody)).data;
  }
  async putRecoveryInfluencerById(
    id: APIPaths["/admin-dash/influencer/{id}/recovery"]["put"]["parameters"]["path"]["id"],
  ): Promise<
    APIPaths["/admin-dash/influencer/{id}/recovery"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/influencer/${id}/recovery`)).data;
  }
}
