import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { appColor } from "@unit/styles";
import React, { MouseEventHandler } from "react";

import AreaChip from "@/components/common/area/AreaChip";
import { LocationType } from "@/data/sampleSelectType";

type Props = {
  caption: string;
  selectedRegion: string;
  selectedPrefecture: string;
  onClick?: MouseEventHandler;
};

const PrefectureSelector: React.FC<Props> = (props) => {
  const location = LocationType.find((location) => location.region === props.selectedRegion);
  const isSelectBlank = props.selectedPrefecture === "";
  return (
    <>
      <Grid item xs={12} px={2} className={"MuiOutlinedInput-notchedOutline"} css={containerStyled}>
        <fieldset>
          <legend>
            <span>{props.caption}</span>
          </legend>
          <Grid container direction={"row"} item>
            {location?.prefectures && !isSelectBlank
              ? location?.prefectures.map((prefecture) => (
                  <AreaChip
                    sx={{ margin: "4px" }}
                    label={prefecture}
                    key={prefecture}
                    isSelected={prefecture === props.selectedPrefecture}
                    onClick={props.onClick}
                  />
                ))
              : null}
          </Grid>
        </fieldset>
      </Grid>
    </>
  );
};
export default PrefectureSelector;

const containerStyled = css`
  fieldset {
    padding: 4px 8px;
    border: ${appColor.Text.gray} 1px solid;
    border-radius: 4px;
    legend {
      height: 16px;
    }
    legend > span {
      font-size: 11px;
      color: ${appColor.Text.primary};
    }
  }
`;
