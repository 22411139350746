import { APICompsSchema, defaultTicketObject } from "@unit/apis";

export const maxTitleLength = 50;
export const maxSubTitleLength = 200;
export const maxDescriptionLength = 1500;
export const maxRemarkLength = 1000;
export const maxCompanionMemoLength = 200;
export const maxStoreDescriptionLength = 200;

export const noImageUrl = "/no-image.png";
export const noImageUrl3x4 = "/no-image_3x4.png";
export const noImageUrl16x9 = "/no-image_16x9.png";
export const addImageUrl = "/add-image.png";
export const logoImageUrl = "/icon-256x256.png";

// example ticket text
export const exampleTicketTitle = "例:【渋谷エリア】テラス席で楽しむ自慢のコースディナー";
export const exampleTicketSubTitle = "例:自慢のコース料理5品＋ドリンクお一人様2杯";
export const exampleTicketDescription =
  "例:絶対食べてほしいシェフの地元食材を使った自慢のコース料理5品＋ドリンクお一人様2杯\n" +
  "\n" +
  "・アミューズ\n" +
  "・オードブル\n" +
  "\n" +
  "※ギフティング内容の一部が変更になる可能性があります。\n" +
  "※お席のご利用は120分以内です。";
export const exampleTicketCompanionMemo = "例:同伴者様1名様まで同内容無料でご提供します";
export const exampleTicketRemark =
  "例:\n" +
  "①料理写真をトップ画に\n" +
  "②キャプションには\n" +
  "　@UNIT-PR\n" +
  "　#ユニットグルメ\n" +
  "　#奥渋谷でユニット\n" +
  "　#渋谷ビストロ\n" +
  "③ストアの位置情報を設定する\n" +
  "　位置情報：ユニット【渋谷ビストロ】\n" +
  "④下記をタイアップ投稿を設定する\n" +
  "　@unit_PR\n" +
  "⑤以下URLをストーリーズに貼り付けて投稿する\n" +
  "　https://service.unit-g.com/\n" +
  "⑥ご来店後5日以内の投稿";

// example store text
export const exampleStoreDescription =
  "例:渋谷から徒歩◯分。寛げる空間と自慢のお料理を提供します。\n" +
  "いつも元気なスタッフと、中央に構えるライブ感のあるオープンキッチン、光が差し込む開放的な店内が魅力。\n" +
  "自慢のコース料理、厳選ワインをお楽しみ下さい。\n" +
  "ディナーデートや女子会など、渋谷駅付近でレストランをお探しの際は是非当店へお越しください。\n" +
  "ランチ、ディナー、デリバリー営業。カウンター、ソファー席、テラス席、個室有ります。";

export const multiBrandRoles: Array<APICompsSchema["StaffRoleEnum"]> = ["supervisor"];
export const multiStoreRoles: Array<APICompsSchema["StaffRoleEnum"]> = ["supervisor"];

// TODO: 管理者ダッシュボード上でテンプレート登録ができるようになるまでベタ書きする。
const baseTicket = defaultTicketObject();

export const TEMPLATE_TICKET_00: APICompsSchema["TicketObject"] = {
  ...baseTicket,
  id: "86c250ad-3b63-4e42-bfd8-xxxxxxxxxxxx",
  type: "visit",
  title: "A4和牛の食べ比べ！「特選コース+90分飲み放題プラン」のPR",
  description: `コース料理をPRしたい場合のテンプレートです。

吉祥寺にあるお店では、A4ランクの和牛を中心とした「特選コース+90分飲み放題プラン」のPRをお願いします。このプランでは、各部位の違いも楽しめるA4和牛を食べ比べることができ、グルメな方々にもおすすめの内容です。さらに、様々な種類のドリンクが楽しめる90分の飲み放題も付いており、和牛と相性の良いドリンクも豊富に取り揃えています。店内は、落ち着いた雰囲気で、ゆったりと食事を楽しめる空間となっており、接客も丁寧です。

【コース内容】
・前菜盛り合わせ
・本日の鮮魚のカルパッチョ
・特選和牛サーロインのタタキ
・特選和牛のステーキ
・特選和牛のすき焼き
・季節の野菜の天ぷら
・デザート

【注意事項】
※ご来店は月～木　16:00～20:00でお願いいたします。
※お席のご利用は120分以内でお願いします。
※食材の都合上お料理内容が変更になる場合がございます`,
  subTitle: "「特選コース+90分飲み放題」1名様分を0円でご提供します。",
  companionMemo: "同伴者1名につきドリンク3杯まで無償で提供いたします",
  prMethodType: "instagram",
  remark: `①料理画像をトップ画に設定


②キャプションには下記を貼り付けて投稿
@unit-pr
#吉祥寺
#東京グルメ
#吉祥寺焼肉
#PR


③下記アカウントをタグ付けする
@unit-pr

④店舗位置情報を設定
位置情報：unit渋谷店


⑤下記タイアップ投稿を設定
@unit-pr

⑥以下URLをストーリーズに貼り付けて投稿する
https://service.unit-g.com/

⑦ご来店から5日以内に投稿


⑧Instagram以外の複数投稿大歓迎です！！

⑨投稿いただいた画像、リール、動画などをリポスト、転載を承諾いただける方`,
};

export const TEMPLATE_TICKET_01: APICompsSchema["TicketObject"] = {
  ...baseTicket,
  id: "86c251ad-3b63-4e42-bfd8-xxxxxxxxxxxx",
  type: "visit",
  title: "旬のこだわりフルーツとルビーチョコレートの贅沢パフェ（2,500円）のPR",
  description: `単品メニューをPRしたい場合のテンプレートです。

原宿のお店で、旬のこだわりフルーツとルビーチョコレートを使った贅沢なパフェのPRをお願いします。このパフェは、季節に合わせた新鮮なフルーツをたっぷりと使用しており、その甘酸っぱい風味が口の中で広がります。

さらに、ルビーチョコレートを使用することで、パフェの風味とともに、華やかな見た目も楽しめます。ルビーチョコレートは、ベルギーの高級チョコレートで、赤みがかった色合いと、フルーティーで爽やかな風味が特徴です。このチョコレートを使用することで、パフェに独特の風味と、上品でエレガントな雰囲気を与えています。

このパフェは、見た目も美しく、味わいも極上の贅沢な一品です。店内の雰囲気もおしゃれで、カフェタイムや女子会など、様々なシーンで楽しめるお店です。季節ごとに異なるフルーツが楽しめるので、何度訪れても飽きることがありません。ぜひ、原宿のお店でこの贅沢なパフェを味わってみてください。`,
  subTitle: "「旬のこだわりフルーツとルビーチョコレートの贅沢パフェ（2,500円）」を1,000円でご提供します。",
  companionMemo: "同伴者1名まで同一の内容を無償提供いたします",
  prMethodType: "instagram",
  remark: `①トップ画像はパフェのみの画像にする


②キャプションには下記を貼り付けて投稿
@unit-pr 店舗のアカウントと指定のハッシュタグを記載
#東京カフェ
#渋谷パフェ
#パーラータケノ
#PR

③下記アカウントをタグ付けする
@unit-pr

④店舗位置情報を設定
位置情報：パーラータケノ渋谷店


⑤タイアップ投稿を設定
@unit-pr


⑥以下URLをストーリーズに貼り付けて投稿する
https://service.unit-g.com/


⑦ご来店後3日以内の投稿`,
};

export const TEMPLATE_TICKET_02: APICompsSchema["TicketObject"] = {
  ...baseTicket,
  id: "86c252ad-3b63-4e42-bfd8-xxxxxxxxxxxx",
  type: "order",
  title: "季節限定！北海道産タラバガニを使用した旨味たっぷりの『タラバガニパスタ』のPR",
  description: `通販商品をPRしたい場合のテンプレートです。

北海道産タラバガニを贅沢に使用したパスタをご紹介をお願いします。北海道は、豊かな自然と豊富な漁場があることから、新鮮な魚介類が豊富に取れることで知られています。中でも、北海道のタラバガニは蟹の中でも最高級品として知られ、その豊かな旨みと食感は多くの人々を魅了しています。

このタラバガニを使用したパスタは、贅沢な食材を使った贅沢な一品です。濃厚なクリームソースに、ぷりぷりとした食感が楽しめるタラバガニの身がたっぷりと使用されています。パスタは、アルデンテに仕上げることで、タラバガニの風味を引き立てます。タラバガニのコクとパスタの食感が相まって、とても贅沢な味わいに仕上がっています。

北海道産のタラバガニは、身がプリッと弾けるような食感が特徴で、ほんのりとした甘味も感じることができます。新鮮な北海道のタラバガニを使用したパスタは、その旨みと食感を最大限に生かすことができ、北海道ならではの味わいを楽しむことができます。`,
  subTitle: "『タラバガニパスタ(3人前)』を無償提供します。",
  companionMemo: "",
  prMethodType: "instagram",
  remark: `①料理画像をトップ画に設定


②キャプションには下記を貼り付けて投稿
@unit-pr
#タラバガニパスタ
#お取り寄せグルメ
#通販グルメ
#PR


③下記アカウントをタグ付けする
@unit-pr

④下記タイアップ投稿を設定
@unit-pr

⑤以下URLをストーリーズに貼り付けて投稿する
https://service.unit-g.com/

⑥商品到着から3日以内に投稿


⑧Instagram以外の複数投稿大歓迎です！！

⑨投稿いただいた画像、リール、動画などをリポスト、転載を承諾いただける方`,
};
