import { css } from "@emotion/react";
import { Box, Stack, TextField } from "@mui/material";
import { FontColor, FontFamily, FontWeight } from "@unit/styles";
import React, { HTMLInputTypeAttribute } from "react";

type FieldType = "string" | "multiple" | "email" | "password";

type FieldParams = {
  type: HTMLInputTypeAttribute | undefined;
  margin: "normal" | "none" | "dense" | undefined;
  autoComplete?: string;
  minRows?: number;
  maxRows?: number;
};

type Props = {
  fieldType: FieldType;
  required?: boolean;
  label?: string;
  title: string;
  value: string | null;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  placeholder?: string;
  minMultilineRows?: number;
  maxMultilineRows?: number;
  noNewLine?: boolean;
  onChange?: (newValue: string) => void;
  onFocus?: React.FocusEventHandler;
};

const PrimaryTextField: React.FC<Props> = (props) => {
  const FIELD_PARAMS = {
    string: {
      type: "text",
      margin: "none",
    } as FieldParams,
    multiple: {
      type: "text",
      margin: "none",
      minRows: props?.minMultilineRows || 5,
      maxRows: props?.maxMultilineRows || 5,
    } as FieldParams,
    password: {
      type: "password",
      margin: "normal",
      autoComplete: "current-password",
    } as FieldParams,
    email: {
      type: "email",
      margin: "normal",
      autoComplete: "email",
    } as FieldParams,
  };

  const fieldParams = FIELD_PARAMS[props.fieldType];

  return (
    <Stack spacing={1}>
      <Box css={style.title}>
        {props.title}
        {props.required ? <span>*</span> : null}
      </Box>
      <TextField
        {...fieldParams}
        css={style.textField}
        fullWidth
        multiline={props.fieldType === "multiple"}
        error={props.error}
        label={props?.label}
        value={props.value || ""}
        size={"small"}
        helperText={props?.helperText}
        placeholder={props?.placeholder}
        disabled={props.disabled}
        onKeyDown={(event: React.KeyboardEvent) => {
          // Note: Enter押下で文末にカーソル移動するtextareaのデフォルト挙動を制限
          if (props.noNewLine && event.key === "Enter") {
            event.preventDefault();
          }
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (!props.onChange) return;
          if (props.noNewLine) {
            props.onChange(event.target.value.replace(/\r?\n/g, ""));
          } else {
            props.onChange(event.target.value);
          }
        }}
        onFocus={props.onFocus}
      />
    </Stack>
  );
};
export default React.memo(PrimaryTextField);

const style = {
  title: css`
    font-family: ${FontFamily.notoSansJp};
    font-style: normal;
    font-weight: ${FontWeight.normal};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    span {
      font-size: 16px;
      font-style: normal;
      color: ${FontColor.error};
    }
  `,
  textField: css`
    .MuiFormHelperText-contained {
      margin: 4px 0 0;
      font-family: ${FontFamily.notoSansJp};
      color: ${FontColor.sub};
      font-size: 12px;
      letter-spacing: 0.1px;
    }
  `,
};
