import { Avatar, Grid, Tooltip } from "@mui/material";
import { FontColor } from "@unit/styles";
import { SVGIconHeart, SVGIconHome } from "@unit/svgs";
import { logoImageUrl } from "@unit/utility";
import React from "react";

import { SVGIconBell } from "../../common/svg-icon/IconBell";
import { SVGIconInfluencerTicket } from "../../common/svg-icon/IconInfluencerTicket";

import FooterItem from "./FooterItem";
type Props = {
  unreadCount?: number;
};

const HomeNavFooter: React.FC<Props> = (props) => {
  const unreadCount = 0;
  const unreadNotificationCount = 0;
  return (
    <Grid px={2} container direction={"row"} justifyContent={"space-evenly"} alignItems={"center"}>
      {/* ホーム */}
      <Tooltip title="これはプレビューです" placement="top">
        <Grid item xs>
          <FooterItem label="ホーム" isActive={true}>
            <SVGIconHome fill={FontColor.basic} />
          </FooterItem>
        </Grid>
      </Tooltip>

      {/* やりとり */}
      <Tooltip title="これはプレビューです" placement="top">
        <Grid item xs>
          <FooterItem label="やりとり" isActive={false} badgeContent={unreadCount}>
            <SVGIconInfluencerTicket fill={FontColor.inactive} />
          </FooterItem>
        </Grid>
      </Tooltip>

      {/* お気に入り */}
      <Tooltip title="これはプレビューです" placement="top">
        <Grid item xs>
          <FooterItem label="お気に入り" isActive={false}>
            <SVGIconHeart fill={FontColor.inactive} />
          </FooterItem>
        </Grid>
      </Tooltip>

      {/* お知らせ */}
      <Tooltip title="これはプレビューです" placement="top">
        <Grid item xs>
          <FooterItem label="お知らせ" isActive={false} badgeContent={unreadNotificationCount}>
            <SVGIconBell fill={FontColor.inactive} />
          </FooterItem>
        </Grid>
      </Tooltip>

      {/* マイページ */}
      <Tooltip title="これはプレビューです" placement="top">
        <Grid item xs>
          <FooterItem label="マイページ" isActive={false}>
            <Avatar src={logoImageUrl} sx={{ width: "26px", height: "26px" }} />
          </FooterItem>
        </Grid>
      </Tooltip>
    </Grid>
  );
};
export default React.memo(HomeNavFooter);
