import { Box, css } from "@mui/material";
import { BackgroundColor } from "@unit/styles";
import React from "react";

import AreaFilterOpener from "./header-component/AreaFilterOpener";
import TicketFilterOpener from "./header-component/TicketFilterOpener";
import TicketTypeToggleSwitch from "./TicketTypeToggleSwitch";

const TicketListHeader: React.FC = () => {
  return (
    <Box
      sx={{
        position: "sticky",
        zIndex: "10",
        top: "30px",
        width: "100%",
        padding: "16px",
        display: "flex",
        justifyContent: "space-between",
        gap: "8px",
      }}
      css={style.header}
    >
      {/* エリアフィルター */}
      <Box sx={{ width: "100%" }}>
        <AreaFilterOpener />
      </Box>

      {/* チケットタイプ選択 */}
      <Box sx={{ flexShrink: 0 }}>
        <TicketTypeToggleSwitch />
      </Box>

      {/* その他のフィルター */}
      <Box sx={{ flexShrink: 0 }}>
        <TicketFilterOpener />
      </Box>
    </Box>
  );
};
export default TicketListHeader;

const style = {
  header: css`
    background: ${BackgroundColor.lightBasic};
  `,
  appear: css`
    opacity: 1;
    width: 40px;
    transition: 0.2s ease-out;
  `,
  disappear: css`
    opacity: 0;
    width: 0;
    transition: 0.2s ease-out;
  `,
};
