import "react-pro-sidebar/dist/css/styles.css";
import { EmotionCache, Global } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { globalStyled, muiTheme } from "@unit/styles";
import { Provider } from "jotai";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import AuthProvider from "@/components/provider/AuthProvider";
import GlobalJotaiProvider from "@/components/provider/GlobalJotaiProvider";
import "react-toastify/dist/ReactToastify.css";

const Layout = dynamic(async () => import("@/components/Layout"), { ssr: false });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 30000,
    },
  },
});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps): JSX.Element {
  const { Component, pageProps } = props;

  const render = () => {
    return (
      <Layout noHeader={!!pageProps.noHeader}>
        <Component {...pageProps} />
      </Layout>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Global styles={globalStyled} />
        <Provider>
          <GlobalJotaiProvider>
            <AuthProvider>{render()}</AuthProvider>
          </GlobalJotaiProvider>
        </Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
