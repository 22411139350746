//
import format from "date-fns/format";
import ja from "date-fns/locale/ja";

import { zeroPad } from "./common-utils";

export const formatJpDate = (date?: string | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}年${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日` : "";
};
export const formatJpDateForNumber = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt ? `${dt.getFullYear()}年${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日` : "";
};

export const formatSlashDate = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt ? `${dt.getFullYear()}/${zeroPad(dt.getMonth() + 1, 2)}/${zeroPad(dt.getDate(), 2)}` : "";
};

export const formatSlashDateMonthDay = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt ? `${zeroPad(dt.getMonth() + 1, 2)}/${zeroPad(dt.getDate(), 2)}` : "";
};

export const formatSlashDateStrings = (date?: string | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}/${zeroPad(dt.getMonth() + 1, 2)}/${zeroPad(dt.getDate(), 2)}` : "";
};

export const formatSlashTime = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt ? `${zeroPad(dt.getHours(), 2)}:${zeroPad(dt.getMinutes(), 2)}` : "";
};
export const formatSlashDateTime = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt
    ? `${dt.getFullYear()}/${zeroPad(dt.getMonth() + 1, 2)}/${zeroPad(dt.getDate(), 2)} ${zeroPad(
        dt.getHours(),
        2,
      )}:${zeroPad(dt.getMinutes(), 2)}`
    : "";
};

export const formatJpDateTime = (date?: number | null) => {
  if (!date) return "";
  const dt = new Date(date * 1000);
  return dt
    ? `${dt.getFullYear()}年${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日 ${zeroPad(
        dt.getHours(),
        2,
      )}:${zeroPad(dt.getMinutes(), 2)}`
    : "";
};

export const formatJpDateTimeDay = (date?: number | null) => {
  if (!date) return "";
  return format(new Date(date * 1000), "yyyy年MM月dd日(E) HH:mm", { locale: ja });
};

export const formatJpDTime = (date?: number | null) => {
  if (!date) return "";
  return format(new Date(date * 1000), "HH:mm", { locale: ja });
};

export const formatApiDate = (date?: Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}-${zeroPad(dt.getMonth() + 1, 2)}-${zeroPad(dt.getDate(), 2)}` : "";
};

export const formatSlashApiDate = (date?: Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}/${zeroPad(dt.getMonth() + 1, 2)}/${zeroPad(dt.getDate(), 2)}` : "";
};

export const formatApiTime = (date?: Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${zeroPad(dt.getHours(), 2)}:${zeroPad(dt.getMinutes(), 2)}` : "";
};
export const validateIsExpired = (endDate: string | null) => {
  if (!endDate) return false;
  const targetEndDate = new Date(endDate);
  targetEndDate.setHours(0, 0, 0, 0);
  targetEndDate.setDate(targetEndDate.getDate() + 1);
  return new Date().getTime() > new Date(targetEndDate).getTime();
};

export const formatTimeToDate = (time: string) => {
  const dt = new Date();
  const timeArray = time.split(":");
  dt.setHours(Number(timeArray[0]), Number(timeArray[1]), Number(timeArray[2]), 0);
  return dt;
};

export const formattedDateAndTimeConverter = (date: Date | null, time: Date | null) => {
  if (!date || !time) {
    return 0;
  }
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds(),
  ).getTime();
};
export const addDays = (date: Date | null, days: number) => {
  if (!date) return undefined;
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const compareAvailableTime = (
  targetDatetime: number,
  availableStartTime: string,
  availableEndTime: string | null,
): boolean => {
  const targetDate = new Date(targetDatetime * 1000);
  const startTime = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate(),
    ...availableStartTime.split(":").map((x) => parseInt(x, 10)),
  );
  if (!availableEndTime) {
    return startTime <= targetDate;
  }
  const endTime = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate(),
    ...availableEndTime.split(":").map((x) => parseInt(x, 10)),
  );
  return startTime <= targetDate && targetDate <= endTime;
};

export const calculateTimeDiff = (createdAt: number) => {
  const actionDate = new Date(createdAt * 1000);
  const currentDate = new Date();
  const diff = currentDate.getTime() > actionDate.getTime() ? currentDate.getTime() - actionDate.getTime() : 0;
  const timeDiff = new Date(diff);

  if (timeDiff.getUTCFullYear() - 1970 > 0) {
    return `${actionDate.getFullYear()}/${zeroPad(actionDate.getMonth() + 1, 2)}/${zeroPad(actionDate.getDate(), 2)}`;
  }
  if (timeDiff.getUTCMonth() > 0) {
    const diffMonth = timeDiff.getUTCMonth();

    return `${diffMonth}ヶ月前`;
  }
  if (timeDiff.getUTCDate() - 1 > 0) {
    const diffDay = timeDiff.getUTCDate() - 1;

    return `${diffDay}日前`;
  }
  if (timeDiff.getUTCHours() > 0) {
    const diffHours = timeDiff.getUTCHours();

    return `${diffHours}時間前`;
  }
  if (timeDiff.getUTCMinutes() > 0) {
    const diffMinutes = timeDiff.getUTCMinutes();

    return `${diffMinutes}分前`;
  }
  if (timeDiff.getUTCSeconds() > 10) {
    const diffSeconds = timeDiff.getUTCSeconds();

    return `${diffSeconds}秒前`;
  }
  if (timeDiff.getUTCSeconds() <= 10) {
    return "たった今";
  }
};

export const formatJpChartXAxis = (date?: string | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getMonth() + 1}月${zeroPad(dt.getDate(), 2)}日` : "";
};

export const formatJpCharDate = (date?: string | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}年${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日` : "";
};

/**
 * 引数にnullを取る時に`null`を返す事以外は`new Date(date)`と同じ動作をします。
 * @param date
 * @returns
 */
export const getDateInstance = (date?: string | number | null): Date | null => {
  if (!date) return null;
  return new Date(date);
};

/**
 * 第1引数~第3引数で受け取った時分秒のDateオブジェクトを生成する。
 * 日付については第4引数の`date`が反映される。
 * @param hours
 * @param minutes
 * @param seconds
 * @param date
 * @returns
 */
export const datetime = (hours: number, minutes: number, seconds: number, date: Date = new Date()) => {
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
};

export const formatDateRange = (dateRange: [Date, Date]) => {
  const start = dateRange[0];
  const end = dateRange[1];
  const startMonth = start.getMonth() + 1;
  const startDate = start.getDate();
  const endMonth = end.getMonth() + 1;
  const endDate = end.getDate();
  return `${start.getFullYear()}/${startMonth < 10 ? "0" + startMonth : startMonth}/${
    startDate < 10 ? "0" + startDate : startDate
  }-${end.getFullYear()}/${endMonth < 10 ? "0" + endMonth : endMonth}/${endDate < 10 ? "0" + endDate : endDate}`;
};

export const formatDateYYYYMMDD = (date: Date) => {
  return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
};
