import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import React from "react";

import { SVGIconChevron } from "../svg-icon/IconChevron";

type Direction = "right" | "left";

type Props = {
  onClick?: () => void;
  direction: Direction;
};

export const SlideArrow: React.FC<Props> = (props) => {
  const onClickSlide = () => {
    props.onClick && props.onClick();
  };

  return (
    <Grid css={props.direction === "right" ? nextArrowContainer : prevArrowContainer} onClick={onClickSlide}>
      <SVGIconChevron direction={props.direction} width={8} height={14} />
    </Grid>
  );
};

const prevArrowContainer = css`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  z-index: 1000;
`;

const nextArrowContainer = css`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 40px;
`;
