import { APICompsSchema } from "@unit/apis";
import { FontColor } from "@unit/styles";
import { SVGIconGoogle, SVGIconInstagramColored, SVGIconTabelog, SVGIconTikTok, SVGIconTwitter } from "@unit/svgs";
import React from "react";
import { SiGoogle, SiTiktok, SiYoutube } from "react-icons/si";
import { TbFileUnknown } from "react-icons/tb";

import { SVGIconInstagramColoredGray } from "../../../common/svg-icon/IconInstagramColoredGray";

type Props = {
  prMethodType?: APICompsSchema["PRMethodTypeEnum"];
  active?: boolean;
  size: number;
};

const SnsIcon: React.FC<Props> = (props) => {
  const { prMethodType, active, size } = props;
  switch (prMethodType) {
    case "instagram":
      return active ? (
        <SVGIconInstagramColored width={size} height={size} />
      ) : (
        <SVGIconInstagramColoredGray width={size} height={size} />
      );
    case "tiktok":
      return active ? <SVGIconTikTok width={size} height={size} /> : <SiTiktok size={size} color={FontColor.subtle} />;
    case "twitter":
      return active ? (
        <SVGIconTwitter width={size} height={size} />
      ) : (
        <SVGIconTwitter width={size} height={size} color={FontColor.subtle} />
      );
    case "youtube":
      return active ? (
        <SiYoutube size={size} color={FontColor.youtube} />
      ) : (
        <SiYoutube size={size} color={FontColor.subtle} />
      );
    case "google":
      return active ? <SVGIconGoogle width={size} height={size} /> : <SiGoogle size={size} color={FontColor.subtle} />;
    case "tabelog":
      const viewBox = [6, 3, 22, 20].join(" ");
      return active ? (
        <SVGIconTabelog width={size * 1.15} height={size} viewBox={viewBox} />
      ) : (
        <SVGIconTabelog width={size * 1.15} height={size} fill={FontColor.subtle} viewBox={viewBox} />
      );
    default:
      return <TbFileUnknown />;
  }
};
export default React.memo(SnsIcon);
